import FaqAuto from './faq-auto/FaqAuto';
import FaqManual from './faq-manual/FaqManual';
import React, { useState } from 'react';
import { BackButton2 } from '../../../../common/BackButton2';
import { useNavigate } from 'react-router-dom';
import {
  deleteFaq,
  generateFAQFromDocuments,
  getFaqList,
  getListOfDocuments
} from '../../../../api/AccountSetup';
import ToastNotification from '../../../../common/ToastNotification';
import AccountSetupButton from '../../../../common/AccountSetupButton';

const Faq = () => {
  const navigate = useNavigate();
  const companyUuid = sessionStorage.getItem('companyUuid');

  const [disable, setDisable] = useState(false);
  const [showFaqAuto, setShowFaqAuto] = useState(false);
  const [showFaqManual, setShowFaqManual] = useState(false);
  const [documentList, setDocumentList] = useState<
    Array<{ type: string; name: string; uuid: string }>
  >([]);
  const [isloading, setIsLoading] = useState(false);
  const [faqList, setFaqList] = useState<
    { uuid: string | null; faq: { question: string; answer: string }[] }[]
  >([]);
  const [questionsList, setQuestionsList] = useState<
    Array<{ question: string; answer: string; uuid?: string | null }>
  >([]);

  const handleFaqAutoClick = () => {
    setShowFaqAuto(true);
    setShowFaqManual(false);
  };

  const handleFaqManualClick = () => {
    setShowFaqManual(true);
    setShowFaqAuto(false);
  };

  const handleGoBack = () => {
    navigate('/account-setup/create-profile');
  };

  const handleDeleteFaq = async (index: number, faqUUID: string) => {
    if (faqUUID) {
      setIsLoading(true);

      const resp = await deleteFaq(faqUUID, companyUuid ? companyUuid : '');
      if (resp.ok) {
        setIsLoading(false);
        fetchAllFaq();

        ToastNotification({
          message: 'Deleted Successfully',
          type: 'success'
        });
      } else {
        setIsLoading(false);

        ToastNotification({
          message: 'Something went wrong',
          type: 'error'
        });
      }
    } else {
      const updatedQuestions = questionsList.filter((_, i) => i !== index);
      setQuestionsList(updatedQuestions);
    }
  };

  const fetchGeneratedFAQ = async () => {
    setDisable(true);
    const listOfUUID = documentList.map((info: any) => info.uuid);
    const payload = { uuids: listOfUUID };
    const res = await generateFAQFromDocuments(payload, companyUuid ? companyUuid : '');
    if (res.message === 'Successful') {
      if (res.payload.complete) {
        const generatedFAQList = res.payload.payload
          .filter((item: any) => item.faq.length > 0)
          .map((item: any) => ({
            ...item,
            faq: item.faq
          }));
        setDisable(false);
        setFaqList(generatedFAQList);
        setQuestionsList(generatedFAQList.flatMap((item: any) => item.faq));
        handleFaqManualClick();
      } else {
        setTimeout(() => {
          fetchGeneratedFAQ();
        }, 5000);
      }
    } else {
      ToastNotification({
        message: 'Something went wrong',
        type: 'error'
      });
      setDisable(false);
    }
  };

  const fetchAllDocuments = async () => {
    try {
      setIsLoading(true);
      const documentList = await getListOfDocuments(companyUuid ? companyUuid : '');
      setDocumentList(documentList.payload.result || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching FAQ list:', error);
      setDocumentList([]);
    }
  };

  const fetchAllFaq = async () => {
    try {
      setIsLoading(true);
      const faqList = await getFaqList(companyUuid ? companyUuid : '');
      setQuestionsList(faqList.payload.list || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching FAQ list:', error);
      setQuestionsList([]);
    }
  };
  return (
    <div className="px-2 md:px-0 w-full">
      {!showFaqAuto && !showFaqManual && (
        <div className="w-full mt-3">
          <div>
            <p className="h-[31px] md:mb-[5px] lg:ml-9 ml-[4px] md:ml-2  text-[14px]   sm:leading-[26.86px] lg:text-[22px] md:text-[17px]  md:leading-[30.86px] font-normal">
              STEP 2
            </p>
          </div>
          <div className="flex ml-[4px] md:ml-1">
            <div className="md:mt-1 lg:mt-0 ">
              <BackButton2 onClick={handleGoBack} />
            </div>
            <div className="">
              <h3 className="md:h-[39px] lg:ml-0 lg:mt-0  mt-0 md:mb-[40px] mb-4 sm:text-[18px] sm:leading-[29.18px] text-[16px] lg:text-[28px] md:text-[21px] md:leading-[39.28px] font-medium">
                Frequently Asked Questions
              </h3>
            </div>
          </div>
          <div className="lg:ml-9 ml-[4px] md:ml-1">
            <div className="md:mt-10 mt-5">
              <p className="md:text-[22px] text-sm md:font-normal font-light md:leading-[25.08px]">
                If you have a document or links related to this, please upload it here.{' '}
                <span
                  onClick={handleFaqAutoClick}
                  className="underline cursor-pointer text-[#006BEA]"
                >
                  Document
                </span>
              </p>
            </div>

            <div className="text-center md:mt-10 mt-5">
              <p className="md:text-[24px] text-base font-bold text-[#ffffff] md:leading-[27.36px]">
                OR
              </p>
            </div>

            <div className="md:mt-10 mt-5">
              <p className="md:text-[22px] text-sm md:font-normal font-light md:leading-[25.08px]">
                If you would like to create FAQ manually, click on manually.{' '}
                <span
                  onClick={handleFaqManualClick}
                  className="underline cursor-pointer text-[#006BEA]"
                >
                  Manually
                </span>
              </p>
            </div>

            <div className="fixed bottom-2 right-6 gap-2 md:gap-4 bg-[#1E1E1E]">
              <AccountSetupButton
                isFormValid={true}
                btnText="Skip"
                textColor="#FFFFFF"
                backGroundColor=""
                borderColor="#006BEA"
                onClick={() => navigate('/account-setup/widgets')}
              />
            </div>
          </div>
        </div>
      )}

      {showFaqAuto && (
        <FaqAuto
          fetchGeneratedFAQ={fetchGeneratedFAQ}
          fetchAllDocuments={fetchAllDocuments}
          documentList={documentList}
          setShowFaqAuto={setShowFaqAuto}
          disable={disable}
          setFaqList={setFaqList}
          isLoading={isloading}
          setIsLoading={setIsLoading}
          setDocumentList={setDocumentList}
        />
      )}
      {showFaqManual && (
        <FaqManual
          isLoading={isloading}
          faqList={faqList}
          questionsList={questionsList}
          setQuestionsList={setQuestionsList}
          disable={disable}
          setDisable={setDisable}
          fetchAllFaq={fetchAllFaq}
          handleDeleteFaq={handleDeleteFaq}
          setShowFaqManual={setShowFaqManual}
          setFaqList={setFaqList}
        />
      )}
    </div>
  );
};

export default Faq;

import React, { Fragment, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import CommonPopup from '../../common/CommonPopup';
import ToastNotification from '../../common/ToastNotification';
import { assignUser } from '../../api/Complaints';
import { assignDataPopup } from '../../api/Complaints';
import Loader from '../../common/Loader';

const AssignUserPopup = (props: any) => {
  const {
    tickets,
    onClose,
    selectedComplaintsInfo,
    handleFetchTickets,
    currentPage,
    selectedFilter
  }=props;
  const companyUuid = sessionStorage.getItem('companyUuid');
  const [showLoading, setShowLoading] = useState(true);
  const [vendorList, setVendorList] = useState<any[]>([]);

  const fetchUserList = async () => {
    try {
      const response = await assignDataPopup(companyUuid ? companyUuid : '');
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setVendorList(data.payload.userList);
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }
  };

  const handleAssignUser = async (userUuid: string) => {
    try {
      const getSelectedIndex = tickets.findIndex(
        (info: any) => info.uuid === selectedComplaintsInfo.uuid
      );
      const response = await assignUser({
        complaintUuid: selectedComplaintsInfo.uuid || '',
        userUuid: userUuid || ''
      });
      if (response.ok) {
        const data = await response.json();
        fetchUserList();
        handleFetchTickets(selectedFilter, currentPage, '', '', getSelectedIndex, false);
        ToastNotification({
          message: data.message
        });
      } else {
        const errorData = await response.json();
        ToastNotification({
          message: errorData.message,
          type: 'warning'
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <CommonPopup onClose={onClose} width="w-[350px] md:w-[550px]">
      <div className="md:w-full">
        <div className="flex justify-between items-center px-3 pb-2 border-b border-[#595959]">
          <h2 className="text-xl font-medium mb-2">Assign User</h2>
          <div className="cursor-pointer mb-2" onClick={onClose}>
            <IoMdClose size={24} />
          </div>
        </div>

        <div className="flex flex-col gap-4 h-[350px] overflow-y-auto mx-1.5  mt-4">
          {showLoading ? (
            <div className="flex justify-center items-center absolute inset-0">
              <Loader />
            </div>
          ) : (
            <Fragment>
              {vendorList.length > 0 ? (
                vendorList.map((info: any, index: number) => (
                  <div
                    className="flex justify-between items-center md:gap-4 text-base font-normal text-white"
                    key={info.uuid || index}
                  >
                    <div className="flex items-center space-x-2">
                      <div className=" w-6 h-6 md:w-11 md:h-11 bg-[#D9D9D9] rounded-full flex justify-center items-center text-black font-md md:text-lg text-base">
                        {info.fullName.charAt(0).toUpperCase()}
                      </div>

                      <div className="justify-center md:w-36 text-left">
                        <label className=" text-[10px] md:text-[15px]">{info.fullName}</label>
                      </div>
                    </div>
                    <div className="justify-center">
                      <label className="text-[10px] md:text-[15px]">
                        {info.complaintCount} Complaints
                      </label>
                    </div>

                    <div className="flex me-2">
                      <button
                        disabled={info?.complaintData?.find(
                          (companyuuid: any) => companyuuid.uuid === selectedComplaintsInfo.uuid
                        )}
                        type="button"
                        className={`px-3.5 py-1 md:py-1.5 font-bold text-base rounded md:w-28 text-[10px] md:text-[15px] ${
                          info?.complaintData?.find(
                            (companyuuid: any) => companyuuid.uuid === selectedComplaintsInfo.uuid
                          )
                            ? 'bg-[#FFFFFF] text-[#006BEA] cursor-not-allowed'
                            : 'bg-[#006BEA] text-white'
                        }`}
                        onClick={() => handleAssignUser(info.uuid)}
                      >
                        {info?.complaintData?.find(
                          (companyuuid: any) => companyuuid.uuid === selectedComplaintsInfo.uuid
                        )
                          ? 'Assigned'
                          : 'Assign'}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-center h-3/6 w-full text-gray-300 text-[16px] font-normal">
                  No records found
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </CommonPopup>
  );
};

export default AssignUserPopup;

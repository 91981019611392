import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import HowCanWeHelp from './support-home/HowCanWeHelp';

const faqData = [
  {
    question: 'Fix Subscription Problems?',
    answers: [
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' }
    ]
  },
  {
    question: 'Fix Subscription Problems?',
    answers: [
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' }
    ]
  },
  {
    question: 'Fix Subscription Problems?',
    answers: [
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' }
    ]
  },
  {
    question: 'Fix Subscription Problems?',
    answers: [
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' }
    ]
  },
  {
    question: 'Fix Subscription Problems?',
    answers: [
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' },
      { text: 'Fix Subscription Problems', link: '/support/detailed-question' }
    ]
  }
];

export const SupportListQuestion = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="w-full mt-12">
        <HowCanWeHelp />

        <div className="w-full block max-w-2xl mx-auto mt-20">
          <label className="text-[20px] font-bold">Browse help topics</label>
          <div className="w-full max-w-2xl mx-auto mt-3">
            {faqData.map((item, index) => (
              <div
                key={index}
                className={`border-[#343434] border-[3px] ${
                  index === 0 ? 'border-t-[3px]' : 'border-t-0'
                } ${index === 0 ? 'rounded-t-md' : ''} ${
                  index === faqData.length - 1 ? 'rounded-b-md' : ''
                }`}
              >
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full text-left p-4 font-semibold text-lg focus:outline-none flex justify-between items-center text-white"
                >
                  <span>{item.question}</span>
                  <span>{activeIndex === index ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
                </button>
                {activeIndex === index && (
                  <div className="p-1  text-[18px] bg-[#343434] leading-[21.78px] ">
                    <ul className=" space-y-[10px]">
                      {item.answers.map((answer, answerIndex) => (
                        <li
                          key={answerIndex}
                          className="hover:bg-[#C9E6F924] pt-1 pb-1 w-full ps-1"
                        >
                          <Link to={answer.link} className="text-light pt-2.5 pb-2.5 pr-2.5 pl-7 ">
                            {answer.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

import React, { Fragment, memo, useEffect, useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import ReactPaginate from 'react-paginate';
import ListOfTickets from './ListOfTickets';
import SpecificTicketDetails from './SpecificTicketDetails';
import AssignUserPopup from './AssignUserPopup';
import ToolTip from '../../common/ToolTip';
import { getSearchedComplaint } from '../../api/Complaints';
import VendorAssignedPopup from './VendorAssignedPopup';
import Calender from './Calender';
import { FaChevronLeft, FaChevronRight, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getVendorComplaintList } from '../../api/VendorLogin';
import ComplaintsCardSkeleton from './ComplaintsCardSkeleton';
interface Ticket {
  uuid: string;
  ticketId: string;
  title: string;
  description: string;
  product: string;
  userAssigned: string;
  userUuid: string;
  vendor: string;
  vendorUuid: string;
  status: string;
  raisedOn: string;
}
interface ComplaintProps {
  searchQuery?: any;
  isVendor?: boolean;
}

const Complaints = (props: ComplaintProps) => {
  const { searchQuery, isVendor } = props;

  const companyUuid = sessionStorage.getItem('companyUuid');
  const vendorUuid = sessionStorage.getItem('userUUID');
  const itemsPerPage = 6;
  const navigate = useNavigate();
  const statusType = ['Pending', 'Assigned', 'Resolved', 'Reopened'];

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoading, setShowLoading] = useState(true);
  const [showVendorAssignPopup, setShowVendorAssignPopup] = useState(false);
  const [selectedComplaintsInfo, setSelectedComplaintsInfo] = useState<any>();
  const [complaintInfo, setComplaintInfo] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [clearButtonShow, setClearButtonShow] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [pageValue, setPageValue] = useState(1);

  const handleFetchTickets = async (
    type: string,
    page: number,
    from: string,
    to: string,
    defaultSelectionIndex: number,
    usePageLoader: boolean = true
  ) => {
    try {
      if (usePageLoader) setShowLoading(true);

      const data = isVendor
        ? await getVendorComplaintList(vendorUuid || '', page, itemsPerPage, type, '', '', '')
        : await getSearchedComplaint(
            companyUuid || '',
            type,
            page,
            itemsPerPage,
            from,
            to,
            searchQuery
          );

      setTickets(data?.payload?.complaintList || []);
      setSelectedComplaintsInfo(data?.payload?.complaintList[defaultSelectionIndex] || '');
      setComplaintInfo(data?.payload?.complaintList[defaultSelectionIndex] || '');
      setTotalItems(data?.payload?.count || 0);
    } catch (error) {
    } finally {
      if (usePageLoader) setShowLoading(false);
    }
  };
  const normalizeToUTC = (date: Date | null): string => {
    if (date) {
      return moment(date).utc().format();
    } else {
      return '';
    }
  };
  const handleFilterClick = (type: string) => {
    setStartDate(null);
    setEndDate(null);
    setCurrentPage(1);
    handleFetchTickets(type, currentPage, normalizeToUTC(startDate), normalizeToUTC(endDate), 0);
    setClearButtonShow(true);
    setSelectedFilter(type);
  };
  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
    if (searchQuery !== '') {
      setPageValue(event.selected + 1);
    }
  };
  const handleStartDateChange = (selectedDate: Date | null) => {
    setStartDate(selectedDate);
    if (selectedDate || !selectedDate) {
      setEndDate(null);
    }
    if (!selectedDate) {
      handleFetchTickets('All', currentPage, '', '', 0);
      setSelectedFilter('All');
      setClearButtonShow(false);
    }
  };
  const handleEndDateChange = (selectedDate: Date | null) => {
    setEndDate(selectedDate);
    setCurrentPage(1);
    if (startDate && selectedDate) {
      handleFetchTickets(
        '',
        currentPage,
        normalizeToUTC(startDate),
        normalizeToUTC(selectedDate),
        0
      );
    }
    setSelectedFilter('Sort');
    setClearButtonShow(true);
  };
  const handelClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    handleFetchTickets('All', currentPage, '', '', 0);
    setSelectedFilter('All');
    setClearButtonShow(false);
  };

  useEffect(() => {
    if (searchQuery !== '') {
      setPageValue(1);
      setCurrentPage(1);
    }
  }, [searchQuery]);
  
  useEffect(() => {
    const fetchTickets = () => {
      if (isVendor) {
        handleFetchTickets(selectedFilter, currentPage, '', '', 0);
      } else if (searchQuery !== '') {
        handleFetchTickets(
          selectedFilter,
          pageValue,
          normalizeToUTC(startDate),
          normalizeToUTC(endDate),
          0
        );
      } else {
        handleFetchTickets(
          selectedFilter,
          currentPage,
          normalizeToUTC(startDate),
          normalizeToUTC(endDate),
          0
        );
      }
    };

    fetchTickets();
  }, [searchQuery, currentPage, pageValue, isVendor]);

  const pageCount = Math.ceil(totalItems / itemsPerPage);
  return (
    <>
      <div className="bg-[#1E1E1E] h-screen py-0 w-full md:px-2 overflow-y-auto xl:overflow-hidden relative">
        <div className="flex md:flex-row flex-col justify-between items-center mb-2.5 px-2">
          <label className="text-[28px] text-white font-medium">Tickets</label>

          {!isVendor && (
            <div className="flex justify-between md:flex-row flex-col items-center gap-4">
              {clearButtonShow && (
                <button
                  className="bg-[#006BEA] text-[#FFFFFF] rounded-[6px] md:mt-0 mt-3 p-[5px] lg:p-[6px] xl:p-[10px] font-semibold text-[10px] ml-2 md:ml-2 md:text-[18px] "
                  onClick={handelClearFilter}
                >
                  Clear Filter
                </button>
              )}
              <div className="gap-3 flex items-center md:flex-row flex-col md:mt-0 mt-2">
                <div className="w-auto">
                  <Calender selectedDate={startDate} onDateChange={handleStartDateChange} />
                </div>
                <label className="font-medium">To</label>
                <div className="w-auto">
                  <Calender
                    selectedDate={endDate}
                    onDateChange={handleEndDateChange}
                    minDate={
                      startDate
                        ? new Date(new Date(startDate).setDate(startDate.getDate() + 1))
                        : null
                    }
                    disabled={!startDate}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4">
                <div className="relative ">
                  <BiFilterAlt
                    className="cursor-pointer w-[38px] h-[38px]"
                    color={'#006BEA'}
                    onClick={() => setShowTooltip(true)}
                  />
                  <ToolTip
                    width={'md:w-[200px] lg:w-[200px] w-[150px]'}
                    height={'h-[150px]'}
                    isVisible={showTooltip}
                    onClose={() => setShowTooltip(false)}
                  >
                    <ul className="flex flex-col text-[#006BEA] font-bold text-base justify-center items-center gap-3 mt-1 cursor-pointer">
                      {statusType.map((item) => (
                        <li
                          key={item}
                          onClick={() => {
                            handleFilterClick(item);
                            setShowTooltip(false);
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </ToolTip>
                </div>
                <div
                  className="h-10 w-10 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
                  onClick={() => navigate('/complaints/add-complaint')}
                >
                  <FaPlus className="h-5 w-5" />
                </div>
              </div>
            </div>
          )}
        </div>
        {showLoading ? (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
            <ComplaintsCardSkeleton />
          </>
        ) : (
          <Fragment>
            {tickets.length > 0 ? (
              <div className="bg-[#1E1E1E] w-full overflow-y-auto xl:overflow-hidden">
                <div className="flex flex-col xl:flex-row gap-2.5 h-auto pb-20 xl:pb-0">
                  <div className="w-auto  md:w-full lg:w-full xl:w-[40%]">
                    <ListOfTickets
                      tickets={tickets || []}
                      selectedComplaintsInfo={selectedComplaintsInfo}
                      setSelectedComplaintsInfo={setSelectedComplaintsInfo}
                      complaintInfo={complaintInfo}
                      setComplaintInfo={setComplaintInfo}
                      isVendor={isVendor}
                    />
                  </div>

                  <div className="w-[96%]  md:w-full xl:w-[58.5%] bg-[#343434] rounded-lg p-2 mt-2 ml-2 md:ml-0">
                    <SpecificTicketDetails
                      isVendor={isVendor}
                      tickets={tickets}
                      setShowAssignPopup={setShowAssignPopup}
                      setShowVendorAssignPopup={setShowVendorAssignPopup}
                      selectedComplaintsInfo={selectedComplaintsInfo}
                      complaintInfo={complaintInfo}
                      handleFetchTickets={handleFetchTickets}
                      currentPage={currentPage}
                      selectedFilter={selectedFilter}
                    />
                  </div>
                </div>

                {tickets.length > 0 && (
                  <div className="flex justify-center lg:mt-4 md:gap-0 gap[-4px] xl:mb-1.5 w-full xl:w-[40%] mb-36">
                    <ReactPaginate
                      previousLabel={
                        <div className="flex items-center justify-center md:w-8 md:h-7 w-5 h-4 border rounded border-white text-white">
                          <FaChevronLeft />
                        </div>
                      }
                      nextLabel={
                        <div className="flex items-center justify-center md:w-8 md:h-7 w-5 h-4 border rounded border-white text-white">
                          <FaChevronRight />
                        </div>
                      }
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      forcePage={currentPage - 1}
                      containerClassName="pagination flex space-x-6 mb-4"
                      pageLinkClassName="cursor-pointer"
                      activeLinkClassName="text-blue-500 font-bold"
                      disabledClassName="opacity-50 cursor-not-allowed"
                      previousClassName={currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}
                      nextClassName={
                        currentPage === pageCount ? 'opacity-50 cursor-not-allowed' : ''
                      }
                    />
                  </div>
                )}

                {showAssignPopup && (
                  <AssignUserPopup
                    tickets={tickets}
                    onClose={() => setShowAssignPopup(false)}
                    selectedComplaintsInfo={selectedComplaintsInfo}
                    handleFetchTickets={handleFetchTickets}
                    currentPage={currentPage}
                    selectedFilter={selectedFilter}
                  />
                )}
                {showVendorAssignPopup && (
                  <VendorAssignedPopup
                    tickets={tickets}
                    onClose={() => setShowVendorAssignPopup(false)}
                    selectedComplaintsInfo={selectedComplaintsInfo}
                    handleFetchTickets={handleFetchTickets}
                    currentPage={currentPage}
                    selectedFilter={selectedFilter}
                  />
                )}
              </div>
            ) : (
              <div className="flex content-center justify-center items-center h-4/6 overflow-y-hidden w-full text-gray-300 text-[16px] font-normal">
                No records found
              </div>
            )}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default memo(Complaints);

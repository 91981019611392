import React from 'react';
import './App.css';
import { SocketProvider } from './Contexts/SocketContext';
import MainComponent from './MainComponent';
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <>
      {' '}
      <SocketProvider>
        <MainComponent />
      </SocketProvider>
      <ToastContainer />
    </>
  );
};

export default App;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SupportDetailedQuestion } from '../components/support/SupportDetailedQuestion';
import { SupportListQuestion } from '../components/support/SupportListQuestion';
import HelpOptions from '../components/support/support-home/HelpOptions';

const SupportNavigation = () => {
  const supportNavigation = [
    {
      path: '/',
      element: <HelpOptions />,
      key:'help-options'
    },
    {
      path: '/detailed-question',
      element: <SupportDetailedQuestion />,
       key:'detailed-question'
    },
    {
      path: '/questions',
      element: <SupportListQuestion />,
      key:'support-list-question'
    }
  ];

  return (
    <Routes>
      {supportNavigation.map((route) => (
        <Route key={route.key} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default SupportNavigation;

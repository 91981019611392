import CommonPopup from '../../../../../common/CommonPopup';
import { RiDeleteBin5Line } from 'react-icons/ri';
import React, {  useRef, useState } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';
import { FaRegFileAlt } from 'react-icons/fa';
import { uploadFaqDocuments } from '../../../../../api/AccountSetup';
import ToastNotification from '../../../../../common/ToastNotification';
import AccountSetupButton from '../../../../../common/AccountSetupButton';
const PopupFile = (props: any) => {
  const { onClose, fetchAllDocuments, setIsLoading }=props;
  const [progress, setProgress] = useState<number[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const companyUuid = sessionStorage.getItem('companyUuid');

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const totalFileSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
      let uploadedBytes = 0;
      let progressValue = 0;

      setProgress([0]);

      const totalTime = (totalFileSize / (1024 * 1024)) * 1000;
      const intervalTime = Math.max(totalTime / 100, 10);

      const interval = setInterval(() => {
        uploadedBytes += totalFileSize / 100;

        progressValue = (uploadedBytes / totalFileSize) * 100;

        progressValue = Math.round(progressValue * 100) / 100;

        progressValue = Math.min(progressValue, 100);

        setProgress([progressValue === 99 ? 100 : progressValue]);

        if (progressValue >= 100) {
          clearInterval(interval);

          Array.from(files).forEach((file) => {
            setSelectedFiles((prevFiles) => [...prevFiles, file]);
          });

          setProgress([]);
        }
      }, intervalTime);
    }
  };

  const handleRemoveFile = (fileName: string) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const getTruncatedFileName = (fileName: string) => {
    const extension = fileName.split('.').pop();
    const baseName = fileName.substring(0, fileName.length - extension!.length - 1);
    const truncatedBaseName = baseName.length > 6 ? baseName.substring(0, 6) : baseName;
    return `${truncatedBaseName}.${extension}`;
  };

  const handleSubmit = async () => {
    if (selectedFiles.length > 0) {
      try {
        setIsLoading(true);
        onClose();
        const res = await uploadFaqDocuments(selectedFiles, companyUuid ? companyUuid : '');
        if (res.message === 'Successful') {
          setIsLoading(false);
          ToastNotification({
            message: 'Documents Added Successfully',
            type: 'success'
          });
          fetchAllDocuments();
        } else {
          setIsLoading(false);
          ToastNotification({
            message: 'Something went wrong',
            type: 'error'
          });
        }
      } catch (error: any) {
        setIsLoading(false);
        ToastNotification({
          message: 'Something went wrong',
          type: 'error'
        });
      }
    } else {
      ToastNotification({
        message: 'Please select document',
        type: 'error'
      });
    }
  };


  const content = (
    <div>
      <h2 className="ms-5 lg:mb-6 mb-3 md:text-xl text-lg font-bold">Upload Files</h2>
      <div
        className="flex gap-4 flex-row-reverse items-start"
        style={{
          height: '150px',
          overflowY: 'auto'
        }}
      >
        <div
          className="grid  gap-4 flex-grow"
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(147px, 0fr))',
            gridAutoRows: 'auto'
          }}
        >
          {selectedFiles.map((file) => (
            <div key={file.name} className="flex flex-col items-center">
              <div className="w-28 h-28 border-[3px] rounded-lg border-[#006BEA]">
                <div className="flex justify-end">
                  <RiDeleteBin5Line
                    height={13}
                    width={13}
                    className=" text-[#FA0000] hover:text-[#006BEA] cursor-pointer"
                    onClick={() => handleRemoveFile(file.name)}
                  />
                </div>
                <div className="h-3/4 flex justify-center items-center">
                  <FaRegFileAlt className="w-10 h-10 text-[#006BEA]" />
                </div>
              </div>
              <div className="mt-2">
                <p className="md:text-[24px] text-xl font-normal leading-[27.36px] text-[#ffffff] text-center">
                  {getTruncatedFileName(file.name)}
                </p>
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            <div
              className="w-28 h-28 border-[3px] rounded-lg border-dashed border-[#006BEA] cursor-pointer"
              onClick={handleClick}
            >
              <div className="h-full flex  justify-center items-center">
                <MdOutlineFileUpload className="w-10 h-10 text-[#006BEA]" />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                onChange={handleFileChange}
                accept=".doc,.docx,.pdf"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className=" flex justify-center  mt-5 ">
          <div
            className={`w-[50%] ${progress.length > 0 ? 'bg-gray-200' : ''}   rounded-lg  h-2 overflow-hidden`}
          >
            {progress.length > 0 && !isNaN(progress[0]) && progress[0] !== null && (
              <div
                className="h-2  rounded-lg "
                style={{
                  width: `${progress[0] ? progress[0] : 0}%`,
                  background: 'linear-gradient(90deg, #006BEA 0%, #00306A 100%)'
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="mt-2 text-center text-sm font-semibold text-[20px] h-2">
          {progress[0] > 0 && `${progress[0]}%`}
        </div>
      </div>
    </div>
  );

  const footer = (
    <div className="-mb-6 -mt-6">
      <AccountSetupButton
        isFormValid={true}
        btnText="Submit"
        textColor="#FFFFFF"
        backGroundColor="#006BEA"
        borderColor="#006BEA"
        onClick={handleSubmit}
      />
    </div>
  );

  return (
    <CommonPopup footer={footer} onClose={onClose} isFaqPopup={true}>
      {content}
    </CommonPopup>
  );
};

export default PopupFile;

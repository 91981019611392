import { AiFillCheckCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Subscription = () => {
  const listOfPlans = [
    {
      title: 'Basic Plan',
      price: 'Free',
      features: [
        {
          name: 'Unlimited FAQs and AI-based FAQ generation.'
        },
        {
          name: '50 After-sale service tickets.'
        },
        {
          name: 'Basic analytics'
        },
        {
          name: 'WhatsApp & Email integeration'
        }
      ]
    },
    {
      title: 'Pro Plan',
      price: '₹3999',
      button: 'Subscribe',
      colors: '#006BEA',
      features: [
        {
          name: 'Unlimited FAQs and AI-based FAQ generation.'
        },
        {
          name: 'HubSpot and Zoho CRM integeration'
        },
        {
          name: '5000 After-sale service tickets.'
        },
        {
          name: 'Moderate analytics'
        },
        {
          name: 'WhatsApp & Email integeration'
        },
        {
          name: 'Widget customization'
        },
        {
          name: 'Support'
        }
      ]
    },
    {
      title: 'Advance Plan',
      price: '₹10499',
      button: 'Subscribe',
      colors: '#006BEA',
      features: [
        {
          name: 'Unlimited FAQs and AI-based FAQ generation.'
        },
        {
          name: 'Unlimited After-sale service tickets.'
        },
        {
          name: 'HubSpot and Zoho CRM integeration'
        },
        {
          name: 'Advanced analytics'
        },
        {
          name: 'White label branding'
        },
        {
          name: 'WhatsApp & Email integeration'
        },
        {
          name: 'Widget customization'
        },
        {
          name: 'Support'
        }
      ]
    }
  ];

  return (
    <div className="h-full">
      <div className="flex flex-col gap-5 pt-20 pl-20 pr-14 pb-12">
        <div className="flex justify-between items-start">
          <div className="flex flex-col text-white font-semibold text-6xl gap-3">
            <label>We’ve got a plan</label>
            <label>that’s perfect for you</label>
          </div>
          <label className="px-6 py-2 text-white bg-[#006BEA] font-bold text-xl rounded-lg">
            Skip
          </label>
        </div>

        <div className="border border-white rounded p-1.5 flex self-start gap-2 items-center">
          <label className="p-2 bg-white text-[#1E1E1E] text-lg font-medium rounded">
            Monthly billing
          </label>
          <label className="p-2 text-lg font-medium text-white">Annual billing</label>
        </div>

        <div className="flex items-center justify-evenly">
          {listOfPlans.map((info: any, index: number) => (
            <div className="border border-[#006BEA] rounded-3xl p-6 flex flex-col">
              <label className="font-semibold text-3xl">{info.title}</label>
              <label className="font-semibold text-6xl">{info.price}</label>
              <div
                className=" rounded-md flex justify-center mt-3"
                style={{ backgroundColor: `${info.colors}` }}
              >
                <Link to="/pay">
                  <label className=" font-medium text-sm py-4 px-36 cursor-pointer">
                    {info.button}
                  </label>
                </Link>
              </div>
              <label className="text-xl font-medium my-3">FEATURES</label>
              {info.features.map((item: any) => (
                <div className="flex items-start gap-1">
                  <AiFillCheckCircle />
                  <label className="text-xl font-medium">{item.name}</label>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Subscription;

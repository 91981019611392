import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Links from '../../common/Links';
import Button from '../../common/Button';
import InputBox from '../../common/InputBox';
import Lopa from '../../assets/whitelogo.png';
import { signUpUser } from '../../api/Auth';
import ToastNotification from '../../common/ToastNotification';
import { validateForm } from '../../function/FormValidation';
import EyeButton from '../../common/EyeButton';

const SignUp = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  // const toggleConfirmPasswordVisibility = () => {
  //   setShowConfirmPassword((prev) => !prev);
  // };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(formData, [
      'email',
      'password'
      // 'businessName'
      // 'confirmPassword'
    ]);
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (isValid) {
      try {
        const response = await signUpUser({
          // businessName: formData.businessName || '',
          email: formData.email || '',
          password: formData.password || ''
        });
        if (response.ok) {
          const data = await response.json();
          ToastNotification({
            message: data.message
          });
          const userId = data?.payload?.userUUID;
          setTimeout(() => {
            // navigate('/');
            navigate(`/otp/${userId}`, { state: { email: formData.email } });
            setDisable(false);
            setFormData({
              // businessName: '',
              email: '',
              password: ''
              // confirmPassword: ''
            });
          }, 1500);
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
          setDisable(false);
        }
      } catch (error: any) {
        setDisable(false);
      }
    }
  };

  return (
    <>
      <div className="flex justify-center py-12">
        <img src={Lopa} className="lg:w-44 lg:h-20 w-28 h-16" alt="Matrix AI" />
      </div>
      <div className="flex  h-full flex-col justify-center px-2 py-3  lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg p-2">
          <h2 className=" text-start text-[25px]   md:text-[46px] md:leading-[50.33px] font-bold tracking-tight">
            Welcome!
          </h2>
          {/* <span className="font-normal sm:text-[20px] sm:leading-[37.66px] md:text-[28px]  md:leading-[39.28px]">
            Support system of Sulopa&apos;s
          </span> */}
          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
            <form className="space-y-3" onSubmit={handleSubmit} method="Post">
              <div>
                {/* <div>
                  <InputBox
                    name="businessName"
                    type="text"
                    value={formData.businessName}
                    placeholder="Business Name"
                    handleChange={handleChange}
                    error={errors.businessName}
                  />
                </div> */}
              </div>
              <div>
                <div className="mt-3">
                  <InputBox
                    name="email"
                    type="text"
                    value={formData.email}
                    placeholder="Email"
                    handleChange={handleChange}
                    error={errors.email}
                  />
                </div>
              </div>
              <div>
                <div className="mt-3  relative">
                  <InputBox
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formData.password}
                    placeholder="Password"
                    handleChange={handleChange}
                    error={errors.password}
                  />
                  <EyeButton
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                  />
                </div>
              </div>
              <div>
                {/* <div className="mt-3 relative">
                  <InputBox
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={formData.confirmPassword}
                    placeholder="Confirm Password"
                    handleChange={handleChange}
                    error={errors.confirmPassword}
                  />
                  <EyeButton
                    showPassword={showConfirmPassword}
                    togglePasswordVisibility={toggleConfirmPasswordVisibility}
                  />
                </div> */}
              </div>
              <div className="mt-3">
                <Button
                  isFormValid={true}
                  disable={disable}
                  btnText="Sign up"
                  textColor="#FFFFFF"
                  backGroundColor="#006BEA"
                  borderColor=""
                />
              </div>
            </form>
            {/* <div className="mt-8">
              <Icon />
            </div> */}
            <div className="flex justify-center items-center gap-2 text-base">
              <label>Already an account ?</label>
              <Link to="/login" className="underline text-[#006BEA] font-semibold">
                Login
              </Link>
            </div>
            <div className="mt-8">
              <Links linkText="Login" link="/" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;

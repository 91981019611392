import React from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';

import SidebarMenuNavigation from './SidebarMenuNavigation';
import AuthNavigation from './AuthNavigation';
import AccountSetupAndSettingsMainNavigation from './AccountSetupAndSettingsMainNavigation';


const Navigation = () => {
  return (
  <>  
 
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }}
    >
      <Routes>
        {AuthNavigation}
        {SidebarMenuNavigation}
        {AccountSetupAndSettingsMainNavigation}
      </Routes>
      
</BrowserRouter>
</>
  );
};


export default Navigation;

import React from 'react';

interface Iprops {
  countrycodes: [] | any;
  handleSelect: any;
  handleSearch: any;
  toggleDropdown: any;
  filteredCountries: any;
  dropdownRef: any;
  selectedCountry: any;
  searchQuery: any;
  isOpen: any;
  formData: any;
}
const CountryCode = (props: Iprops) => {
  const {
    countrycodes,
    handleSelect,
    handleSearch,
    toggleDropdown,
    filteredCountries,
    dropdownRef,
    selectedCountry,
    searchQuery,
    isOpen,
    formData
  } = props;
  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        // style={{
        //   width: "42%",
        //   boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 2px 0px;",
        // }}
      >
        <div
          className="dropdown-header flex items-center text-[12px] lg:text-[20px] md:text-[14px] justify-between md:w-[110px] w-[90px]  lg:h-[48px] md:h-8 h-12 bg-[#343434] placeholder:text-[#F3F3E099]  rounded-lg cursor-pointer pt-4 pb-5 md:pr-[40px]  md:pl-5 pl-3"
          onClick={toggleDropdown}
        >
          {selectedCountry ? (
            <div className="flex items-center space-x-1">
              <span
                dangerouslySetInnerHTML={{
                  __html: selectedCountry.flag
                }}
                className="lg:w-6 lg:h-6 md:w-4 md:h-4 w-[14px] h-[14px] flex"
              />
              <span className="ml-2">({selectedCountry.teleCode})</span>
            </div>
          ) : (
            <div className="flex items-center space-x-1">
              <span
                dangerouslySetInnerHTML={{
                  __html: countrycodes[0]?.flag
                }}
                className="w-5 h-5  flex"
              />
              <span className="ml-2">({countrycodes[0]?.teleCode})</span>
            </div>
          )}
        </div>
        {isOpen && (
          <div
            className="absolute z-10 mt-2 w-full  border  rounded-lg shadow-lg "
            style={{ width: '309%' }}
          >
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-full px-3 py-2 border-b border-gray-300 rounded-t-md bg-[#343434] dark:bg-gray-800 dark:text-gray-300 focus:outline-none focus:ring-1 focus:ring-primary"
            />
            <ul
              className="max-h-48 overflow-y-auto bg-[#343434]  rounded-b-md border-t"
              style={{ height: '137px' }}
            >
              {filteredCountries.length > 0 ? (
                filteredCountries.map((country: any) => (
                  <li
                    key={country.code}
                    className="cursor-pointer px-3 py-2 hover:bg-[#006BEA] dark:hover:bg-gray-600 dark:text-gray-300 flex items-center"
                    onClick={() => handleSelect(country)}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: country.flag
                      }}
                      className="w-5 h-5  flex"
                    />
                    <span className="ml-2">
                      {country.name} ({country.teleCode})
                    </span>
                  </li>
                ))
              ) : (
                <li className="px-3 py-2 dark:text-gray-300">No results found</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountryCode;

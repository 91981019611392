import React from 'react';

interface IProps {
  name: string;
  value: string;
  placeholder?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  type?: string;
  onclick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const AccountSetupInputBox = (props: IProps) => {
  const { name, value, placeholder, handleChange, error, type, onclick, disabled } = props;
  return (
    <>
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        onClick={onclick}
        placeholder={placeholder}
        disabled={disabled}
        className="block w-full lg:h-[48px] md:h-8 h-9  rounded-lg border-0 pt-4 pb-5 pr-[40px] lg:pl-5 pl-3   shadow-sm bg-[#343434] placeholder:text-[#F3F3E099] placeholder:font-normal text-[12px] md:text-[14px]  lg:text-[24px] sm:leading-[28.13px]"
      />
      <div
        className={`block h-[16px] ${error && `mb-3`}  font-normal text-[12px] md:text-[14px] md:leading-[22.8px]`}
      >
        {error && <p className="error text-[#ff4c4c]">{error}</p>}
      </div>
    </>
  );
};

export default AccountSetupInputBox;

import StatusCard from './StatusCard';

const StatusCardList = ({ dashboardData }: any) => {
  const data = dashboardData || {};

  const formattedData = [
    {
      title: 'Total Complaints',
      count: data?.totalComplaints?.totalComplaintCount?.toLocaleString() || '0',
      percent: data?.totalComplaints?.percentage ? `${data.totalComplaints.percentage.toFixed(1)}%` : '',
      status:
        data?.totalComplaints?.percentage !== undefined && data.totalComplaints.percentage >= 0
          ? 'positive'
          : 'negative'
    },
    {
      title: 'Complaints Pending',
      count: data?.pending?.complaintsPending?.toLocaleString() || '0',
      percent: data?.pending?.percentage ? `${data.pending.percentage.toFixed(1)}%` : '',
      status:
        data?.pending?.percentage !== undefined && data.pending.percentage >= 0
          ? 'positive'
          : 'negative'
    },
    {
      title: 'Resolved',
      count: data?.resolved?.complaintsResolved?.toLocaleString() || '0',
      percent: data?.resolved?.percentage ? `${data.resolved.percentage.toFixed(1)}%` : '',
      status:
        data?.resolved?.percentage !== undefined && data.resolved.percentage >= 0
          ? 'positive'
          : 'negative'
    },
    {
      title: 'Operators',
      count: data?.operators?.toLocaleString() || '0',
      percent: '',
      status: ''
    }
  ];

  return (
    <div className="grid gap-5 grid-cols-1   md:grid-cols-3 lg:grid-cols-4">
      {formattedData.map((info: any, index: number) => (
        <StatusCard info={info} index={index} key={index} />
      ))}
    </div>
  );
};

export default StatusCardList;

import React, { useEffect, useState } from 'react';
import ListOfUser from './ListOfUser';
import ChatArea from './ChatArea';
import Calender from '../complaints/Calender';

const Chatpage = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [clearButtonShow, setClearButtonShow] = useState(false);

  const [users, setUsers] = useState([
    {
      id: 1234,
      name: 'Nishikanta',
      lastMessage: 'Hello how are you?',
      date: '23/12/2024',
      messages: [
        { type: 'bot', text: 'Welcome to support!', time: '12:00 PM' },
        { type: 'user', text: 'Hi, I need help.', time: '12:05 PM' }
      ]
    },
    {
      id: 5678,
      name: 'Alice',
      lastMessage: 'Need assistance with billing.',
      date: '22/12/2024',
      messages: [
        { type: 'bot', text: 'Sure, how can I help?', time: '11:00 AM' },
        { type: 'user', text: 'Billing issues.', time: '11:15 AM' }
      ]
    },
    {
      id: 9101112,
      name: 'Aswini',
      lastMessage: 'Hello how can assist you today?',
      date: '23/12/2024',
      messages: [
        { type: 'bot', text: 'Welcome to support!', time: '12:00 PM' },
        { type: 'user', text: 'Hi, I need help.', time: '12:05 PM' }
      ]
    },
    {
      id: 13141516,
      name: 'Deba',
      lastMessage: 'Need assistance with billing.',
      date: '22/12/2024',
      messages: [
        { type: 'bot', text: 'Sure, how can I help?', time: '11:00 AM' },
        { type: 'user', text: 'Billing issues.', time: '11:15 AM' }
      ]
    },
    {
      id: 17181920,
      name: 'Babul',
      lastMessage: 'Hello how can assist you today?',
      date: '23/12/2024',
      messages: [
        { type: 'bot', text: 'Welcome to support!', time: '12:00 PM' },
        { type: 'user', text: 'Hi, I need help.', time: '12:05 PM' }
      ]
    },
    {
      id: 21222324,
      name: 'Smaranika',
      lastMessage: 'Need assistance with billing.',
      date: '22/12/2024',
      messages: [
        { type: 'bot', text: 'Sure, how can I help?', time: '11:00 AM' },
        { type: 'user', text: 'Billing issues.', time: '11:15 AM' }
      ]
    }
  ]);

  const [selectedUser, setSelectedUser] = useState(users[0]);

  const handleUserSelect = (userId: number) => {
    const user = users.find((u) => u.id === userId);
    if (user) {
      setSelectedUser(user);
    } else {
      console.error(`User with ID ${userId} not found`);
    }
  };
  const updateUserMessages = (userId: number, newMessage: any) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, messages: [...user.messages, newMessage] } : user
      )
    );
  };
  const handleStartDateChange = (selectedDate: Date | null) => {
    setStartDate(selectedDate);
    if (selectedDate || !selectedDate) {
      setEndDate(null);
    }
    if (!selectedDate) {
      setClearButtonShow(false);
    }
  };
  const handleEndDateChange = (selectedDate: Date | null) => {
    setEndDate(selectedDate);
    setClearButtonShow(true);
  };
  const handelClearFilter = () => {
    setStartDate(null);
    setEndDate(null);

    setClearButtonShow(false);
  };
  useEffect(() => {
    const updatedUser = users.find((user) => user.id === selectedUser?.id);
    if (updatedUser) setSelectedUser(updatedUser);
  }, [users]);
  return (
    <div className="bg-[#1E1E1E] h-screen py-0 w-full px-2 overflow-y-auto xl:overflow-hidden relative">
      <div className="flex md:justify-between md:flex-row flex-col items-center mb-2.5 px-2">
        <label className="text-[28px] text-white font-medium">Message</label>
        <div className="flex md:flex-row flex-col gap-4 ">
          {clearButtonShow && (
            <button
              className="bg-[#006BEA] text-[#FFFFFF] w-[60%] rounded-[6px] md:mt-0 mt-3 p-[8px] lg:p-[6px] xl:p-[10px] font-semibold text-[10px] ml-8 md:ml-2 md:text-[18px] "
              onClick={handelClearFilter}
            >
              Clear Filter
            </button>
          )}

          <div className="gap-3 flex items-center md:flex-row flex-col md:mt-0 mt-2">
            <div className="w-auto">
              <Calender selectedDate={startDate} onDateChange={handleStartDateChange} />
            </div>
            <label className="font-medium">To</label>
            <div className="w-auto">
              <Calender
                selectedDate={endDate}
                onDateChange={handleEndDateChange}
                minDate={
                  startDate ? new Date(new Date(startDate).setDate(startDate.getDate() + 1)) : null
                }
                disabled={!startDate}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#1E1E1E] w-full overflow-y-auto xl:overflow-hidden">
        <div className="flex flex-col xl:flex-row gap-2.5 pb-24 h-auto xl:pb-0">
          <div className="w-full xl:w-[40%]">
            <ListOfUser users={users} onUserSelect={handleUserSelect} selectedUser={selectedUser} />
          </div>

          <div className="w-full xl:w-[58.5%] bg-[#343434] rounded-lg p-2 mt-2">
            <ChatArea selectedUser={selectedUser} updateUserMessages={updateUserMessages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatpage;

import React from 'react';
import { Route } from 'react-router-dom';

import Login from '../components/Authentication/Login';
import SignUp from '../components/Authentication/SignUp';
import ForgotPassword from '../components/Authentication/ForgotPassword';
import ResetPassword from '../components/Authentication/ResetPassword';
import Otp from '../common/Otp';
import PrivacyPolicy from '../components/Authentication/PrivacyPolicy';
import ViewDemo from '../components/Authentication/ViewDemo';

const authNavigation = [
  { path: '/', element: <Login />, key: 'login' },
  { path: '/:uuid', element: <Login />, key: 'login-uuid' },
  { path: '/signup', element: <SignUp />, key: 'signup' },
  { path: '/privacy-policy', element: <PrivacyPolicy />, key: 'privacy-policy' },
  { path: '/demo', element: <ViewDemo />, key: 'demo' },
  { path: '/forgot-password', element: <ForgotPassword />, key: 'forgot-password' },
  { path: '/reset-password', element: <ResetPassword />, key: 'reset-password' },
  { path: '/reset-password/:token', element: <ResetPassword />, key: 'reset-password-token' },
  { path: '/otp/:userId', element: <Otp />, key: 'otp' }
];

export default authNavigation.map((route) => (
  <Route path={route.path} element={route.element} key={route.key} />
));

import React, { ReactNode, useState, useEffect, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { IoSearchSharp, IoSettingsOutline } from 'react-icons/io5';

import complaintsImage from '../../assets/Complaints.png';
import vendorsBlueImage from '../../assets/Vendors-blue.png';
import vendorswhiteImage from '../../assets/Vendors-white.png';
import dashboardBlueImage from '../../assets/Dashboard-blue.png';
import dashboardwhiteImage from '../../assets/Dashboard-white.png';
import blueCompaintsImage from '../../assets/Compaints-blue.png';
import HelpCenter from '../../assets/HelpCenter.png';
import BellIcon from '../../assets/Bell.png';
import Sidebar from '../../common/layout/Sidebar';
import DashboardSidebarItem from '../../common/SidebarItem';
import Navbar from '../../common/layout/Navbar';
import Complaints from '../complaints/Complaints';
import Notification from '../../notifications/Notification';
import { useSocket } from '../../Contexts/SocketContext';
import chat from '../../assets/chatLogo.png';
import SettingsSidebar from '../settings/SettingsSidebar';
import SettingUsers from '../setting-users/SettingUsers';
import ChatBlue from '../../assets/chatblue.png';
interface dashboardLayoutProps {
  children: ReactNode;
}
const DashboardLayout = (props: dashboardLayoutProps) => {
  const location = useLocation();
  const { children } = props;
  const { socketInstance } = useSocket();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSidebar, setActiveSidebar] = useState(1);
  const [hasNotifications, setHasNotifications] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };
  const onClickSearchhandler = () => {
    if (searchInputValue !== '') {
      setSearchQuery(searchInputValue);
    }
  };
  useMemo(() => {
    if (
      location.pathname === '/notifications' ||
      location.pathname === '/complaints' ||
      location.pathname === '/settings/users'
    ) {
      setSearchInputValue('');
      setSearchQuery('');
    }
  }, [location.pathname]);
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = e.currentTarget.value.trim();
    if (trimmedValue === '') {
      setSearchQuery(trimmedValue);
    } else if (e.key === 'Enter') {
      setSearchQuery(trimmedValue);
    }
  };

  const sidebarItems = [
    {
      id: 1,
      image: '/dashboard' === location.pathname ? dashboardBlueImage : dashboardwhiteImage,
      label: 'Dashboard',
      menuUrl: '/dashboard'
    },
    {
      id: 2,
      image: location.pathname.includes('/complaint') ? blueCompaintsImage : complaintsImage,
      label: 'Complaints',
      menuUrl: '/complaints'
    },
    {
      id: 3,
      image: location.pathname.startsWith('/vendors') ? vendorsBlueImage : vendorswhiteImage,
      label: 'Vendors',
      menuUrl: '/vendors'
    },
    {
      id: 4,
      image: location.pathname.startsWith('/chat') ? ChatBlue : chat,
      label: 'Chat',
      menuUrl: '/chat'
    }
  ];

  const handleSidebarItemsClick = (sidebarItemId: number) => {
    setActiveSidebar(sidebarItemId);
  };
  useEffect(() => {
    if (socketInstance) {
      setTimeout(() => {
        socketInstance.emit('getNotifications');
      }, 300);
      socketInstance.on('notifications', (notifications: any) => {
        const mappedunreadNotifications = notifications.filter(
          (notification: any) => !notification.read
        );
        setHasNotifications(mappedunreadNotifications);
      });

      socketInstance.on('updateNotifications', (notification: any) => {
        socketInstance.emit('getNotifications');
      });
    }
  }, [socketInstance]);
  const inputHeader =
    location.pathname === '/complaints' ||
    location.pathname === '/notifications' ||
    location.pathname === '/settings/users' ? (
      <div className="mt-5">
        <div
          className={`flex items-center bg-[#1E1E1E] text-white rounded-3xl ${location.pathname === '/complaints' ? 'ms-[-3px]' : ''} ${location.pathname === '/settings/users' ? 'ms-[-19px]' : ''}   px-4 py-4 w-[200px] sm:w-[364px] md:h-[38px] h-[30px] border-[2px] border-[#FFFFFF]`}
        >
          <input
            value={searchInputValue}
            type="text"
            placeholder="Search here"
            onChange={handleSearch}
            onKeyUp={handleKeyUp}
            className="bg-transparent outline-none  flex-grow placeholder-[#FFFFFF] md:placeholder:text-[18px] placeholder:text-[8px] md:text-[18px] text-[6px]"
          />

          <button onClick={onClickSearchhandler}>
            <IoSearchSharp className="sm:h-[20px] sm:w-[20px] h-4 w-4  text-[#FFFFFF]" />
          </button>
        </div>
      </div>
    ) : (
      <div></div>
    );
  const memoizedSearchQuery = useMemo(() => searchQuery, [searchQuery]);

  const bellAndSettingsIcon = (
    <div className="flex space-x-4 text-white">
      <div className="">
        <Link to="/settings/faq">
          <IoSettingsOutline
            className={`w-[25px] h-[25px]  ${location.pathname.startsWith('/settings') ? 'text-[#006BEA]' : 'text-white'}`}
          />
        </Link>
      </div>
      <div className="relative mx-2 lg:mx-0">
        <Link to="/notifications">
          <img
            src={BellIcon}
            alt="bell"
            className="w-[23px] h-[25px] transition-all duration-300"
          />
        </Link>
        {hasNotifications.length > 0 && (
          <span
            className={`absolute top-[-5px] ${hasNotifications.length < 99 ? 'right-0 left-2 bottom-5' : 'right-[-5px]'} inline-flex items-center justify-center
    ${hasNotifications.length < 99 ? 'w-4 h-4 p-[2px]' : 'w-[18px] h-[18px] px-[2px]'} 
    ${hasNotifications.length < 99 ? 'text-[9px]' : 'text-[8px]'} 
    bg-red-500 text-white font-semibold rounded-full`}
          >
            {hasNotifications.length > 99 ? '99+' : hasNotifications.length}
          </span>
        )}
      </div>
    </div>
  );

  const supportAndHelpdeskContent = (
    <div className="space-y-4 ms-6 ">
      <div className={`flex items-center gap-4 p-2 rounded-md  transition`}>
        <img className={`text-lg lg:text-xl`} src={HelpCenter} alt="help" />

        <span className="text-sm lg:text-[16px]">
          <Link to="/support">Help Center</Link>
        </span>
      </div>
    </div>
  );
  return (
    <div className="flex h-screen bg-[1E1E1E] mb-0 text-white  overflow-y-hidden overflow-x-hidden">
      <Sidebar supportAndHelpdeskContent={supportAndHelpdeskContent}>
        <div className="flex flex-col justify-between ms-2">
          <div>
            <div className="space-y-4">
              {sidebarItems.map((item) => (
                <DashboardSidebarItem
                  key={item.id}
                  image={item.image}
                  label={item.label}
                  isActive={activeSidebar === item.id}
                  handleSidebarItemsClick={handleSidebarItemsClick}
                  id={item.id}
                  url={item.menuUrl}
                />
              ))}
            </div>
          </div>
        </div>
      </Sidebar>

      <Navbar header={inputHeader} bellAndSettingsIcon={bellAndSettingsIcon} layoutPadding={true}>
        {location.pathname === '/complaints' && <Complaints searchQuery={memoizedSearchQuery} />}
        {location.pathname === '/notifications' && (
          <Notification searchQuery={memoizedSearchQuery} />
        )}
        {location.pathname === '/settings/users' && (
          <SettingsSidebar>
            <SettingUsers searchQuery={memoizedSearchQuery} />
          </SettingsSidebar>
        )}
        {location.pathname !== '/complaints' && location.pathname !== '/notifications' && children}
      </Navbar>
    </div>
  );
};

export default DashboardLayout;

import React from 'react';
import {  Route } from 'react-router-dom';

import AccountSetupWizard from '../components/client-setup/AccountSetupWizard';
import AccountSetupNavigation from './AccountSetupNavigation';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import SettingsNavigation from './SettingsNavigation';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import Subscription from '../components/subscription/Subscription';
import { SupportLayout } from '../components/support/SupportLayout';
import SupportNavigation from './SupportNavigation';
import VendorLoginLayout from '../components/vendor-login/VendorLoginLayout';
import VendorNavigation from './VendorNavigation';
import Payment from '../components/subscription/Payment';

const listOfNavigation = [
  {
    path: '/account-setup/*',
    element: (
      <AccountSetupWizard>
        <AccountSetupNavigation />
      </AccountSetupWizard>
    ),
    key: 'account-setup'
  },
  {
    path: '/settings/*',
    element: (
      <DashboardLayout>
        <SettingsNavigation />
      </DashboardLayout>
    ),
    key: 'settings'
  },
  {
    path: '/subscription',
    element: <Subscription />,
    key: 'subscription'
  },
  {
    path: '/pay',
    element: <Payment />,
    key: 'Pay'
  },
  {
    path: '/support/*',
    element: (
      <SupportLayout>
        <SupportNavigation />
      </SupportLayout>
    ),
    key: 'support-routes'
  },
  {
    path: '/vendor-login/*',
    element: (
      <VendorLoginLayout>
        <VendorNavigation />
      </VendorLoginLayout>
    ),
    key:'Vendor-login'
  }
];

export default [
  <Route element={<AuthenticatedRoutes />} key="authenticated-routes">
    {listOfNavigation.map((route) => (
      <Route key={route.key} path={route.path} element={route.element} />
    ))}
  </Route>
];

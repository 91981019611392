import React, { useEffect, useState } from 'react';
import { TbCheck } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getStepperCheck } from '../../api/AccountSetup';

const Stepper = () => {
  const location = useLocation();
  const [accountDetails, setAccountDetails] = useState<any>(null);
  const uuid = sessionStorage.getItem('companyUuid');
  const [currentStep, setCurrentStep] = useState(1);
  const fetchData = async () => {
    try {
      const company = await getStepperCheck(uuid ? uuid : '');
      setAccountDetails(company?.payload);
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [location.pathname]);

  const handleStepClick = (stepId: number) => {
    setCurrentStep(stepId);
  };
  const steps = [
    {
      id: 1,
      step: 'Create Profile',
      dbName: 'companyDetail',
      stepUrl: '/account-setup/create-profile'
    },
    { id: 2, step: 'FAQ', dbName: 'faq', stepUrl: '/account-setup/faq' },
    { id: 3, step: 'Widgets', dbName: 'widget', stepUrl: '/account-setup/widgets' },
    { id: 4, step: 'Integration', dbName: 'integration', stepUrl: '/account-setup/integration' }
  ];
  const submittedStep = accountDetails ?? {
    companyDetail: '',
    faq: '',
    widget: '',
    integration: ''
  };

  return (
   
    <>
      <div className=" hidden 820:block">
        <div className="flex flex-col justify-center lg:ms-6 ms-6 mt-10 lg:mt-0">
          <div className="flex flex-col justify-center lg:ms-6 my-auto flex-1">
            {steps.map((step, index) => (
              <div key={index} className="flex">
                <div className="flex flex-col items-center">
                  <Link
                    to={
                      submittedStep[step.dbName] === 'completed'
                        ? step.stepUrl
                        : `${window.location.pathname}`
                    }
                  >
                    <div
                      className={`rounded-full lg:w-6 lg:h-6 md:w-5 md:h-5 w-4 h-4 border-2 cursor-pointer ${
                        step.stepUrl === location.pathname ? 'p-[3px]' : 'p-0'
                      } mt-0`}
                      onClick={
                        submittedStep[step.dbName] === 'completed'
                          ? () => handleStepClick(step.id)
                          : undefined
                      }
                    >
                      <span
                        className={`flex items-center justify-center w-full h-full rounded-full ${
                          step.stepUrl === location.pathname
                            ? 'bg-blue-500 text-white border-none'
                            : 'border-[#FFFFFF] text-white'
                        }`}
                      >
                        {submittedStep[step.dbName] === 'completed' &&
                        step.stepUrl !== location.pathname ? (
                          <TbCheck className="w-5 h-5" />
                        ) : (
                          <span className="block w-3 h-3 rounded-full bg-transparent border-none"></span>
                        )}
                      </span>
                    </div>
                  </Link>
                  {index !== steps.length - 1 && (
                    <div className="h-20 border-l-2 border-dashed border-[#ffffff]"></div>
                  )}
                </div>

                <div className="ml-2 mt-[-5px] lg:mt-0 pt-0">
                  <span className="lg:text-[18px] md:text-[14px] text-[12px] text-white font-normal">
                    <Link
                      to={
                        submittedStep[step.dbName] === 'completed'
                          ? step.stepUrl
                          : `${window.location.pathname}`
                      }
                    >
                      {step.step}
                    </Link>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="block 820:hidden">
        <div className="flex flex-row justify-between items-center mb-5 lg:mt-5 w-[98%]">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center w-[100%]">
              <div className="mb-2 text-center">
                <span className="lg:text-[18px] md:text-[14px] text-[12px] text-white font-normal">
                  <Link
                    to={
                      submittedStep[step.dbName] === 'completed'
                        ? step.stepUrl
                        : `${window.location.pathname}`
                    }
                  >
                    {step.step}
                  </Link>
                </span>
              </div>

              <div
                className={`flex ${
                  index === steps.length - 1
                    ? 'justify-start ml-[61px] 768-818:ml-[148px] items-center w-full'
                    : 'ml-16 md:ml-[80%] w-full justify-center items-center'
                }`}
              >
                <Link
                  to={
                    submittedStep[step.dbName] === 'completed'
                      ? step.stepUrl
                      : `${window.location.pathname}`
                  }
                >
                  <div
                    className={`rounded-full lg:w-6 lg:h-6 md:w-5 md:h-5 w-4 h-4 border-2 cursor-pointer flex items-center justify-center ${
                      step.stepUrl === location.pathname ? 'p-[3px]' : 'p-0'
                    }`}
                    onClick={
                      submittedStep[step.dbName] === 'completed'
                        ? () => handleStepClick(step.id)
                        : undefined
                    }
                  >
                    <span
                      className={`w-full h-full rounded-full flex items-center justify-center ${
                        step.stepUrl === location.pathname
                          ? 'bg-blue-500 text-white border-none'
                          : 'border-[#FFFFFF] text-white'
                      }`}
                    >
                      {submittedStep[step.dbName] === 'completed' &&
                      step.stepUrl !== location.pathname ? (
                        <TbCheck className="w-5 h-5" />
                      ) : (
                        <span className="block w-3 h-3 rounded-full bg-transparent border-none"></span>
                      )}
                    </span>
                  </div>
                </Link>

                {index !== steps.length - 1 && (
                  <div className="flex-grow h-[2px] border-dashed border-t-2 border-[#ffffff]"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Stepper;

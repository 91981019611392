import React from 'react';
import { toast,  ToastOptions, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastNotificationProps {
  message: string;
  type?: 'success' | 'error' | 'info' | 'warning'; 
}

const ToastNotification: React.FC<ToastNotificationProps> = ({ message, type = 'success' }) => {
  const showToast = () => {
    const toastOptions: ToastOptions = {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce
    };

    switch (type) {
      case 'success':
        toast.success(message, toastOptions);
        break;
      case 'error':
        toast.error(message, toastOptions);
        break;
      case 'info':
        toast.info(message, toastOptions);
        break;
      case 'warning':
        toast.warning(message, toastOptions);
        break;
      default:
        toast.success(message, toastOptions);
    }
  };

  return (
    <>   
      {showToast()}
    </>
  );
};

export default ToastNotification;

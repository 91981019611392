import { useEffect, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { LuClock } from 'react-icons/lu';
import { TfiAngleDown } from 'react-icons/tfi';
import ToastNotification from '../../common/ToastNotification';
import { updateComplaintStatus } from '../../api/Complaints';
import React from 'react';
import Loader from '../../common/Loader';
import moment from 'moment';
import { updateComplaint } from '../../api/VendorLogin';

interface IProps {
  isVendor?: boolean;
  tickets: any;
  setShowAssignPopup: (value: boolean) => void;
  setShowVendorAssignPopup: any;
  selectedComplaintsInfo: any;
  complaintInfo: any;
  handleFetchTickets: any;
  currentPage: any;
  selectedFilter: any;
}

const SpecificTicketDetails = (props: IProps) => {
  const companyUuid = sessionStorage.getItem('companyUuid') || '';

  const {
    tickets,
    setShowAssignPopup,
    setShowVendorAssignPopup,
    selectedComplaintsInfo,
    complaintInfo,
    handleFetchTickets,
    currentPage,
    selectedFilter,
    isVendor
  } = props;

  const [selectedStatus, setSelectedStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = async (event: any, uuid: string) => {
    const UpdatedStatus = event.target.value;
    const getSelectedIndex = tickets.findIndex((info: any) => info.uuid === uuid);
    setSelectedStatus(UpdatedStatus);

    if (UpdatedStatus) {
      setLoading(true);
      try {
        let response;

        if (isVendor) {
          response = await updateComplaint({
            complaintUuid: complaintInfo.uuid,
            status: UpdatedStatus,
            companyUuid: complaintInfo.accountUuid
          });
        } else {
          response = await updateComplaintStatus(
            {
              complaintUuid: selectedComplaintsInfo.uuid,
              status: UpdatedStatus
            },
            companyUuid
          );
        }

        if (response.ok) {
          const data = await response.json();
          handleFetchTickets(selectedFilter, currentPage, '', '', getSelectedIndex, false);
          ToastNotification({ message: data.message });
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
        }
      } catch (error) {
        console.error('Error updating status:', error);
        ToastNotification({
          message: 'An error occurred while updating the status.',
          type: 'error'
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setSelectedStatus(isVendor ? complaintInfo.vendorStatus : selectedComplaintsInfo.status);
  }, [selectedComplaintsInfo.status, complaintInfo.vendorStatus]);
  return (
    <div className="xl:h-[calc(100vh-264px)] flex flex-col gap-3 p-2 xl:p-4">
      {/* Ticket ID */}
      <div className="flex justify-between items-center">
        <div className="flex gap-8">
          <label className="text-xl text-white font-bold">Ticket ID</label>
          <label className="text-lg text-white font-normal">
            {/* {selectedComplaintsInfo.ticketId || '-'} */}
            {isVendor ? complaintInfo.ticketId : selectedComplaintsInfo.ticketId || '-'}
          </label>
        </div>
        {selectedStatus === 'Assigned' ? (
          <LuClock color={'#006BEA'} size={24} />
        ) : (
          <label
            className={`text-lg font-bold cursor-pointer ${
              selectedStatus === 'Resolved'
                ? 'text-[#28A745]'
                : selectedStatus === 'Reopened'
                  ? 'text-[#FA812F]'
                  : selectedStatus === 'Pending'
                    ? 'text-[#FF4C4C]'
                    : ''
            }`}
          >
            {selectedStatus || '-'}
          </label>
        )}
      </div>

      {/* Raised On */}
      <div className="flex gap-6">
        <label className="text-xl text-white font-bold">Raised on</label>
        <label className="text-lg text-white font-normal">
          {/* {selectedComplaintsInfo.raisedOn
            ? moment(selectedComplaintsInfo.raisedOn).local().format('L')
            : '-'} */}
          {isVendor
            ? complaintInfo.raisedOn
              ? moment(complaintInfo.raisedOn).local().format('L')
              : '-'
            : selectedComplaintsInfo.raisedOn
              ? moment(selectedComplaintsInfo.raisedOn).local().format('L')
              : '-'}
        </label>
      </div>

      {/* Message */}
      <div className="flex gap-9">
        <label className="text-xl text-white font-bold">Message</label>
        <label className="text-lg text-white font-normal">
          {/* {selectedComplaintsInfo.title || '-'} */}
          {isVendor ? complaintInfo.title : selectedComplaintsInfo.title || '-'}
        </label>
      </div>

      {/* Product */}
      <div className="flex gap-11">
        <label className="text-xl text-white font-bold">Product</label>
        <label className="text-lg text-white font-normal">
          {/* {selectedComplaintsInfo.product || '-'} */}
          {isVendor ? complaintInfo.product : selectedComplaintsInfo.product || '-'}
        </label>
      </div>

      {/* Assigned */}
      <div className="flex gap-8 items-center">
        <label className="text-xl text-white font-bold">Assigned</label>
        <div
          className={`text-[#ffffff] p-2 rounded-lg ${!isVendor && 'cursor-pointer'} flex items-center justify-center text-sm font-bold border-2 border-[#006BEA]`}
          onClick={() => (isVendor ? {} : setShowAssignPopup(true))}
        >
          {/* {selectedComplaintsInfo.userAssigned ? (
            selectedComplaintsInfo.userAssigned
          ) : (
            <IoMdAdd size={22} />
          )} */}

          {isVendor ? (
            complaintInfo.userAssigned ? (
              complaintInfo.userAssigned
            ) : (
              <IoMdAdd size={22} />
            )
          ) : selectedComplaintsInfo.userAssigned ? (
            selectedComplaintsInfo.userAssigned
          ) : (
            <IoMdAdd size={22} />
          )}
        </div>
      </div>

      {/* Vendor Assigned */}
      <div className="flex gap-8 items-center">
        <label className="text-xl text-white font-bold">
          Vendor
          <br />
          Assigned
        </label>
        <div
          className={`text-[#ffffff] p-2 rounded-lg ${!isVendor && 'cursor-pointer'} flex items-center justify-center text-sm font-bold border-2 border-[#006BEA]`}
          onClick={() => (isVendor ? {} : setShowVendorAssignPopup(true))}
        >
          {/* {selectedComplaintsInfo.vendor ? (
            selectedComplaintsInfo.vendor
          ) : (
            <span>No vendor assigned</span>
          )} */}

          {isVendor ? (
            complaintInfo.vendor ? (
              complaintInfo.vendor
            ) : (
              <span>No vendor assigned</span>
            )
          ) : selectedComplaintsInfo.vendor ? (
            selectedComplaintsInfo.vendor
          ) : (
            <span>No vendor assigned</span>
          )}
        </div>
      </div>

      {/* Status */}
      <div className="flex h-11 items-center overflow-y-hidden gap-7">
        <label className="text-xl text-white font-bold pr-8">Status</label>
        <div className="flex">
          <div className="relative flex items-center">
            <select
              name="type"
              className={`bg-[#1e1e1e] outline-none focus:bg-[#1e1e1e] active:bg-[#1e1e1e] text-lg font-normal rounded-lg px-5 py-1 pr-10 appearance-none cursor-pointer overflow-y-hidden ${
                selectedStatus === 'Pending'
                  ? 'text-[#FF4C4C]'
                  : selectedStatus === 'Reopened'
                    ? 'text-[#FA812F]'
                    : selectedStatus === 'Resolved'
                      ? 'text-[#28A745]'
                      : selectedStatus === 'Assigned'
                        ? 'text-white'
                        : ''
              }`}
              value={selectedStatus}
              onChange={(e: any) => {
                const newStatus = e.target.value;
                if (newStatus !== selectedStatus) {
                  handleChange(e, selectedComplaintsInfo.uuid);
                }
              }}
            >
              <option value="Assigned" className="text-[#ffffff]">
                Assigned
              </option>
              <option value="Pending" className="text-[#FF4C4C]">
                Pending
              </option>
              <option value="Reopened" className="text-[#FA812F]">
                Reopened
              </option>
              <option value="Resolved" className="text-[#28A745]">
                Resolved
              </option>
            </select>
            <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
              <TfiAngleDown />
            </span>
          </div>
          <div className="ml-3">{loading && <Loader />}</div>
        </div>
      </div>
    </div>
  );
};

export default SpecificTicketDetails;

import React from 'react';
import Stepper from '../../components/client-setup/Stepper';
import { useLocation } from 'react-router-dom';
interface navbarProps {
  children: React.ReactNode;
  header?: JSX.Element;
  bellAndSettingsIcon?: JSX.Element;
  isAccountSetup?: boolean;
  layoutPadding?: boolean;
}

const Navbar = (props: navbarProps) => {
  const { children, header, bellAndSettingsIcon, layoutPadding, isAccountSetup } = props;
  const companyName = sessionStorage.getItem('companyName');
  const location = useLocation();
  return (
    <div className="flex flex-col flex-1 ">
      <header className=" md:ps-2 md:pe-10 ps-2 pe-2 px-2 lg:pt-4 pt-2 sticky top-0 bg-[#1E1E1E] md:py-5">
        <div className="flex justify-between items-center">
          {location.pathname === '/account-setup/faq' ||
          location.pathname === '/account-setup/create-profile' ||
          location.pathname === '/account-setup/integration' ||
          location.pathname === '/account-setup/widgets' ? (
            header
          ) : (
            <div className="hidden lg:block md:block md:ml-8">{header}</div>
          )}
          <div className="flex w-full justify-end  items-center sm:space-x-6 space-x-6">
            {/* <div className="text-xl font-normal text-[#DAA520]">
            <Link to="/subscription">Pricing</Link>
          </div> */}
            {bellAndSettingsIcon}

            <div
              className={`${
                location.pathname === '/account-setup/faq' ||
                location.pathname === '/account-setup/create-profile' ||
                location.pathname === '/account-setup/integration' ||
                location.pathname === '/account-setup/widgets'
                  ? ''
                  : 'hidden lg:block'
              } lg:text-[22px] md:text-[18px] sm:text-[15px] text-[11px]`}
            >
              <span className="font-normal  block">Welcome!</span>
              <span className="font-bold block">{companyName ? companyName : ''}</span>
            </div>
          </div>
        </div>
        {(location.pathname === '/account-setup/faq' ||
          location.pathname === '/account-setup/create-profile' ||
          location.pathname === '/account-setup/integration' ||
          location.pathname === '/account-setup/widgets') && (
          <div className="block 820:hidden">
            <Stepper />
          </div>
        )}

        {!(
          location.pathname === '/account-setup/faq' ||
          location.pathname === '/account-setup/create-profile' ||
          location.pathname === '/account-setup/integration' ||
          location.pathname === '/account-setup/widgets'
        ) && <div className=" lg:hidden md:hidden my-4 flex justify-center">{header}</div>}
      </header>
      <main
        className={`flex flex-1 md:pb-2 md:px-5 px-2 ${isAccountSetup ? 'overflow-y-auto' : ''}  ${!layoutPadding && 'md:px-5 px-2'}`}
      >
        {children}
      </main>
    </div>
  );
};

export default Navbar;

import React, { useRef, useEffect } from 'react';

interface ToolTipProps {
  width?: string;
  height?: string;
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ToolTip: React.FC<ToolTipProps> = ({ width, height, isVisible, onClose, children }) => {
  const popupRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (e: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div
      ref={popupRef}
      className={`absolute bg-white shadow-md rounded-md z-50 md:right-4 lg:right-4 right-0 py-0.5 ${width ? width : 'w-auto'} ${height ? height : 'h-auto'}`}
    >
      {children}
    </div>
  );
};

export default ToolTip;

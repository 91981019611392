import React, { useEffect, useRef, useState } from 'react';
import InputBox from '../../../common/InputBox';
import { validateForm } from '../../../function/FormValidation';
import { phoneNumberRangeRegex } from '../../../function/Regex';
import Button from '../../../common/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ToastNotification from '../../../common/ToastNotification';
import { getVendorLogin, updateVendor } from '../../../api/VendorLogin';
import { countrycodes } from '../../../country-code/CountryCodes';
import CountryCode from '../../../common/CountryCode';

interface Country {
  code: string;
  flag: string;
  name: string;
  teleCode: string;
}

function Profile() {
  const { vendorid } = useParams();

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const vendorData = await getVendorLogin(vendorid ? vendorid : '');
        const vendorDetail = vendorData?.payload;
        if (vendorDetail) {
          sessionStorage.setItem('fullName', vendorDetail?.fullname || '');
          setFormData({
            name: vendorDetail?.fullname || '',
            email: vendorDetail?.email || '',
            companyName: vendorDetail?.companyName || '',
            contactNumber: vendorDetail?.phone || '',
            address: vendorDetail?.area || '',
            Countrycode: vendorDetail?.code || '',
            Countryname: vendorDetail?.country || ''
          });
          const selectedCountry = countrycodes.find(
            (country) => country.teleCode === vendorDetail?.code
          );
          if (selectedCountry) {
            setSelectedCountry(selectedCountry);
          }
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
        setErrors((prev) => ({ ...prev, fetch: 'Failed to fetch company details.' }));
      }
    };

    fetchCompanyDetails();
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    contactNumber: '',
    address: '',
    Countrycode: '',
    Countryname: ''
  });
  const navigates = useNavigate();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disable, setDisable] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };
  const handleUpdateVendorProfile = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
      | undefined
  ) => {
    e?.preventDefault();
    const { isValid, errors } = validateForm(formData, [
      'name',
      'email',
      'companyName',
      'contactNumber',
      'address'
    ]);

    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (isValid) {
      try {
        const response = await updateVendor(
          {
            fullName: formData.name || '',
            email: formData.email || '',
            companyName: formData.companyName || '',
            phone: formData.contactNumber || '',
            address: formData.address || '',
            code: formData.Countrycode || '',
            country: formData.Countryname || ''
          },
          vendorid ? vendorid : ''
        );
        if (response.ok) {
          const data = await response.json();
          ToastNotification({
            message: data.message
          });
          setTimeout(() => {
            setDisable(false);
          }, 1500);
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
          setDisable(false);
        }
      } catch (error) {
        setDisable(true);
      }
    }
  };
  /*Country code and name implementation*/
  const handlePhoneChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      Countrycode: value
    }));
  };
  useEffect(() => {
    handlePhoneChange(formData.Countrycode);
  }, [formData.contactNumber]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setFormData({
        ...formData,
        Countryname: selectedCountry.name,
        Countrycode: selectedCountry.teleCode
      });
    }
  }, [selectedCountry]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (country: Country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredCountries = countrycodes?.filter(
    (country: Country) =>
      country.teleCode.includes(searchQuery) ||
      country.code.toLowerCase().includes(searchQuery) ||
      country.name.toLowerCase().includes(searchQuery)
  );

  return (
    <div className="flex flex-col flex-1 bg-[#1E1E1E] h-[calc(100vh-120px)] overflow-y-auto md:pl-10">
      <form
        className="flex flex-col justify-between mt-5"
        onSubmit={handleUpdateVendorProfile}
        method="Post"
      >
        <div className="space-y-0.5 md:pr-4 px-5">
          {' '}
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Name
            </label>
            <div className=" md:flex-grow">
              <InputBox
                name="name"
                type="text"
                value={formData.name}
                placeholder="Enter Your Name"
                handleChange={handleChange}
                error={errors.name}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Email
            </label>
            <div className="md:flex-grow ">
              <InputBox
                name="email"
                type="text"
                value={formData.email}
                placeholder="Enter Your Email"
                handleChange={handleChange}
                error={errors.email}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Company Name
            </label>
            <div className="md:flex-grow ">
              <InputBox
                name="companyName"
                type="text"
                value={formData.companyName}
                placeholder="Enter Your Company Name"
                handleChange={handleChange}
                error={errors.companyName}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Contact Number
            </label>
            <div className="flex flex-grow gap-2">
              <CountryCode
                formData={formData}
                isOpen={isOpen}
                searchQuery={searchQuery}
                selectedCountry={selectedCountry}
                dropdownRef={dropdownRef}
                filteredCountries={filteredCountries}
                toggleDropdown={toggleDropdown}
                handleSearch={handleSearch}
                handleSelect={handleSelect}
                countrycodes={countrycodes}
              />
              <div className="md:flex-grow ">
                <InputBox
                  name="contactNumber"
                  type="text"
                  value={formData.contactNumber}
                  placeholder="Enter Your Phone Number"
                  error={errors.contactNumber}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (phoneNumberRangeRegex.test(value)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Address
            </label>
            <div className="md:flex-grow ">
              <InputBox
                name="address"
                type="text"
                value={formData.address}
                placeholder="Enter Your Address"
                handleChange={handleChange}
                error={errors.address}
              />
            </div>
          </div>
        </div>

        {/* <ToastContainer /> */}
      </form>
      <div className="flex  justify-end items-end mt-auto  px-5 lg:mb-10">
        <Button
          isFormValid={true}
          btnText={'Update'}
          disable={disable}
          textColor="#FFFFFF"
          backGroundColor="#006BEA"
          onClick={(event) => handleUpdateVendorProfile(event)}
        />
      </div>
      {disable && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
    </div>
  );
}

export default Profile;

import { FaPlus } from 'react-icons/fa';
import InputBox from '../../../../../common/InputBox';
import React, { useState, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { validateForm } from '../../../../../function/FormValidation';
import { Config } from 'jodit/config';
import QuestionItem from './QuestionItem';
import CommonPopup from '../../../../../common/CommonPopup';
import faqAddORUpdate from './FaqAddOrUpdate';
import { useNavigate } from 'react-router-dom';

interface FormData {
  textBox: string;
  editors: string;
  uuid?: string | null;
}
interface Iprops {
  questionsList: Array<{ question: string; answer: string; uuid?: string | null }>;
  setQuestionsList: React.Dispatch<
    React.SetStateAction<Array<{ question: string; answer: string; uuid?: string | null }>>
  >;
  isSettings?: boolean;
  fetchAllFaq?: any;
  isAddOrEditPopUpOpen?: boolean;
  setIsAddOrEditPopUpOpen?: (open: boolean) => void;
  handleDeleteFaq: (index: number, faqUUID: string) => void;
  isLoading?: boolean;
  setIsLoading?: any;
  loading?: boolean;
}

const RichTextBox = (props: Iprops) => {
  const navigate = useNavigate();
  const {
    questionsList,
    setQuestionsList,
    isSettings,
    fetchAllFaq,
    setIsAddOrEditPopUpOpen,
    isAddOrEditPopUpOpen,
    handleDeleteFaq,
    isLoading,
    setIsLoading
  } = props;
  const [formData, setFormData] = useState<FormData>({
    textBox: '',
    editors: '',
    uuid: ''
  });
  const [isDisable, setDisable] = useState(false);
  const [errors, setErrors] = useState<{ question?: string; answer?: string }>({});
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  useEffect(() => {
    if (!isAddOrEditPopUpOpen) {
      setFormData({ textBox: '', editors: '<p><br></p>', uuid: null });
      setErrors({ answer: '', question: '' });
    }
  }, [isAddOrEditPopUpOpen]);

  const handleAddQuestion = () => {
    const { isValid, errors } = validateForm(
      { question: formData.textBox, answer: formData.editors },
      ['question', 'answer']
    );

    if (!isValid) {
      setErrors(errors);
      return false;
    }

    setErrors({});

    if (editingIndex !== null) {
      const updatedQuestions = [...questionsList];
      updatedQuestions[editingIndex] = {
        question: formData.textBox,
        answer: formData.editors,
        uuid: formData.uuid
      };
      setQuestionsList(updatedQuestions);
    } else {
      setQuestionsList([
        ...questionsList,
        { question: formData.textBox, answer: formData.editors, uuid: null }
      ]);
    }

    if (setIsAddOrEditPopUpOpen) {
      setIsAddOrEditPopUpOpen(false);
    }
    setFormData({ textBox: '', editors: '<p><br></p>', uuid: null });
    setEditingIndex(null);
    return isValid;
  };

  const handleEditQuestion = (index: number) => {
    const questionToEdit = questionsList[index];
    setFormData({
      textBox: questionToEdit.question,
      editors: questionToEdit.answer,
      uuid: questionToEdit.uuid
    });
    setEditingIndex(index);
  };

  function settingsFaqAddOrUpdate() {
    const isValid = handleAddQuestion();
    if (isValid) {
      setQuestionsList((prevQuestionsList) => {
        const updatedList = [...prevQuestionsList];
        setIsLoading(true);
        faqAddORUpdate({
          questionsList: updatedList,
          setQuestionsList,
          faqList: [],
          navigate,
          setDisable,
          isSettings,
          fetchAllFaq
        });
        return updatedList;
      });
    }

    setIsLoading(false);
  }

  const config = useMemo(
    () =>
      ({
        readonly: false,
        showPoweredBy: false,
        uploader: {
          insertImageAsBase64URI: true
        },
        theme: 'dark',
        style: {
          color: 'white',
          borderRadius: '10px',
          height: '180px',
          overflowY: 'auto'
        },

        enter: 'p',
        defaultMode: '1',
        cleanHTML: {
          replaceNewlines: false
        },
        placeholder: 'Enter the answer'
      }) as unknown as Config,
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({ ...formData, textBox: value });

    if (errors.question) {
      setErrors({ ...errors, question: '' });
    }
  };

  const handleAddEditPopupClosed = () => {
    try {
      if (setIsAddOrEditPopUpOpen) {
        setIsAddOrEditPopUpOpen(false);
      }
      setFormData({ textBox: '', editors: '<p><br></p>', uuid: null });
      setEditingIndex(null);
    } catch {}
  };
  const footer = (
    <div className="flex justify-end">
      <button
        type="submit"
        className="  items-center mx-auto w-auto rounded-[7px] py-2 md:px-[22px]  px-2 md:text-xl text-sm font-bold shadow-sm focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue-600 bg-blue-600 text-white border-2 border-blue-600 cursor-pointer"
        onClick={isSettings ? settingsFaqAddOrUpdate : handleAddQuestion}
      >
        {editingIndex == 0 || editingIndex ? 'Update' : 'Submit'}
      </button>
    </div>
  );

  return (
    <>
      <div className={` ml-[4px] md:ml-2 ${isSettings ? 'pe-10 lg:ml-4' : 'pe-5 lg:ml-9'}  `}>
        {(editingIndex === 0 || editingIndex || isAddOrEditPopUpOpen) && (
          <CommonPopup onClose={handleAddEditPopupClosed} isFaqPopup={true}>
            <div className="mt-2">
              <InputBox
                name="question"
                type="text"
                value={formData.textBox}
                placeholder="Enter the question"
                handleChange={(e) => handleChange(e)}
                error={errors.question}
              />
            </div>
            <div>
              <div style={{ marginTop: '10px' }}>
                {formData.editors !== undefined && formData.editors !== null && (
                  <JoditEditor
                    value={formData.editors}
                    config={config}
                    onChange={(newContent) => {
                      setFormData((prev) => ({
                        ...prev,
                        editors: newContent || '<p><br></p>'
                      }));
                    }}
                  />
                )}

                <div className="block h-3">
                  <p className="block h-[16px] my-1 font-normal text-[10px] md:text-[16px] md:leading-[22.8px] error text-[#ff4c4c]">
                    {errors.answer}
                  </p>
                </div>
              </div>
              <div className="flex justify-end">{footer}</div>
            </div>
          </CommonPopup>
        )}

        {isSettings && (
          <div className="flex justify-between md:items-center">
            <p className={`font-medium text-xl md:text-[22px] pl-2 pb-2`}>
              {isSettings && "FAQ's"}
            </p>
            <div
              className="md:h-10 md:w-10 h-8 w-8 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
              onClick={() => setIsAddOrEditPopUpOpen && setIsAddOrEditPopUpOpen(true)}
            >
              <FaPlus className="md:h-5 md:w-5 h-3 w-3" />
            </div>
          </div>
        )}
        {isLoading && isSettings ? (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
          </>
        ) : questionsList.length > 0 ? (
          <div className={`w-100% ${isSettings ? 'ps-9' : ''}`}>
            {questionsList.map((item, index) => (
              <QuestionItem
                key={index}
                item={item}
                index={index}
                editingIndex={editingIndex}
                handleEditQuestion={handleEditQuestion}
                handleDeleteQuestion={handleDeleteFaq}
                questionsList={questionsList}
              />
            ))}
            <br />
          </div>
        ) : (
          <div className="flex flex-1 h-full items-center justify-center mt-44 font-normal text-[16px] text-gray-300">
            {/* <h2 className=""> */}
              { !isLoading ? 'No FAQs are available' : null}
            {/* </h2> */}
          </div>
        )}
      </div>
    </>
  );
};

export default RichTextBox;

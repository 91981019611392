import React from 'react';

const UserCard = ({ user, onSelect, selectedUser }: any) => {
  return (
    <div
      onClick={onSelect}
      className={`bg-[#343434] rounded-lg pt-3.5 pl-3.5 md:pr-2 pb-1.5 cursor-pointer hover:shadow-[0_0_10px_0px_#006BEA] transition-shadow duration-300  'border border-[#006BEA]'} ${selectedUser?.id === user.id ? 'border border-[#006BEA]' : 'border border-transparent'}`}
    >
      <div className="flex justify-between items-center"></div>
      <div className="flex md:gap-x-6 gap-x-3">
        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-500 text-white font-bold">
          {user.name[0]}
        </div>
        <div className="flex flex-col">
          <label className="text-lg text-white font-regular cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap">
            {user.name}
          </label>
          <label className="text-lg text-white font-regular cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap">
            {user.lastMessage}
          </label>
        </div>
      </div>
      <div className="flex justify-end mt-1">
        <label className="text-xs font-bold text-[#D7D7D7] text-right cursor-pointer">
          {user.date}
        </label>
      </div>
    </div>
  );
};

export default UserCard;

import React, { useEffect, useState } from 'react';
import SupportNavbar from '../../common/layout/SupportNavbar';
import { Link } from 'react-router-dom';
import { notifyDashboard } from '../../api/Notification';
import { IoSettingsOutline } from 'react-icons/io5';
import BellIcon from '../../assets/Bell.png';
export const SupportLayout = ({ children }: any) => {
  const [hasNotifications, setHasNotifications] = useState(false);
  const bellAndSettingsIcon = (
    <div className="flex space-x-4 text-white">
      <div className="relative mx-2 lg:mx-0">
        <Link to="/notifications">
          <img src={BellIcon} className="md:w-[23px] md:h-[25px] w-[15px] h-[18px]" alt="bell" />
        </Link>

        {hasNotifications && (
          <span className="absolute top-0 right-0 inline-block md:w-2.5 md:h-2.5 w-2 h-2 bg-red-500 rounded-full"></span>
        )}
      </div>

      <Link to="/settings/faq">
        <IoSettingsOutline
          className={`md:w-[25px] md:h-[25px] w-[20px] h-[20px]  ${location.pathname.startsWith('/settings') ? 'text-[#006BEA]' : 'text-white'}`}
        />
      </Link>
    </div>
  );
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await notifyDashboard();
        const data = await response.json();

        if (Array.isArray(data?.payload?.notification) && data.payload.notification.length > 0) {
          setHasNotifications(true);
        } else {
          setHasNotifications(false);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setHasNotifications(false);
      }
    };

    fetchNotifications();
  }, []);
  return (
    <>
      <SupportNavbar bellAndSettingsIcon={bellAndSettingsIcon} children={children} />
    </>
  );
};

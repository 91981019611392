import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BackButton2 } from '../../../../common/BackButton2';
import IntegrationDataCard from '../../../client-setup/pages/account-integration/IntegrationDataCard';
import {
  getListOfIntegration,
  intiateOAuthForCRM,
  intiateOAuthForDataSource,
  verifyDataSourceTokenInfo,
  verifyTokenInfo
} from '../../../../api/AccountSetup';
import ToastNotification from '../../../../common/ToastNotification';
import DataSourceInfoPopup from './DataSourceInfoPopup';
import AccountSetupButton from '../../../../common/AccountSetupButton';

interface IntegrationProps {
  isSettings?: boolean;
}

export const Integration = (props: IntegrationProps) => {
  const { isSettings } = props;
  const navigate = useNavigate();
  const companyUuid = sessionStorage.getItem('companyUuid');
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const [integrationList, setIntegrationList] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState({ show: false, uuid: '', storeName: '' });

  const handleGoBack = () => {
    navigate('/account-setup/widgets');
  };

  const handleMoveToDashbord = () => {
    navigate('/dashboard');
  };

  const fetchListOfIntegration = async () => {
    try {
      setShowLoading(true);
      const res = await getListOfIntegration(companyUuid ? companyUuid : '');
      if (res.payload) {
        setIntegrationList(res.payload.integrationPartyList);
      } else if (res.message) {
        ToastNotification({
          message: res.message,
          type: 'error'
        });
      }
      setShowLoading(false);
    } catch {
      setShowLoading(false);
    }
  };

  const handleCRMConnect = async (partyUuid: string) => {
    try {
      sessionStorage.setItem('partyUuid', partyUuid);
      sessionStorage.setItem('shop', '');
      const resp = await intiateOAuthForCRM(partyUuid, companyUuid ? companyUuid : '');
      if (resp.message === 'success') {
        window.location.href = resp.payload.url;
      }
    } catch {}
  };

  const handleDataSourceConnect = async () => {
    if (modalInfo.storeName) {
      try {
        sessionStorage.setItem('partyUuid', modalInfo.uuid);
        sessionStorage.setItem('shop', modalInfo.storeName);
        const resp = await intiateOAuthForDataSource(
          modalInfo.storeName,
          isSettings ? 'settings/integration' : 'account-setup/integration'
        );
        if (resp.message === 'success') {
          window.location.href = resp.payload.url;
        }
      } catch {}
    } else {
      ToastNotification({
        message: 'Please enter store name',
        type: 'error'
      });
    }
  };

  const handleVerifyTokenInfo = async (code: string) => {
    try {
      setShowLoading(true);
      const integrationPartyUuid = sessionStorage.getItem('partyUuid');
      const shopName = sessionStorage.getItem('shop');
      const resp =
        shopName === ''
          ? await verifyTokenInfo(
              integrationPartyUuid ? integrationPartyUuid : '',
              companyUuid ? companyUuid : '',
              code
            )
          : await verifyDataSourceTokenInfo(
              shopName || '',
              integrationPartyUuid ? integrationPartyUuid : '',
              companyUuid ? companyUuid : '',
              code,
              isSettings ? 'settings/integration' : 'account-setup/integration'
            );
      if (resp.message === 'success') {
        fetchListOfIntegration();
      } else {
        setShowLoading(false);
      }
    } catch {}
  };

  const handleModal = (partyUuid: string) => {
    setModalInfo({
      ...modalInfo,
      show: !modalInfo.show,
      uuid: partyUuid
    });
  };

  useEffect(() => {
    fetchListOfIntegration();
  }, []);

  useEffect(() => {
    if (code) {
      handleVerifyTokenInfo(code);
    }
  }, [code]);

  return (
    <>
      <div
        className={`${isSettings ? '' : 'pt-3'} h-full w-full min-h-[600px] flex flex-col justify-between px-2 md:px-0`}
      >
        {!isSettings && (
          <div className="block">
            <h2 className="h-[31px] lg:ml-9 ml-[4px] md:ml-2 md:mb-[5px] text-[14px] sm:text-[18px] sm:leading-[26.86px] lg:text-[22px] md:text-[17px]  md:leading-[30.86px] font-normal">
              STEP 4
            </h2>
            <div className="flex ml-[4px] md:ml-1">
              <div className="md:mt-1 mt-0">
                <BackButton2 onClick={handleGoBack} />
              </div>
              <h1 className="h-[39px] lg:ml-0 lg:mt-0 mt-0 mb-[2px] sm:text-[18px] sm:leading-[29.18px]    lg:text-[28px] md:text-[21px] text-[16px] md:leading-[39.28px] font-medium">
                Integration
              </h1>
            </div>
          </div>
        )}

        <div>
          {showLoading ? (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
              </div>
            </>
          ) : (
            <div className="lg:pl-6 pl-2 block">
              <h1 className="lg:text-[22px] md:text-[17px]  text-[14px] font-medium mt-2  sm:ml-[10px]">
                Data Source
              </h1>
              <IntegrationDataCard
                integrationList={integrationList.filter((info: any) => info.type === 'DATA_SOURCE')}
                handleClick={handleModal}
              />
              <h1 className="lg:text-[22px] md:text-[17px]  text-[14px] font-medium mt-2 sm:ml-[4px] pl-1">
                CRM Integration
              </h1>
              <IntegrationDataCard
                integrationList={integrationList.filter((info: any) => info.type === 'CRM')}
                handleClick={handleCRMConnect}
              />
            </div>
          )}
        </div>

        <div className="flex justify-end mt-auto lg:mb-3">
          {!isSettings && (
            <div className="mr-[10px]">
              <AccountSetupButton
                isFormValid={true}
                btnText="Skip"
                textColor="#FFFFFF"
                backGroundColor=""
                borderColor="#006BEA"
                onClick={handleMoveToDashbord}
              />
            </div>
          )}
          <div className={`${isSettings ? 'mr-4 mb-4' : ''}`}>
            <AccountSetupButton
              isFormValid={true}
              btnText={`${isSettings ? 'Update' : 'Next'}`}
              textColor="#FFFFFF"
              backGroundColor="#006BEA"
              borderColor="#006BEA"
              onClick={!isSettings ? handleMoveToDashbord : undefined}
            />
          </div>
        </div>

        {modalInfo.show && (
          <DataSourceInfoPopup
            modalInfo={modalInfo}
            setModalInfo={setModalInfo}
            onClose={() => handleModal('')}
            handleDataSourceConnect={handleDataSourceConnect}
          />
        )}
      </div>
    </>
  );
};

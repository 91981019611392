import React, { Fragment } from 'react';
import { GoDotFill } from 'react-icons/go';

interface IProps {
  integrationList: any[];
  handleClick?: (integrationPartyUuid: string) => void;
}

const IntegrationDataCard = (props: IProps) => {
  const { integrationList, handleClick } = props;
  return (
    <Fragment>
      {integrationList?.length === 0 ? (
        <div className="flex text-gray-300 text-[16px] font-normal p-2">No data found</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4 w-full sm:ml-[4px] p-2">
          {integrationList.map((info: any) => (
            <div
              key={info.uuid}
              className="border-[#343434] border-2 rounded-md p-4 flex flex-col justify-between leading-normal gap-3"
            >
              <div className="flex justify-between items-start">
                <div className="flex items-center">
                  <img src={info.icon} className="lg:w-10 lg:h-10 w-8 h-8" alt="logo" />
                  <label className="pr-2 pl-2 pt-1  lg:text-[18px] text-[14px] font-semibold">
                    {info.name}
                  </label>
                </div>
                <div className="flex-shrink-0">
                  <GoDotFill
                    className={'lg:w-[24px] lg:sh-[24px]'}
                    color={info.enabled ? '#28A745' : '#FA0000'}
                  />
                </div>
              </div>
              <p className=" leading-[25.25px] text-white md:text-[18px] text-[14px]">
                {info.description}
              </p>
              <div>
                <button
                  className={`text-[12px] md:text-[16px] font-bold md:px-1.5 px-1 md:py-1 py-[2px] rounded ${info.enabled ? 'cursor-not-allowed bg-[#505050] text-[#A9A9A9]' : 'cursor-pointer bg-white text-[#006BEA]'}`}
                  onClick={() => (handleClick ? handleClick(info.uuid) : {})}
                  disabled={info.enabled}
                >
                  Connect
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};
export default IntegrationDataCard;

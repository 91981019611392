import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BackButton } from '../../common/BackButton';
import { useNavigate, useLocation } from 'react-router-dom';
import RichTextBox from '../client-setup/pages/faq/faq-manual/RichTextBox';
import { deleteFaq, getFaqList } from '../../api/AccountSetup';
import ToastNotification from '../../common/ToastNotification';

const SettingsSidebar = ({ children }: any) => {
  const companyUuid = sessionStorage.getItem('companyUuid');
  const navigate = useNavigate();
  const location = useLocation();
  const isFaqQuestionListComponent = location.pathname === '/settings/faq';
  const [isLoading, setIsLoading] = useState(false);

  const [questionsList, setQuestionsList] = useState<
    { question: string; answer: string; uuid?: string | null | undefined }[]
  >([]);
  const [isAddOrEditPopUpOpen, setIsAddOrEditPopUpOpen] = useState(false);

  const handleDeleteFaq = async (index: number, faqUUID: string) => {
    if (faqUUID) {
      setIsLoading(true);
      const resp = await deleteFaq(faqUUID, companyUuid ? companyUuid : '');
      if (resp.ok) {
        setIsLoading(false);
        ToastNotification({
          message: 'Deleted Successfully',
          type: 'success'
        });
        fetchAllFaq();
      } else {
        setIsLoading(false);

        ToastNotification({
          message: 'Something went wrong',
          type: 'error'
        });
      }
    } else {
      const updatedQuestions = questionsList.filter((_, i) => i !== index);
      setQuestionsList(updatedQuestions);
      ToastNotification({
        message: 'Removed',
        type: 'success'
      });
    }
  };

  const fetchAllFaq = async () => {
    try {
      setIsLoading(true);
      const faqList = await getFaqList(companyUuid ? companyUuid : '');
      setQuestionsList(faqList.payload.list || []);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching FAQ list:', error);
      setQuestionsList([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (location.pathname === '/settings/faq') {
      fetchAllFaq();
    }
  }, [location.pathname]);
  const SettingsSidebarItems = [
    {
      id: 1,
      name: 'FAQ',
      urlLink: '/settings/faq'
    },
    {
      id: 2,
      name: 'Widgets',
      urlLink: '/settings/widgets'
    },
    {
      id: 3,
      name: 'Integration',
      urlLink: '/settings/integration'
    },
    {
      id: 4,
      name: 'Users',
      urlLink: '/settings/users'
    },
    {
      id: 5,
      name: 'Configuration',
      urlLink: '/settings/configuration'
    }
  ];

  const isActiveLink = (urlLink: string) => {
    return location.pathname.startsWith(urlLink);
  };

  return (
    <div className="md:flex bg-[#1E1E1E] text-white w-full  h-screen overflow-y-auto">
      <div className=" bg-[#1E1E1E] md:py-10 md:block hidden overflow-y-auto">
        <div className="flex md:gap-4 md:mb-[28px] ">
          <div className="sm:w-4 sm:h-4 mt-2">
            <BackButton onClick={() => navigate(-1)} />
          </div>
          <h2 className="md:text-[28px] sm:text-[10px] font-medium md:mt-1 md:ps-3 pt-2.5">
            {' '}
            Settings
          </h2>
        </div>
        <ul className="mt-4 border-r border-[#595959] h-[80%] md:w-[224px] w-[120px] md:ps-7">
          {SettingsSidebarItems.map((item, index) => (
            <li
              key={index}
              className={`md:w-[152px] md:h-[35px] md:text-[20px] sm:text-[12px] sm:me-2 text-left font-medium  ${
                isActiveLink(item.urlLink) ? 'bg-[#FFFFFF] text-[#006BEA] sm:mr-2 ' : ''
              } md:py-[6px] md:px-[10px] sm:text-[12px]  sm:me-2  flex items-center rounded-[5px]   mb-[18px]  `}
            >
              <Link
                to={item.urlLink}
                className="sm:text-[12px] md:text-[20px] sm:p-1.5 text-center sm:w-3 sm:hidden md:block"
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className=" bg-[#1E1E1E]  sm:block md:hidden sidebar-setting flex justify-center items-center self-center mb-2">
        <div className=" sm:w-[5px] md:ps-10 sm:ps-3 sm:ml-2 flex flex-row justify-center items-center fixed top-9">
          {SettingsSidebarItems.map((item, index) => (
            <span
              key={index}
              className={` md:text-[20px] sm:text-[5px] pr-2 text-left font-medium  ${
                isActiveLink(item.urlLink) ? 'bg-[#FFFFFF] text-[#006BEA] sm:mr-2 ' : ''
              } md:py-[6px] md:px-[10px]  sm:me-2  flex items-center rounded-[5px]   mb-[18px]  `}
            >
              <Link
                to={item.urlLink}
                className="text-[13px] md:text-[20px] sm:p-1.5 ps-2 text-center sm:w-3"
              >
                {item.name}
              </Link>
            </span>
          ))}
        </div>
      </div>

      <div className="md:mt-28 overflow-y-auto overflow-x-hidden mb-[70px] flex-col w-full flex-1 flex-grow">
        {!isFaqQuestionListComponent && children}
        {isFaqQuestionListComponent && (
          <RichTextBox
            questionsList={questionsList}
            setQuestionsList={setQuestionsList}
            isSettings={true}
            fetchAllFaq={fetchAllFaq}
            isAddOrEditPopUpOpen={isAddOrEditPopUpOpen}
            setIsAddOrEditPopUpOpen={setIsAddOrEditPopUpOpen}
            handleDeleteFaq={handleDeleteFaq}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
      </div>
    </div>
  );
};

export default SettingsSidebar;

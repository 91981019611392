import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';
import { Link } from 'react-router-dom';

interface navbarProps {
  children: React.ReactNode;
  header?: JSX.Element;
  bellAndSettingsIcon?: JSX.Element;
  isAccountSetup?: boolean;
  layoutPadding?: boolean;
}

const SupportNavbar = (props: navbarProps) => {
  const { children,  bellAndSettingsIcon, layoutPadding, isAccountSetup } = props;
  const companyName = sessionStorage.getItem('companyName');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col flex-1 ">
      <header className="flex justify-between items-center md:ps-2 md:pe-10 ps-2 pe-2 px-2 pt-4 sticky top-0 bg-[#1E1E1E] md:py-5">
        <div className="flex items-center space-x-6 pl-8">
          <FaBars
            className="text-[#006BEA] text-[30px] cursor-pointer"
            onClick={handleSidebarToggle}
          />
          <span className="text-white text-sm md:text-[22px]">Support AI Help</span>
        </div>

        <div className="flex items-center sm:space-x-6 space-x-6">
          {bellAndSettingsIcon}

          <div className="md:text-[22px] sm:text-[15px] text-[15px]">
            <span className="font-normal text-white block">Welcome!</span>
            <span className="font-bold text-white block">{companyName ? companyName : ''}</span>
          </div>
        </div>
      </header>
      {isSidebarOpen && (
        <div
          className={`fixed top-0 left-0 h-full bg-[#2D2D2D] z-50 text-white pl-4 transition-transform duration-700 ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
          style={{ width: '300px' }}
        >
          <div className="flex justify-between items-center px-5 py-4 mt-10">
            <h2 className="text-lg font-bold md:text-[22px]">Support AI Help</h2>
            <button onClick={handleSidebarToggle}>
              <RxCross2 className="text-sm md:text-[30px] cursor-pointer text-[#006BEA]" />
            </button>
          </div>

          <div className="mt-4 min-h-[600px] flex flex-col justify-between px-4">
            <div>
              <ul className="space-y-4">
                <li>
                  <Link to="/dashboard" className="text-[22px]">
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/support" className="text-[22px]">
                    Help Center
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="space-y-4">
                <li>
                  <Link to="/" className="text-[22px]">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/" className="text-[22px]">
                    Terms Of Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <main
        className={`flex flex-1 md:pb-2 md:px-5 px-2 ${isAccountSetup ? 'overflow-y-auto' : ''}  ${!layoutPadding && 'md:px-5 px-2'}`}
      >
        {children}
      </main>
    </div>
  );
};

export default SupportNavbar;

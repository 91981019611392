import React from 'react';

interface ComplaintCardProps {
  info: any[];
  usersWithUnassignedComplaints: any[];
  navigatetocomplaints: any;
}
const ComplaintsCard = ({
  info,
  usersWithUnassignedComplaints,
  navigatetocomplaints
}: ComplaintCardProps) => {

  const VendorProfile = () => {
    return (
      <div className="flex justify-end relative">
        {usersWithUnassignedComplaints?.length === 0 ? (
          <div className="text-gray-300 font-normal text-xs">No users available currently.</div>
        ) : (
          <>
            <div>
              {usersWithUnassignedComplaints?.slice(0, 3).map((value: any, index: number) => {
                const zIndex = 20 - index * 10;
                let right;

                if (usersWithUnassignedComplaints?.length === 1) {
                  right = 0;
                } else if (usersWithUnassignedComplaints?.length === 2) {
                  right = 8 - index * 20;
                } else if (usersWithUnassignedComplaints?.length >= 3) {
                  right = 88 - index * 20;
                }

                return (
                  <div
                    className={`w-8 h-8 bg-[#D9D9D9] rounded-full cursor-pointer items-center flex justify-center text-black font-regular text-lg font-bold absolute`}
                    style={{ zIndex, ...(right !== undefined && { right: `${right}px` }) }}
                    key={index}
                  >
                    {value.fullName[0]?.toUpperCase() || 'A'}
                  </div>
                );
              })}
            </div>
            <div className="">
              <label className="text-lg block content-center font-bold text-white rounded-3xl">
                {usersWithUnassignedComplaints?.length > 2
                  ? `${usersWithUnassignedComplaints?.length}+`
                  : '\u00A0'}
              </label>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      {info?.map((value: any, index: any) => (
        <div
          className="flex flex-col bg-[#616161] rounded-md my-2 p-1.5 gap-y-2 pl-2.5"
          key={index}
          onClick={() => {
            navigatetocomplaints();
          }}
        >
          <label className="text-sm font-bold text-white">{value.title}</label>
          <label className="text-xs font-normal text-white text-ellipsis overflow-hidden whitespace-nowrap w-[98%] leading-3">
            {value.description}
          </label>
          <div className="p-3">
            <VendorProfile />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComplaintsCard;

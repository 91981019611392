import React, { useEffect, useState } from 'react';
import CommonPopup from '../../../../../common/CommonPopup';
import { MdDeleteOutline } from 'react-icons/md';
import Button from '../../../../../common/Button';
import InputBox from '../../../../../common/InputBox';
import { editFaqUrl, uploadFaqURLs } from '../../../../../api/AccountSetup';
import ToastNotification from '../../../../../common/ToastNotification';
import { toast } from 'react-toastify';
import { urlRegex } from '../../../../../function/Regex';
import { FaPlus } from 'react-icons/fa';

const AddUrlPopup = ({ onClose, fetchAllDocuments, editUrlPopupData, closePopup2 }: any) => {
  const companyUuid = sessionStorage.getItem('companyUuid');
  const [isDisable, setIsDisable] = useState(false);

  const [defaultUrlFields, setDefaultUrlFields] = useState([
    { id: 1, value: '', error: '' },
    { id: 2, value: '', error: '' },
    { id: 3, value: '', error: '' }
  ]);
  const [additionalUrlFields, setAdditionalUrlFields] = useState<
    { id: number; value: string; error: string }[]
  >([]);
  useEffect(() => {
    if (editUrlPopupData) {
      setDefaultUrlFields([
        { id: editUrlPopupData.uuid, value: editUrlPopupData.path, error: '' },
        { id: 2, value: '', error: '' },
        { id: 3, value: '', error: '' }
      ]);
    }
  }, [editUrlPopupData]);
  const handleDefaultUrlChange = (id: number, value: string) => {
    const checkVal = !urlRegex.test(value.trim());
    setDefaultUrlFields((prev) =>
      prev.map((item) =>
        item.id === id
          ? { ...item, value, error: value && checkVal ? 'Please enter valid URL' : '' }
          : item
      )
    );
  };
  const clearDefaultUrlValue = (id: number) => {
    setDefaultUrlFields((prev) =>
      prev.map((item) => (item.id === id ? { ...item, value: '' } : item))
    );
  };
  const handleAdditionalUrlChange = (id: number, value: string) => {
    const checkVal = !urlRegex.test(value.trim());
    setAdditionalUrlFields((prev) =>
      prev.map((item) =>
        item.id === id
          ? { ...item, value, error: value && checkVal ? 'Please enter valid URL' : '' }
          : item
      )
    );
  };
  const handleAdd = () => {
    setAdditionalUrlFields((prev) => [...prev, { id: Date.now(), value: '', error: '' }]);
  };

  const handleDeleteAdditionalUrl = (id: number) => {
    setAdditionalUrlFields((prev) => prev.filter((item) => item.id !== id));
  };

  const handleAddUrlApiCall = async (payload: any) => {
    try {
      setIsDisable(true);
      const res = await uploadFaqURLs(payload, companyUuid ? companyUuid : '');
      if (res.message === 'Successful') {
        ToastNotification({
          message: 'URL Added',
          type: 'success'
        });
        setIsDisable(false);
        closePopup2();
        fetchAllDocuments();
        setTimeout(() => {
          onClose();
          toast.dismiss();
        }, 1500);
      } else {
        setIsDisable(false);
        ToastNotification({
          message: 'Something went wrong',
          type: 'error'
        });
      }
    } catch {
      setIsDisable(false);
    }
  };
  const handleUpdateUrlApiCall = async () => {
    if (defaultUrlFields[0].value && defaultUrlFields[0].id) {
      setIsDisable(true);
      const { id, value } = defaultUrlFields[0];

      const payloadData = { uuid: id.toString(), url: value };
      const res = await editFaqUrl(payloadData, companyUuid ? companyUuid : '');
      if (res.message === 'Successful') {
        ToastNotification({
          message: res.message,
          type: 'success'
        });
        setIsDisable(false);
        closePopup2();
        fetchAllDocuments();
        setTimeout(() => {
          onClose();
          toast.dismiss();
        }, 1500);
      } else {
        setIsDisable(false);

        ToastNotification({
          message: res.message,
          type: 'error'
        });
      }
    } else {
      setIsDisable(false);
      ToastNotification({
        message: 'Please enter valid url',
        type: 'error'
      });
    }
  };

  const handleSubmit = async () => {
    const urlsAdded = [...defaultUrlFields, ...additionalUrlFields];
    const errors = urlsAdded?.filter((info: any) => info.error).map((info: any) => info.error);
    if (errors.length === 0) {
      const formattedURLS = urlsAdded
        ?.filter((info: any) => info.value)
        .map((info: any) => info.value);
      const payload = { urls: formattedURLS };
      if (editUrlPopupData) {
        handleUpdateUrlApiCall();
      } else if (formattedURLS.length > 0) {
        handleAddUrlApiCall(payload);
      } else {
        ToastNotification({
          message: 'Please enter url',
          type: 'error'
        });
      }
    } else {
      ToastNotification({
        message: 'Please enter valid url',
        type: 'error'
      });
    }
  };

  const content = (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 backdrop max-h-[100vh] overflow-hidden">
        <div className="bg-[#1e1e1e] lg:p-6 p-4 rounded-lg md:w-1/2 w-[80%] ">
          <div className="flex md:justify-end mt-[-48px]  px-1 pt-3">
            <Button
              isFormValid={true}
              btnText={`${editUrlPopupData ? 'Update' : 'Submit'}`}
              textColor="#FFFFFF"
              backGroundColor="#006BEA"
              disable={isDisable}
              borderColor="#006BEA"
              onClick={handleSubmit}
            />
          </div>
          <div className="overflow-y-scroll lg:h-[60vh] h-[50%]">
            {defaultUrlFields.map((item) => (
              <>
                <div key={item.id} className={`relative ${item.id % 2 == 1 ? 'md:mt-4 mt-1' : ''} px-1`}>
                  <p className="mb-2 text-[16px] lg:text-[22px]">Add URL</p>
                  <div className="relative">
                    <InputBox
                      disabled={editUrlPopupData && (item.id === 2 || item.id === 3)}
                      name={`permanent-${item.id}`}
                      type="text"
                      value={item.value}
                      placeholder=""
                      handleChange={(e) => handleDefaultUrlChange(item.id, e.target.value)}
                      error={item.error}
                    />
                    {item.value != '' && (
                      <span
                        className="absolute top-[37%] right-[0.75rem] transform -translate-y-1/2 cursor-pointer"
                        onClick={() => clearDefaultUrlValue(item.id)}
                      >
                        <MdDeleteOutline className="text-[#FA0000] w-5 h-6" />
                      </span>
                    )}
                  </div>
                </div>
                {item.id == 1 && (
                  <div className="md:py-4 py-1">
                    <p className="text-[16px] lg:text-[22px]  font-normal italic text-[#5F5F5F] px-1">
                      Optional
                    </p>
                  </div>
                )}
              </>
            ))}
            {additionalUrlFields.map((item) => (
              <div key={item.id} className="relative md:mt-4 mt-1 px-1">
                <p className="mb-2 text-[22px]">Add URL</p>
                <div className="relative">
                  <InputBox
                    name={`dynamic-${item.id}`}
                    type="text"
                    value={item.value}
                    placeholder=""
                    handleChange={(e) => handleAdditionalUrlChange(item.id, e.target.value)}
                    error={item.error}
                  />
                  <span
                    className="absolute top-[37%] right-[0.75rem] transform -translate-y-1/2 cursor-pointer"
                    onClick={() => handleDeleteAdditionalUrl(item.id)}
                  >
                    <MdDeleteOutline className="text-[#FA0000] w-5 h-6" />
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-end px-1">
            <div
              className={`lg:h-10 lg:w-10 h-8 w-8 rounded-lg flex justify-center items-center ${
                editUrlPopupData
                  ? 'bg-[#616161] cursor-not-allowed  color-[#006BEA]'
                  : 'bg-[#006BEA]  cursor-pointer'
              }`}
              onClick={editUrlPopupData ? undefined : handleAdd}
            >
              <FaPlus className={`lg:h-5 lg:w-5 h-4 w-4  ${editUrlPopupData ? 'text-[#B3b3b3]' : 'text-white'}`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return <CommonPopup onClose={onClose}>{content} </CommonPopup>;
};

export default AddUrlPopup;

import { API_URL } from './Environment';

async function getChatOptions(companyUuid: string) {
  const response = await fetch(API_URL + `admin/get-chat-options?accountUuid=${companyUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  const data = await response.json();
  return data;
}

async function postChatOptions(configInfo: any, companyUuid: string) {
  const response = await fetch(API_URL + `admin/add-chat-options?accountUuid=${companyUuid}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(configInfo)
  });
  return response;
}

export { getChatOptions, postChatOptions };

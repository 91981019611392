import React, { useState } from 'react';
import Lopa from '../../assets/whitelogo.png';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../Contexts/SocketContext';
import { logoutUser } from '../../api/Auth';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';

interface sidebarProps {
  children: React.ReactNode;
  supportAndHelpdeskContent?: React.ReactNode;
}

const Sidebar = (props: sidebarProps) => {
  const { disconnectSocket } = useSocket();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogoutClick = async () => {
    try {
      const uuid = sessionStorage.getItem('userUUID');

      if (uuid) {
        const response = await logoutUser(uuid);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('companyName');
        sessionStorage.removeItem('companyUuid');
        sessionStorage.removeItem('userUUID');
        sessionStorage.removeItem('partyUuid');
        sessionStorage.removeItem('shop');

        disconnectSocket();
        navigate('/');
        if (response.ok) {
        } else {
          console.error('Failed to logout:', await response.text());
        }
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const { children, supportAndHelpdeskContent } = props;

  return (
    <div
      className={`${location.pathname === '/account-setup/faq' || location.pathname === '/account-setup/create-profile' || location.pathname === '/account-setup/integration' || location.pathname === '/account-setup/widgets' ? ' hidden 820:block' : ''} `}
    >
      <div className="relative">
        <div className="lg:hidden fixed px-2 py-1 z-50">
          {!isSidebarOpen && (
            <HiOutlineMenuAlt3
              className="text-white text-3xl cursor-pointer"
              onClick={toggleSidebar}
            />
          )}
        </div>

        <div
          className={`fixed top-0 left-0 h-screen min-h-[600px] bg-[#343434] lg:p-6 flex flex-col justify-between lg:static transition-transform duration-300 z-50 ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } lg:translate-x-0 lg:flex lg:w-[320px] w-64`}
        >
          <div>
            <div className="lg:mb-14 my-6 flex items-center justify-center">
              <img src={Lopa} className="lg:w-40 lg:h-20 w-32 h-16" alt="Matrix AI" />
            </div>

            <div onClick={toggleSidebar}>{children}</div>
          </div>

          <div>
            {supportAndHelpdeskContent}

            <Button
              isFormValid={true}
              btnText="Logout"
              textColor="#006BEA"
              backGroundColor="white"
              onClick={handleLogoutClick}
            />
          </div>
        </div>

        {isSidebarOpen && (
          <div
            className=" fixed inset-0 bg-black bg-opacity-50 lg:hidden z-40"
            onClick={toggleSidebar}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;

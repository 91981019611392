import { ErrorMessages } from './ErrorMessages';
import { emailRegex, passwordRegex, phoneNumberRegex, websiteRegex } from './Regex';

export interface ValidationErrors {
  [key: string]: string;
}

export interface FormData {
  [key: string]: string;
}

export const validateForm = (
  formData: FormData,
  fieldsToValidate: string[]
): { isValid: boolean; errors: ValidationErrors } => {
  const errors: ValidationErrors = {};
  let isValid = true;

  // Validate only the specified fields
  fieldsToValidate.forEach((field) => {
    const value = formData[field];
    let error = '';

    // Perform field-specific validation
    if (field === 'businessName') {
      if (!value?.trim()) {
        error = ErrorMessages.businessName.required;
      } else if (value.trim().length < 3) {
        error = ErrorMessages.businessName.invalid;
      }
    } else if (field === 'email') {
      if (!value?.trim()) {
        error = ErrorMessages.email.required;
      } else if (!emailRegex.test(value)) {
        error = ErrorMessages.email.invalid;
      }
    } else if (field === 'password') {
      if (!value?.trim()) {
        error = ErrorMessages.password.required;
      } else if (!passwordRegex.test(value)) {
        error = ErrorMessages.password.invalid;
      }
    } else if (field === 'confirmPassword') {
      if (!value?.trim()) {
        error = ErrorMessages.confirmPassword.required;
      } else if (value !== formData.password) {
        error = ErrorMessages.confirmPassword.mismatch;
      }
    } else if (field === 'targetWebsite') {
      if (!value?.trim()) {
        error = ErrorMessages.targetWebsite.required;
      } else if (!websiteRegex.test(value)) {
        error = ErrorMessages.targetWebsite.invalid;
      }
    } else if (field === 'companyName') {
      if (!value?.trim()) {
        error = ErrorMessages.companyName.required;
      }
    } else if (field === 'contactNumber') {
      if (!value?.trim()) {
        error = ErrorMessages.contactNumber.required;
      }
      // else if (!phoneNumberRegex.test(value)) {
      //   error = ErrorMessages.contactNumber.invalid;
      // }
    } else if (field === 'address') {
      if (!value?.trim()) {
        error = ErrorMessages.address.required;
      }
    } else if (field === 'industry') {
      if (!value?.trim()) {
        error = ErrorMessages.industry.required;
      }
    } else if (field === 'name') {
      if (!value?.trim()) {
        error = ErrorMessages.name.required;
      }
    } else if (field === 'employeeId') {
      if (!value?.trim()) {
        error = ErrorMessages.employeeId.required;
      }
    } else if (field === 'position') {
      if (!value?.trim()) {
        error = ErrorMessages.position.required;
      }
    } else if (field === 'question') {
      if (!value?.trim()) {
        error = ErrorMessages.question.required;
      }
    } else if (field === 'orderUuid') {
      if (!value?.trim()) {
        error = ErrorMessages.orderUuid.required;
      }
    } else if (field === 'productUuid') {
      if (!value?.trim()) {
        error = ErrorMessages.productUuid.required;
      }
    } else if (field === 'title') {
      if (!value?.trim()) {
        error = ErrorMessages.title.required;
      }
    } else if (field === 'customerUuid') {
      if (!value?.trim()) {
        error = ErrorMessages.customerUuid.required;
      }
    } else if (field === 'location') {
      if (!value?.trim()) {
        error = ErrorMessages.location.required;
      }
    } else if (field === 'answer') {
      const trimmedValue = value?.trim();
      // console.log(trimmedValue, 'trimmedValue');
      if (!trimmedValue || trimmedValue === '<p><br></p>' || trimmedValue === '<p></p>') {
        error = ErrorMessages.answer.required;
      }
    } else if (field === 'whatsAppNumber') {
      if (!value?.trim()) {
        error = ErrorMessages.whatsAppNumber.required;
      } else if (!phoneNumberRegex.test(value)) {
        error = ErrorMessages.whatsAppNumber.invalid;
      }
    } else if (field === 'supportEmail') {
      if (!value?.trim()) {
        error = ErrorMessages.supportEmail.required;
      } else if (!emailRegex.test(value)) {
        error = ErrorMessages.supportEmail.invalid;
      }
    }
    if (error) {
      errors[field] = error;
      isValid = false;
    }
  });
  return { isValid, errors };
};

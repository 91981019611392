import React from 'react';
import { FaAngleLeft } from 'react-icons/fa6';

interface BackButtonProps {
  onClick: () => void;
}

export const BackButton2: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <div
      className="bg-[#006BEA] mt-1 lg:mt-1 rounded w-[17px] h-[17px] md:w-[24px] md:h-[24px] lg:w-[27px] lg:h-[27px] ml-0 mr-1 cursor-pointer flex items-center justify-center"
      onClick={onClick}
    >
      <FaAngleLeft className="lg:w-[20px]  lg:h-[20px] w-[15px] h-[15px]" />
    </div>
  );
};

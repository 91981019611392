import React from 'react';
import UserCard from '../chat/UserCard';

const ListOfUser = ({ users, onUserSelect, selectedUser }: any) => {
  return (
    <div className="flex flex-col gap-2.5 xl:h-[calc(100vh-224px)] xl:overflow-y-auto p-2 pr-2.5">
      {users.map((user: any) => (
        <UserCard
          key={user.id}
          user={user}
          onSelect={() => onUserSelect(user.id)}
          selectedUser={selectedUser}
        />
      ))}
    </div>
  );
};

export default ListOfUser;

import React from 'react';
import { LuMessagesSquare } from 'react-icons/lu';
import { MdOutlineSettings } from 'react-icons/md';
import { PiCrownSimpleBold } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import HowCanWeHelp from './HowCanWeHelp';

const cards = [
  {
    icon: <LuMessagesSquare className="w-16 h-16" />,
    title: 'FAQ',
    link: '/support/questions'
  },
  {
    icon: <MdOutlineSettings className="w-16 h-16" />,
    title: 'Integration',
    link: '/support/questions'
  },
  {
    icon: <PiCrownSimpleBold className="w-16 h-16" />,
    title: 'Subscription',
    link: '/support/questions'
  }
];

const HelpOptions = () => {
  return (
    <div className="w-full mt-12">
      <HowCanWeHelp />
      <div className="flex justify-center mt-16 gap-10">
        {cards.map((card, index) => (
          <Link
            to={card.link}
            key={index}
            className="bg-[#343434] justify-evenly flex-col rounded-[10px] w-[187px] h-[160px] flex items-center text-white hover:shadow-lg transition duration-300 card-shadow"
          >
            <div className="">{card.icon}</div>
            <div className="text-[22px] leading-[26.63px] font-medium">{card.title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HelpOptions;

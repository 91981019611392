const CardSkeleton = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-4 p-6 bg-[#333333] rounded-lg shadow-lg whitespace-pre-wrap  animate-pulse">
      {/* Profile Placeholder */}
      <div className="w-20 h-20 bg-gray-500 rounded-full"></div>

      {/* Name and Position Placeholder */}
      <div className="flex flex-col justify-center items-center space-y-2">
        <div className="w-32 h-4 bg-gray-500 rounded"></div>
        <div className="w-24 h-4 bg-gray-500 rounded"></div>
      </div>

      {/* Star Ratings Placeholder */}
      <ul className="flex space-x-2 justify-center">
        {new Array(5).fill(1).map((_, idx) => (
          <div key={idx} className="w-4 h-4 bg-gray-500 rounded-full"></div>
        ))}
      </ul>

      {/* Complaints, Solved, Pending Placeholder */}
      <div className="flex items-center gap-5 lg:gap-5">
        {new Array(3).fill(1).map((_, idx) => (
          <div key={idx} className="flex flex-col items-center space-y-1 w-12 lg:w-16">
            <div className="w-16 h-4 bg-gray-500 rounded"></div>
            <div className="w-8 h-4 bg-gray-500 rounded"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSkeleton;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PopupFile from './PopupFile';
import PopupUrl from './AddUrlPopup';
import FaqEmptyScreen from './FaqEmptyScreen';
import FaqFillScreen from './FaqFillScreen';
import { BackButton2 } from '../../../../../common/BackButton2';
import { FaRegFileAlt } from 'react-icons/fa';
import { RiLink } from 'react-icons/ri';
import { deleteFaqDocument } from '../../../../../api/AccountSetup';
import ToastNotification from '../../../../../common/ToastNotification';
import AccountSetupButton from '../../../../../common/AccountSetupButton';

interface faqAutoProps {
  documentList: {
    uuid: string;
    type: string;
    name: string;
  }[];
  fetchAllDocuments: any;
  fetchGeneratedFAQ: any;
  setShowFaqAuto: any;
  disable: any;
  setFaqList: any;
  isLoading: boolean;
  setIsLoading: any;
  setDocumentList: any;
}

const FaqAuto = (props: faqAutoProps) => {
  const {
    documentList,
    fetchAllDocuments,
    fetchGeneratedFAQ,
    setShowFaqAuto,
    disable,
    setFaqList,
    isLoading,
    setIsLoading,
    setDocumentList
  } = props;

  const navigate = useNavigate();
  const companyUuid = sessionStorage.getItem('companyUuid');

  const [isPopupOpenFile, setIsPopupOpenFile] = useState(false);
  const [isPopupOpenUrl, setIsPopupOpenUrl] = useState(false);
  const [editUrlPopupData, setEditUrlPopupData] = useState();

  const openPopup1 = () => {
    setIsPopupOpenFile(true);
  };

  const openPopup2 = () => {
    setIsPopupOpenUrl(true);
  };

  const closePopup1 = () => {
    setIsPopupOpenFile(false);
  };

  const closePopup2 = () => {
    setEditUrlPopupData(undefined);
    setIsPopupOpenUrl(false);
  };

  const deleteDocuments = async (documentId: string) => {
    setIsLoading(true);
    const res = await deleteFaqDocument(documentId, companyUuid ? companyUuid : '');
    if (res.ok) {
      setDocumentList((documents: any) => documents.filter((doc: any) => doc.uuid != documentId));
      ToastNotification({
        message: 'Deleted Successfully',
        type: 'success'
      });

      fetchAllDocuments();
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const handleEditUrl = (info: any) => {
    setEditUrlPopupData(info);
    setIsPopupOpenUrl(true);
  };
  useEffect(() => {
    fetchAllDocuments();
  }, []);

  return (
    <>
      <div className="w-full">
        <div>
          <p className="h-[31px] lg:ml-9 ml-[4px] md:ml-2 mb-[5px] lg:text-[22px] md:text-[17px] sm:leading-[26.86px] text-[14px] md:leading-[30.86px] font-normal">
            STEP 2
          </p>
        </div>

        <div className="flex md:items-center items-start ml-[4px] md:ml-1">
          <div className="lg:mt-1">
            <BackButton2
              onClick={() => {
                setShowFaqAuto(false);
                setFaqList([]);
              }}
            />
          </div>
          <h3 className="h-[39px] mt-0 md:mt-0 lg:mt-2 text-[18px] sm:leading-[29.18px] md:text-[21px] lg:text-[28px] md:leading-[39.28px] font-medium">
            Frequently Asked Questions
          </h3>
          <div className="flex space-x-4 ml-auto">
            <div
              className="lg:h-10 lg:w-10 lg-8 w-8 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer p-2 lg:p-0"
              onClick={openPopup1}
            >
              <FaRegFileAlt className="lg:h-6 lg:w-6 h-5 w-5" />
            </div>
            <div
              className="lg:h-10 lg:w-10 lg-8 w-8 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer p-2 lg:p-0"
              onClick={openPopup2}
            >
              <RiLink className="lg:h-6 lg:w-6 h-5 w-5" />
            </div>
          </div>
        </div>
        {isLoading ? (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
          </>
        ) : documentList?.length > 0 ? (
          <FaqFillScreen
            documentList={documentList}
            deleteDocuments={(documentId: string) => deleteDocuments(documentId)}
            handleEditUrl={handleEditUrl}
          />
        ) : (
          <FaqEmptyScreen />
        )}

        <div className="fixed  bottom-2 right-6 gap-2 md:gap-4 bg-[#1E1E1E]">
          <div className="flex gap-7  -mt-10 ">
            <AccountSetupButton
              isFormValid={true}
              btnText="Skip"
              textColor="#FFFFFF"
              backGroundColor=""
              borderColor="#006BEA"
              onClick={() => navigate('/account-setup/widgets')}
            />

            {documentList.length > 0 && (
              <AccountSetupButton
                isFormValid={true}
                btnText="Next"
                textColor="#FFFFFF"
                backGroundColor="#006BEA"
                borderColor="#006BEA"
                onClick={fetchGeneratedFAQ}
                disable={disable}
              />
            )}
          </div>
        </div>

        {isPopupOpenFile && (
          <PopupFile
            onClose={closePopup1}
            fetchAllDocuments={fetchAllDocuments}
            setIsLoading={setIsLoading}
          />
        )}
        {isPopupOpenUrl && (
          <PopupUrl
            onClose={closePopup2}
            fetchAllDocuments={fetchAllDocuments}
            editUrlPopupData={editUrlPopupData}
            closePopup2={closePopup2}
          />
        )}

        {disable && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FaqAuto;

import React from 'react';
import { useEffect, useState } from 'react';
import StatusCardList from './complaint-status-card/StatusCardList';
import ComplaintsGraph from './complaints-graph/ComplaintsGraph';
import ComplaintsRaising from './complaints-raising/ComplaintsRaising';
import EngagementAnalysis from './engagement-analysis/EngagementAnalysis';
import VendorEfficiency from './vendor-efficiency/VendorEfficiency';
import { fetchDashboardInfo } from '../../api/DashBoard';
import Helper from '../../function/Helper';
import { getUser } from '../../api/SettingUser';
import { useSocket } from '../../Contexts/SocketContext';
const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState('yearly');
  const [selectedButton, setSelectedButton] = useState('');
  const [raisedComplaints, setRaisedComplaints] = useState();
  const [allUser, setAllUser] = useState<any>();
  const { socketInstance } = useSocket();

  const getDateRange = (date: string) => {
    switch (date) {
      case 'monthly':
        return { type: 'monthly', from: Helper.startOfMonth, to: Helper.endOfMonth };
      case 'weekly':
        return { type: 'weekly', from: Helper.startOfWeek, to: Helper.endOfWeek };
      case 'yearly1':
        return { type: 'yearly', from: Helper.previousStartYear, to: Helper.previousEndYear };
      case 'yearly2':
        return { type: 'yearly', from: Helper.previousTwoStartYear, to: Helper.previousTwoEndYear };
      default:
        return { type: 'yearly', from: Helper.startOfYear, to: Helper.endOfYear };
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const companyUuid = sessionStorage.getItem('companyUuid') || '';
      const { type, from, to } = getDateRange(date);
      const data = await fetchDashboardInfo(companyUuid, type, from, to);
      const allUsers = await getUser(companyUuid ? companyUuid : '', '');
      const dashboardData = data?.payload as any;
      setAllUser(allUsers);
      setDashboardData(dashboardData);
      setRaisedComplaints(dashboardData.complaintRaising?.raisedComplaints);
    } catch (err: any) {
      console.error('Error fetching data', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [date]);

  useEffect(() => {
    if (socketInstance) {
      setTimeout(() => {
        socketInstance.emit('complaint-raised-today');
      }, 400);
      socketInstance.on('complaintRaised', (complaintRaised: any) => {
        setRaisedComplaints(complaintRaised);
      });

      socketInstance.on('new-complaint-raised', () => {
        socketInstance.emit('complaint-raised-today');
      });

      return () => {
        socketInstance.off('complaintRaised');
        socketInstance.off('new-complaint-raised');
        socketInstance.off('complaint-raised-today');
      };
    }
  }, [socketInstance]);

  return (
    <>
      <div className="bg-[#1E1E1E] h-[calc(100vh-110px)] py-5 flex flex-col gap-y-5 w-full overflow-y-auto lg:px-5 px-2">
        <StatusCardList dashboardData={dashboardData} />

        <div className="flex flex-col xl:flex-row gap-5">
          <div className="w-full xl:w-[65%]">
            <ComplaintsGraph
              complaintsInfo={dashboardData?.complaints}
              date={date}
              setDate={setDate}
              selectedButton={selectedButton}
              setSelectedButton={setSelectedButton}
            />
          </div>
          <div className="w-full xl:w-[35%]">
            <ComplaintsRaising
              complaintRaise={raisedComplaints}
              usersWithUnassignedComplaints={
                dashboardData?.complaintRaising?.usersWithUnassignedComplaints
              }
            />
          </div>
        </div>

        <div className="flex flex-col xl:flex-row gap-5">
          <div className="w-full xl:w-[65%]">
            <VendorEfficiency vendor={dashboardData?.vendor} />
          </div>
          <div className="w-full xl:w-[35%]">
            <EngagementAnalysis
              engagement={dashboardData?.userLogInDetails}
              allUser={allUser?.payload?.result}
            />
          </div>
        </div>
        {loading && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;

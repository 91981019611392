import { RiAddLargeFill } from 'react-icons/ri';
import ListOfVendor from './vendor-list/ListOfVendor';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

const Vendors = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#1E1E1E] h-screen py-5 w-full overflow-y-auto px-6 flex flex-col gap-2.5 overflow-hidden">
      <div className={`flex items-center justify-between`}>
        <label className={`text-[28px] text-white font-medium `}>Vendors</label>
        <div
          className="h-10 w-10 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
          onClick={() => navigate('add-vendor')}
        >
          <FaPlus className="h-5 w-5" />
        </div>
      </div>

      <ListOfVendor />
    </div>
  );
};

export default Vendors;

import React from 'react';

const Payment = () => {
  return (
    <>
      <div>Payment</div>
    </>
  );
};
export default Payment;

import React, { useEffect, useRef, useState } from 'react';
import InputBox from '../../common/InputBox';
import { validateForm } from '../../function/FormValidation';
import { phoneNumberRangeRegex } from '../../function/Regex';
import Button from '../../common/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createUser, updateUser } from '../../api/SettingUser';
import ToastNotification from '../../common/ToastNotification';
import { FaArrowLeft } from 'react-icons/fa6';
import { countrycodes } from '../../country-code/CountryCodes';
import CountryCode from '../../common/CountryCode';

interface Country {
  code: string;
  flag: string;
  name: string;
  teleCode: string;
}

function CreateUserSetting() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const userData = location.state?.userData;
  const companyUuid = sessionStorage.getItem('companyUuid');
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        if (userData) {
          setFormData({
            name: userData?.fullName || '',
            email: userData?.email || '',
            companyName: userData?.account?.companyName || '',
            contactNumber: userData?.phone || '',
            employeeId: userData?.employeeId || '',
            dateOfBirth: userData?.dateOfBirth || '',
            position: userData?.position || '',
            address: userData?.address || '',
            Countrycode: userData?.code || '',
            Countryname: userData?.country || ''
          });
          const selectedCountry = countrycodes.find(
            (country) => country.teleCode === userData?.code
          );
          if (selectedCountry) {
            setSelectedCountry(selectedCountry);
          }
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
        setErrors((prev) => ({ ...prev, fetch: 'Failed to fetch company details.' }));
      }
    };

    fetchCompanyDetails();
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    contactNumber: '',
    employeeId: '',
    dateOfBirth: '',
    position: '',
    address: '',
    Countrycode: '',
    Countryname: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disable, setDisable] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };
  const handleUpdateUserProfile = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
      | undefined
  ) => {
    e?.preventDefault();
    const { isValid, errors } = validateForm(formData, [
      'name',
      'email',
      'companyName',
      'contactNumber',
      'employeeId',
      'dateOfBirth',
      'position',
      'address'
    ]);
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (isValid) {
      if (location.pathname.startsWith('/settings/users/update-user')) {
        try {
          const response = await updateUser(
            {
              uuid: id || '',
              name: formData.name || '',
              email: formData.email || '',
              companyName: formData.companyName || '',
              contactNumber: formData.contactNumber || '',
              employeeId: formData.employeeId || '',
              dateOfBirth: formData.dateOfBirth || '',
              position: formData.position || '',
              address: formData.address || '',
              code: formData.Countrycode || '',
              country: formData.Countryname || ''
            },
            companyUuid ? companyUuid : ''
          );
          if (response.ok) {
            const data = await response.json();

            ToastNotification({
              message: data.message
            });
            setTimeout(() => {
              navigate('/settings/users');
              setDisable(false);
            }, 1500);
          } else {
            const errorData = await response.json();
            ToastNotification({
              message: errorData.message,
              type: 'warning'
            });
            setDisable(false);
          }
        } catch (error) {
          setDisable(true);
        }
      } else {
        try {
          const response = await createUser(
            {
              name: formData.name || '',
              email: formData.email || '',
              companyName: formData.companyName || '',
              contactNumber: formData.contactNumber || '',
              employeeId: formData.employeeId || '',
              dateOfBirth: formData.dateOfBirth || '',
              position: formData.position || '',
              address: formData.address || '',
              code: formData.Countrycode || '+91',
              country: formData.Countryname || 'India'
            },
            companyUuid ? companyUuid : ''
          );
          if (response.ok) {
            const data = await response.json();
            ToastNotification({
              message: data.message
            });
            setTimeout(() => {
              navigate('/settings/users');
              setFormData({
                name: '',
                email: '',
                companyName: '',
                contactNumber: '',
                employeeId: '',
                dateOfBirth: '',
                position: '',
                address: '',
                Countrycode: '+91',
                Countryname: 'India'
              });
              setDisable(false);
            }, 1500);
          } else {
            const errorData = await response.json();
            ToastNotification({
              message: errorData.message,
              type: 'warning'
            });
            setDisable(false);
          }
        } catch (error) {
        }
      }
    }
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      Countrycode: value
    }));
  };
  useEffect(() => {
    handlePhoneChange(formData.Countrycode);
  }, [formData.contactNumber]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setFormData({
        ...formData,
        Countryname: selectedCountry.name,
        Countrycode: selectedCountry.teleCode
      });
    }
  }, [selectedCountry]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (country: Country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredCountries = countrycodes?.filter(
    (country: Country) =>
      country.teleCode.includes(searchQuery) ||
      country.code.toLowerCase().includes(searchQuery) ||
      country.name.toLowerCase().includes(searchQuery)
  );

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target && target.type === 'date') {
      target.showPicker();
    }
  };

  const handleGoBack = () => {
    navigate('/settings/users');
  };

  return (
    <div className="flex flex-col flex-1 bg-[#1E1E1E]">
      <div className="flex px-5">
        <div className="">
          <FaArrowLeft
            onClick={handleGoBack}
            className="text-[#006BEA] w-6 h-6 mt-3 cursor-pointer"
          />
        </div>
        <h1 className="h-[26px] px-2  mt-4 md:mt-3 mb-[27px] pl-4 sm:text-[18px] sm:leading-[29.18px] md:text-[24px] md:leading-[25.78px] font-medium">
          Users Profile
        </h1>
      </div>
      <form
        className="flex flex-col justify-between "
        onSubmit={handleUpdateUserProfile}
        method="Post"
      >
        <div className="space-y-0.5 pr-4 px-5">
          {' '}
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Name
            </label>
            <div className="md:flex-grow">
              <InputBox
                name="name"
                type="text"
                value={formData.name}
                placeholder="Enter Your Name"
                handleChange={handleChange}
                error={errors.name}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Email
            </label>
            <div className="md:flex-grow">
              <InputBox
                name="email"
                type="text"
                value={formData.email}
                placeholder="Enter Your Email"
                handleChange={handleChange}
                error={errors.email}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Company Name
            </label>
            <div className="md:flex-grow">
              <InputBox
                name="companyName"
                type="text"
                value={formData.companyName}
                placeholder="Enter Your Company Name"
                handleChange={handleChange}
                error={errors.companyName}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Contact Number
            </label>
            <div className="flex md:flex-grow gap-2">
              <CountryCode
                formData={formData}
                isOpen={isOpen}
                searchQuery={searchQuery}
                selectedCountry={selectedCountry}
                dropdownRef={dropdownRef}
                filteredCountries={filteredCountries}
                toggleDropdown={toggleDropdown}
                handleSearch={handleSearch}
                handleSelect={handleSelect}
                countrycodes={countrycodes}
              />
              <div className="md:flex-grow">
                <InputBox
                  name="contactNumber"
                  type="text"
                  value={formData.contactNumber}
                  placeholder="Enter your Phone Number"
                  error={errors.contactNumber}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (phoneNumberRangeRegex.test(value)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Employee ID
            </label>
            <div className="md:flex-grow">
              <InputBox
                name="employeeId"
                type="text"
                value={formData.employeeId}
                placeholder="Enter Your Employee Id"
                handleChange={handleChange}
                error={errors.employeeId}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              D.O.B
            </label>
            <div className="md:flex-grow">
              <InputBox
                name="dateOfBirth"
                type="date"
                value={formData.dateOfBirth}
                placeholder="D.O.B"
                handleChange={handleChange}
                error={errors.dateOfBirth}
                onclick={handleClick}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Position
            </label>
            <div className="md:flex-grow">
              <InputBox
                name="position"
                type="text"
                value={formData.position}
                placeholder="Enter Your Position"
                handleChange={handleChange}
                error={errors.position}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Address
            </label>
            <div className="md:flex-grow">
              <InputBox
                name="address"
                type="text"
                value={formData.address}
                placeholder="Enter Your Address"
                handleChange={handleChange}
                error={errors.address}
              />
            </div>
          </div>
        </div>

      </form>
      <div className="flex justify-end items-end px-5">
        <Button
          isFormValid={true}
          btnText={`${location.pathname.startsWith('/settings/users/update-user') ? 'Update' : 'Create'}`}
          disable={disable}
          textColor="#FFFFFF"
          backGroundColor="#006BEA"
          onClick={(event) => handleUpdateUserProfile(event)}
        />
      </div>
      <div className="mb-4"></div>
      {disable && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
    </div>
  );
}

export default CreateUserSetting;

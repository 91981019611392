import React from 'react';
import { TiDocumentText } from 'react-icons/ti';
import { Link } from 'react-router-dom';
export const SupportDetailedQuestion = () => {
  const questionsAndAnswers = [
    {
      question: 'How can we help you?',
      answer:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
    },
    {
      question: 'How can we help you?',
      answer:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
    },
    {
      question: 'How can we help you?',
      answer:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
    },
    {
      question: 'How can we help you?',
      answer:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
    }
  ];

  const relatedQuestions = [
    { text: 'Fix the problem?', link: '/support/detailed-question' },
    { text: 'How do i subscribe?', link: '/support/detailed-question' },
    { text: 'Can you reduce the plan price?', link: '/support/detailed-question' }
  ];

  return (
    <div className="flex w-full flex-col md:flex-row gap-4 p-4 text-white">
      <div className="w-full md:w-[85%] border border-[#BFBFBF] rounded-lg p-4">
        <ul className="space-y-4">
          {questionsAndAnswers.map((item, index) => (
            <li key={index} className=" pb-2">
              <p className="font-semibold text-[40px]">{item.question}</p>
              <label className=" text-light text-[22px]">{item.answer}</label>
            </li>
          ))}
        </ul>
      </div>
      <div className="w-full md:w-[25%]  p-4 pt-12">
        <ul className="space-y-2">
          {relatedQuestions.map((question, index) => (
            <li key={index} className="text-[22px] cursor-pointer flex items-center gap-2">
              <TiDocumentText className="w-[23px] h-[29px] text-[#006BEA]" />
              <Link to={question.link} className="text-[22px]">
                {question.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

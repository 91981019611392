import React, { useState, useRef } from 'react';
import { FaBars } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../Button';
import Lopa from '../../assets/whitelogo.png';

interface vendorNavbarProps {
  children: React.ReactNode;
  header?: JSX.Element;
  bellAndSettingsIcon?: JSX.Element;
  isAccountSetup?: boolean;
  layoutPadding?: boolean;
}

const VendorNavbar = (props: vendorNavbarProps) => {
  const { children, isAccountSetup, layoutPadding, bellAndSettingsIcon } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target as Node)) {
      setIsSidebarOpen(false);
    }
  };

  const navigate = useNavigate();
  const handleLogoutClick = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const fullname = sessionStorage.getItem('fullName');

  return (
    <div className="flex flex-col flex-1 relative">
      <header className="flex justify-between items-center md:ps-2 md:pe-10 ps-2 pe-2 px-2 pt-4 sticky z-50 top-0 bg-[#1E1E1E] md:py-5">
        <div className="flex items-center space-x-6 md:pl-8">
          <FaBars
            className="text-[#006BEA] text-[30px] cursor-pointer"
            onClick={handleSidebarToggle}
          />

          <span className="text-white font-medium text-[22px]">Vendor Profile</span>
        </div>

        <div className="flex items-center sm:space-x-6 space-x-6">
          {bellAndSettingsIcon}
          <div className="md:text-[22px] sm:text-[15px] text-[15px]">
            <span className="font-normal text-white block">Welcome!</span>
            <span className="font-bold text-white block">{fullname || 'Guest'}</span>
          </div>
        </div>
      </header>

      {isSidebarOpen && (
        <>
          <div className="fixed inset-0 bg-opacity-50 z-40" onClick={handleOverlayClick} />
          <div
            ref={sidebarRef}
            className={`fixed top-0 left-0 h-full bg-[#2D2D2D] z-50 text-white pl-4 transition-transform duration-300 ${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
            style={{ width: '300px' }}
          >
            <div className="flex justify-between items-center px-5 py-4 mt-10">
              <img src={Lopa} className="w-32 h-16" alt="Matrix AI" />
              <button onClick={handleSidebarToggle}>
                <RxCross2 className="text-[30px] cursor-pointer text-[#006BEA]" />
              </button>
            </div>

            <div className="mt-4 min-h-[600px] flex flex-col justify-between px-7">
              <div className="flex flex-col justify-between flex-1">
                <div>
                  <ul className="space-y-4">
                    <li>
                      <Link
                        to={`/vendor-login/vendor-complaints/${sessionStorage.getItem('userUUID')}`}
                        className="text-[22px]"
                        onClick={handleSidebarToggle}
                      >
                        Complaints
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/vendor-login/vendor-profile/${sessionStorage.getItem('userUUID')}`}
                        className="text-[22px]"
                        onClick={handleSidebarToggle}
                      >
                        Profile
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <Button
                    isFormValid={true}
                    btnText="Logout"
                    textColor="#006BEA"
                    backGroundColor="white"
                    onClick={handleLogoutClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <main
        className={`flex flex-1 md:pb-2 md:px-5 ${
          isAccountSetup ? 'overflow-y-auto' : ''
        } ${!layoutPadding && 'md:px-5'} ${isSidebarOpen ? 'filter blur-sm' : ''}`}
      >
        {children}
      </main>
    </div>
  );
};

export default VendorNavbar;

import React, { useState, useEffect, Fragment } from 'react';
import ReactPaginate from 'react-paginate';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import SingleVendor from './SingleVendor';
import { deleteVendor, vendorPagination } from '../../../api/Vendor';
import ToastNotification from '../../../common/ToastNotification';
import { ToastContainer } from 'react-toastify';
import Loader from '../../../common/Loader';
import CardSkeleton from './CardSkeleton';

const ListOfVendor = () => {
  const itemsPerPage = 8;
  const [vendorList, setVendorList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    fetchVendors(currentPage);
  }, [currentPage]);
  const companyUuid = sessionStorage.getItem('companyUuid');
  const fetchVendors = async (page: number) => {
    try {
      setShowLoading(true);
      const response = await vendorPagination(companyUuid ? companyUuid : '', page, itemsPerPage);

      if (!response.ok) {
        throw new Error('Failed to fetch vendor data');
      }

      const data = await response.json();
      const vendorData = data?.payload?.vendor[0].default;
      // console.log('vendor', vendorData);
      setVendorList(data.payload.vendor || []);
      setTotalItems(data.payload.count || 0);
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      console.error('Error fetching vendor data:', error);
    }
  };

  //Delete Vendor
  const deleteVendordata = async (clientVendorUuid: string) => {
    try {
      const response = await deleteVendor(companyUuid ? companyUuid : '', clientVendorUuid);
      if (response.ok) {
        const data = await response.json();

        fetchVendors(currentPage);
        ToastNotification({
          message: data.message,
          type: 'success'
        });
      } else {
        const errorData = await response.json();
        ToastNotification({
          message: errorData.message,
          type: 'warning'
        });
      }
    } catch (error) {
      console.error('Error deleting vendor:', error);
    }
  };

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  return (
    <div>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 h-[calc(100vh-224px)] overflow-y-auto pr-2 relative">
        {showLoading ? (
          <>
            {/* <div className="flex justify-center overflow-y-hidden items-center absolute inset-0 z-10"> */}
            {/* <Loader /> */}
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
            {/* </div> */}
            {/* Skeleton Cards */}
            {new Array(8).fill(1).map((_, idx) => (
              <CardSkeleton key={idx} />
            ))}
          </>
        ) : (
          <Fragment>
            {vendorList.length > 0 ? (
              vendorList.map((info, index) => (
                <SingleVendor
                  deleteVendordata={deleteVendordata}
                  info={info}
                  index={index}
                  key={index}
                />
              ))
            ) : (
              <div className="flex items-center justify-center absolute inset-0 text-gray-300 text-[16px] font-normal">
                No vendors available
              </div>
            )}
          </Fragment>
        )}
      </div>

      {pageCount > 1 && (
        <div className="flex justify-center items-center mt-2 mb-2">
          <ReactPaginate
            previousLabel={
              <div className="flex items-center justify-center w-8 h-8 border rounded border-white text-white">
                <FaChevronLeft />
              </div>
            }
            nextLabel={
              <div className="flex items-center justify-center w-8 h-8 border rounded border-white text-white">
                <FaChevronRight />
              </div>
            }
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination flex space-x-6"
            pageLinkClassName="cursor-pointer"
            activeLinkClassName="text-blue-500 font-bold"
            disabledClassName="opacity-50 cursor-not-allowed"
          />
        </div>
      )}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default ListOfVendor;

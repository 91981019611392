import React from 'react';
import { IoSearch } from 'react-icons/io5';

const HowCanWeHelp = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col justify-center items-center h-full w-full">
        <label className="font-normal md:text-[40px] text-[20px] text-white">
          How can we help you?
        </label>
        <div className="mt-4 relative">
          <IoSearch className="absolute top-1/2 left-4 transform -translate-y-1/2 w-7 h-7 text-[#ffffff]" />
          <input
            type="text"
            className="bg-[#343434] py-4 pl-14 pr-3 md:w-[575px] h-[48px] text-[24px] rounded-lg text-white placeholder:text-[#F3F3E099] flex justify-content items-center"
            placeholder="Describe your Issue"
          />
        </div>
      </div>
    </div>
  );
};

export default HowCanWeHelp;

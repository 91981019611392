import React, { useEffect, useRef, useState } from 'react';
import Gmail from '../assets/gmail.png';
import Lopa from '../assets/whitelogo.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { verifyUser } from '../api/Auth';
import ToastNotification from './ToastNotification';

const Otp = () => {
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const location = useLocation();
  const email = location.state?.email;
  const { userId } = useParams<{ userId: string }>();

  const [otp, setOtp] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const verifyEmail = async (userUuid: string, updatedOtp: string) => {
    setLoading(true);
    try {
      const response = await verifyUser(userUuid, updatedOtp);
      if (response.message === 'User verified successfully.') {
        ToastNotification({ message: response.message, type: 'success' });
        setTimeout(() => {
          setLoading(false);
          navigate('/');
          setOtp('');
        }, 1000);
      } else {
        ToastNotification({
          message: response.message,
          type: 'warning'
        });
        setLoading(false);
        setOtp('');
        inputRefs.current[0]?.focus();
      }
    } catch (error: any) {
      setLoading(false);
      setOtp('');
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value.replace(/\D/, '');
    const newOtp = otp.split('');
    newOtp[index] = value;
    const updatedOtp = newOtp.join('');
    setOtp(updatedOtp);

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    if (updatedOtp.length === 6 && updatedOtp.trim() !== '') {
      setTimeout(() => {
        verifyEmail(userId!, updatedOtp);
      }, 500);
    }
  };

  const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const value = (e.target as HTMLInputElement).value;
    if (e.key === 'Backspace' && !value && index > 0) {
      const newOtp = otp.split('');
      newOtp[index - 1] = '';
      setOtp(newOtp.join(''));
      inputRefs.current[index - 1]?.focus();
    }
  };
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData('text');
    if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
      const otpArray = pastedData.split('');
      otpArray.forEach((char, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index]!.value = char;
        }
      });
      setOtp(pastedData);
      inputRefs.current[5]?.focus();
      setTimeout(() => {
        verifyEmail(userId!, pastedData);
      }, 500);
    }
  };
  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center mt-16 px-4">
      <div className="mb-6">
        <img src={Lopa} className="lg:w-44 lg:h-20 w-28 h-16 mr-2 ml-8 mt-3" alt="Matrix AI" />
      </div>

      <h1 className="text-3xl font-bold mb-4">Check your email for a code</h1>

      <p className=" mb-6 text-center md:w-[40%]">
        We’ve sent a 6-character code to <span className="font-bold">{email}</span>. The code
        expires shortly, so please enter it soon.
      </p>

      <div className="flex items-center gap-4 mb-24 relative">
        <div className="flex gap-2">
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleInput(e, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
                value={otp[index] || ''}
                readOnly={index > 0 && !otp[index - 1]}
                onPaste={handlePaste}
                className="w-10 h-10 md:w-20 md:h-20 border-2 bg-[#343434] text-white focus:outline-none focus:border-blue-500 text-center text-2xl rounded-md"
              />
            ))}
        </div>

        <span className="text-3xl text-gray-500">-</span>

        <div className="flex gap-2">
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <input
                key={index + 3}
                type="text"
                maxLength={1}
                ref={(el) => (inputRefs.current[index + 3] = el)}
                onChange={(e) => handleInput(e, index + 3)}
                onKeyDown={(e) => handleBackspace(e, index + 3)}
                value={otp[index + 3] || ''}
                readOnly={index > 0 && !otp[index - 1]}
                onPaste={handlePaste}
                className="w-10 h-10 md:w-20 md:h-20 border-2 bg-[#343434] text-white focus:outline-none focus:border-blue-500 text-center text-2xl rounded-md"
              />
            ))}
        </div>
      </div>

      {loading && (
        <div className="text-blue-500 text-lg absolute mt-2 text-center">Verifying...</div>
      )}
      <div className="flex gap-4 mb-8">
        <button
          onClick={() => {}}
          className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-md "
        >
          <img src={Gmail} alt="Gmail" className="w-5 h-5" />
          <span>Open Gmail</span>
        </button>
      </div>

      <p className=" text-sm mb-10">Can’t find your code? Check your spam folder!</p>

      <div className="flex gap-4 mt-32 text-xs">
        <a href="#" className="hover:underline">
          Privacy & Terms
        </a>
        <a href="#" className="hover:underline">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Otp;

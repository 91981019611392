import React from 'react';
import { useState, useEffect } from 'react';

interface IProps {
  tickets: any;
  info: any;
  index: number;
  selectedTicketIdx: any;
  setSelectedTicketIdx: any;
  markAsRead: any;
  isRead: any;
}

const NotificationCard = (props: IProps) => {
  const { info, index, selectedTicketIdx, setSelectedTicketIdx, markAsRead, isRead, tickets } =
    props;

  // const selectInitialTicket = () => {
  //   if (tickets.length > 0 && selectedTicketIdx === 0 && !tickets[0].read) {
  //     markAsRead(tickets[0].uuid);
  //   }
  // };

  // useEffect(() => {
  //   selectInitialTicket();
  // }, [tickets[0], selectedTicketIdx]);

  const [skipUseEffect, setSkipUseEffect] = useState(false);

  useEffect(() => {
    if (
      tickets.length > 0 &&
      selectedTicketIdx === 0 &&
      tickets[0]?.uuid &&
      !tickets[0]?.read &&
      !tickets[0]?.isBeingRead &&
      !skipUseEffect
    ) {
      tickets[0].isBeingRead = true;
      markAsRead(tickets[0].uuid);
    }

    if (skipUseEffect) {
      setSkipUseEffect(false);
    }
  }, [tickets.length, tickets[0]?.uuid, selectedTicketIdx, skipUseEffect]);

  const handleClick = () => {
    setSelectedTicketIdx(index);

    if (index === 0) {
      setSkipUseEffect(true);
    }

    if (!info.read) {
      if (index === 0) {
        tickets[0].isBeingRead = true;
      }
      markAsRead(info.uuid);
    }
  };

  return (
    <>
      <div>
        <div
          className={`rounded-lg pt-3.5 pl-3.5 pr-3 pb-3.5 mb-2 cursor-pointer border ${info?.read ? 'bg-none' : 'bg-[#006BEA30]'}  ${
            selectedTicketIdx === index ? 'border-[#006BEA]' : 'border-[#696969]'
          }`}
          key={index}
          // onClick={(e) => {
          //   setSelectedTicketIdx(index);
          //   if (!info.read) {
          //     markAsRead(info.uuid);
          //   }
          // }}
          onClick={handleClick}
        >
          <div className="flex justify-between items-center mb-2">
            <div className="flex gap-x-5 items-center">
              <label className="md:text-[20px] text-white font-regular  line-clamp-2 mt-1 cursor-pointer font-medium">
                {info?.message}
              </label>
            </div>
          </div>

          <div className="flex gap-x-5">
            <label className="md:text-[18px] text-white font-regular cursor-pointer max-w-60 overflow-hidden text-ellipsis whitespace-nowrap">
              {info?.title}
            </label>
          </div>
          <div className="flex justify-end mt-1">
            <label className="text-xs font-bold text-[#D7D7D7] text-right cursor-pointer">
              {info.createdTime}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationCard;

import React, { useState, useEffect, Fragment } from 'react';
import { FaChevronLeft, FaChevronRight, FaPhoneAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { IoMdMail } from 'react-icons/io';
import { LuTrash } from 'react-icons/lu';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { getUser, deleteUser } from '../../api/SettingUser';
import ToastNotification from '../../common/ToastNotification';
interface SettingUserProps {
  searchQuery?: string;
}
const SettingList = (props: SettingUserProps) => {
  const { searchQuery } = props;
  const itemsPerPage = 4;
  const companyUuid = sessionStorage.getItem('companyUuid');

  const [data, setData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchData = async () => {
    try {
      setShowLoading(true);
      const result = await getUser(companyUuid ? companyUuid : '', searchQuery ? searchQuery : '');

      if (result?.payload?.result) {
        setData(result.payload.result);
      } else {
        setData([]);
      }
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      console.error('Failed to fetch users:', error);
    }
  };
  useEffect(() => {
    if (searchQuery !== '') {
      setCurrentPage(0);
    }
    fetchData();
  }, [searchQuery]);

  const deleteUserHandler = async (uuid: string) => {
    try {
      const response = await deleteUser(companyUuid || '', uuid);

      if (response.ok) {
        const responseData = await response.json();
        ToastNotification({
          message: responseData.message,
          type: 'success'
        });

        setData((prevData) => {
          const updatedData = prevData.filter((item: any) => item.uuid !== uuid);
          const newTotalPages = Math.ceil(updatedData.length / itemsPerPage);
          if (currentPage >= newTotalPages && newTotalPages > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
          }
          return updatedData;
        });
      } else {
        const errorData = await response.json();
        ToastNotification({
          message: errorData.message,
          type: 'warning'
        });
      }
    } catch (error: any) {
      console.error('Error deleting users:', error);
      ToastNotification({
        message: error.response?.data?.message || error.message || 'An unexpected error occurred.',
        type: 'success'
      });
    }
  };

  const offset = currentPage * itemsPerPage;
  const currentData = data.slice(offset, offset + itemsPerPage);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (selectedItem: any) => {
    setCurrentPage(selectedItem.selected);
  };

  const navigate = useNavigate();

  const handleEdit = (uuid: string, userData?: any) => {
    navigate(`update-user/${uuid}`, { state: { userData } });
  };

  return (
    <>
      <div className="p-4 table-page mb-30">
        {showLoading ? (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
          </>
        ) : currentData.length > 0 ? (
          <Fragment>
            <div className="overflow-y-auto pr-2">
              <table className="overflow-x-auto table-users  text-white border-separate border-spacing-y-3">
                <thead className="">
                  <tr>
                    <th className="pl-3 md:py-2 text-left md:text-[16px] sm:text-[8px]">ID</th>
                    <th className="md:py-2 text-left md:text-[16px] sm:text-[8px]">Name</th>
                    <th className="md:py-2 text-left md:text-[16px] sm:text-[8px]">Info</th>
                    <th className="md:py-2 text-left md:text-[16px] sm:text-[8px] pr-1">Status</th>
                    <th className="md:py-2 text-left md:text-[16px] sm:text-[8px] pr-1">
                      Complaints
                    </th>
                    <th className="md:py-2 text-left md:text-[16px] sm:text-[8px]">Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item: any, index: number) => (
                    <tr key={index} className="bg-[#343434]">
                      <td className="pl-3 py-2 md:text-[16px] sm:text-[8px] rounded-tl-md rounded-bl-md">
                        {item.employeeId}
                      </td>
                      <td className="pl-1 py-2 md:text-[16px] sm:text-[8px]">
                        <div className="w-30 text-ellipsis overflow-hidden whitespace-nowrap">
                          {item.fullName}
                        </div>
                      </td>
                      <td className="pl-1 py-2 md:text-[16px] sm:text-[8px]">
                        <div className="flex flex-col gap-2">
                          <div className="flex items-center gap-2">
                            <FaPhoneAlt className="w-5 h-5" />
                            <h6 className="md:text-[16px] ">{item.phone}</h6>
                          </div>
                          <div className="flex items-center gap-2">
                            <IoMdMail className="w-5 h-5 mt-0.5" />
                            <h6 className="md:text-[16px]  leading-5 w-32 text-ellipsis overflow-hidden whitespace-nowrap">
                              {item.email}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td
                        className={`py-2 text-[16px] ${item.accesslog?.length === 0 ? 'text-red-500' : 'text-green-500'}`}
                      >
                        {item.accesslog?.length === 0 ? 'In Active' : 'Active'}
                      </td>
                      <td className="pl-1 py-2 md:text-[16px] sm:text-[8px]">
                        {item.complaint.length || 0}
                      </td>
                      <td className="pl-1 py-2 md:text-[16px] sm:text-[8px] rounded-tr-md rounded-br-md">
                        {' '}
                        <div className="flex">
                          <FiEdit
                            className="text-[#006BEA] w-5 h-5 cursor-pointer"
                            onClick={() => handleEdit(item.uuid, item)}
                          />
                          <LuTrash
                            className="text-[#FF4C4C] w-5 h-5 ml-3 cursor-pointer border-[#FFFFFF]"
                            onClick={() => deleteUserHandler(item.uuid)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {currentData.length >= 1 && (
                <div className="">
                  <div className="w-full flex justify-center items-center mt-4 mb-3">
                    <ReactPaginate
                      previousLabel={
                        <FaChevronLeft className="flex items-center justify-center w-6 h-6 p-1 border rounded border-white text-white" />
                      }
                      nextLabel={
                        <FaChevronRight className="flex items-center justify-center w-6 h-6 p-1 border rounded border-white text-white" />
                      }
                      forcePage={currentPage}
                      pageCount={totalPages}
                      onPageChange={handlePageClick}
                      containerClassName="flex items-center justify-center gap-3"
                      activeClassName="text-[#006BEA] font-bold"
                      disabledClassName="opacity-50 cursor-not-allowed"
                    />
                  </div>
                </div>
              )}
            </div>
            <br />
          </Fragment>
        ) : (
          <div className="flex items-center justify-center h-[50vh] text-[16px] text-gray-300">
            No users found
          </div>
        )}
      </div>
    </>
  );
};

export default SettingList;

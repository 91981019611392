import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackButton2 } from '../../../../common/BackButton2';
import WidgetsSubmit from './WidgetsSubmit';
import CustomColorPicker from './CustomColorPicker';
import AccountSetupInputBox from '../../../../common/AccountSetupInputBox';
import AccountSetupButton from '../../../../common/AccountSetupButton';

interface IProps {
  state: {
    website: string;
    websiteError: string;
    chatbotName: string;
    listOfThemes: string[];
    selectedTheme: string;
    storedTheme: string;
  };
  isSubmit: boolean;
  isDisabled: boolean;
  setIsSubmit: (value: boolean) => void;
  handleState: (field: string, value: string) => void;
  handleCreateOrUpdateWidgets: any;
  isSettings: boolean | undefined;
}

const CreateWidgets = (props: IProps) => {
  const {
    state,
    isSubmit,
    isDisabled,
    handleState,
    setIsSubmit,
    handleCreateOrUpdateWidgets,
    isSettings
  } = props;

  const navigate = useNavigate();
  useEffect(() => {
    if (isSettings) {
      setIsSubmit(true);
    }
  }, [isSettings]);
  const handleSubmitWebsite = (e: any) => {
    e.preventDefault();
    if (state.website && !state.websiteError) {
      setIsSubmit(true);
    } else {
      handleState('websiteError', 'Please enter URL');
    }
  };

  const handleGoBack = () => {
    if (isSubmit) {
      setIsSubmit(false);
    } else {
      navigate('/account-setup/faq');
    }
  };

  return (
    <div className="w-full flex justify-between flex-col flex-1 ms-1 overflow-y-auto mt-3 md:mt-0">
      {!isSettings && (
        <>
          <h2 className=" lg:ml-9 ml-[4px] md:ml-2 md:mb-[5px] text-[14px]  sm:text-[18px] sm:leading-[26.86px] lg:text-[22px] md:text-[17px]  md:leading-[30.86px] font-normal">
            STEP 3
          </h2>
        </>
      )}
      {!isSettings && (
        <div className="flex ml-[4px] md:ml-1">
          <div className="lg:mt-1 md:mt-2 mt-1">
            <BackButton2 onClick={handleGoBack} />
          </div>
          <h1 className="h-[39px] lg:ml-0 lg:mt-0 md:mt-1 mt-1 lg:mb-[40px] mb-[14px] text-[16px] sm:leading-[29.18px] md:text-[21px] lg:text-[28px] md:leading-[39.28px] font-medium">
            Create Widgets
          </h1>
        </div>
      )}
      <div className={`${isSettings ? 'lg:ml-4' : 'lg:ml-9'} ml-[4px] md:ml-1 mb-0`}>
        <form>
          <div className=" md:w-1/2 mr-3">
            <label
              htmlFor="targetWebsite"
              className=" text-[#FFFFFF] block sm:text-[18px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-medium  md:mb-[15px] mb-1"
            >
              Target Website
            </label>
            <AccountSetupInputBox
              name="targetWebsite"
              type="text"
              value={state.website}
              placeholder="Enter Website"
              handleChange={(e) => handleState('website', e.target.value)}
              error={state.websiteError}
              disabled={isSettings ? false : isSubmit}
            />
          </div>
          <div className=" md:w-1/2 mr-3">
            <label
              htmlFor="targetWebsite"
              className=" text-[#FFFFFF] block sm:text-[18px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-medium  md:mb-[15px] mb-1"
            >
              Customization
            </label>
            <AccountSetupInputBox
              name="chatBotname"
              type="text"
              value={state.chatbotName}
              placeholder="Chat bot name"
              handleChange={(e) => handleState('chatbotName', e.target.value)}
              disabled={isSettings ? false : isSubmit}
            />
          </div>
          <div className=" sm:w-full md:w-1/2">
            <label className=" text-[#FFFFFF] block sm:text-[18px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-medium  mb-[15px]">
              Chat bot Themes
            </label>
            <div>
              <div className=" md:flex  md:flex-start md:justify-around mt-[22px] mb-[17px] md:w-[40%] sm:w-[20%] w-full  gap-4  md:gap-5  md:h-8">
                <div className="grid grid-cols-8 gap-3 md:flex md:space-x-[18px]">
                  {state.listOfThemes.map((color, index) => (
                    <div
                      key={index}
                      className={`w-6 h-6 md:w-[26px] md:h-[26px]  rounded-sm ${(!isSubmit || isSettings) && 'cursor-pointer'} ${color === state.selectedTheme && 'border-2 border-white'}`}
                      style={{ backgroundColor: color }}
                      onClick={() =>
                        !isSubmit || isSettings ? handleState('selectedTheme', color) : {}
                      }
                    />
                  ))}

                  <div className=" flex ">
                    <CustomColorPicker
                      handleState={handleState}
                      selectedTheme={state.selectedTheme}
                      listOfColor={state.listOfThemes}
                      isSubmit={isSubmit}
                      isSettings={isSettings}
                      storedTheme={state.storedTheme}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-[14px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-normal w-full mb-[1px]">
            <h2 className="text-[14px] sm:leading-[26.86px] md:text-[22px] md:leading-[30.86px] font-bold mb-[15px] mt-[11px]">
              Lorem Ipsum
            </h2>
            <p className="pr-4 mr-4">
              {' '}
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industrys standard dummy text ever since the 1500s, when an unknown
              printer took a galley of type and scrambled it to make a type specimen book.
            </p>
          </div>
          {!isSubmit && (
            <div className="flex justify-center">
              <AccountSetupButton
                isFormValid={true}
                btnText="Next"
                textColor="#FFFFFF"
                backGroundColor="#006BEA"
                onClick={handleSubmitWebsite}
              />
            </div>
          )}
        </form>
      </div>
      {isSubmit ? (
        <WidgetsSubmit
          handleCreateOrUpdateWidgets={handleCreateOrUpdateWidgets}
          isDisabled={isDisabled}
          isSettings={isSettings}
        />
      ) : (
        <div className="flex  justify-end  mt-auto px-5 lg:mb-3">
          <AccountSetupButton
            isFormValid={true}
            btnText="Skip"
            textColor="#FFFFFF"
            backGroundColor="#1E1E1E"
            borderColor="#006BEA"
            onClick={() => navigate('/account-setup/integration')}
          />
        </div>
      )}
    </div>
  );
};

export default CreateWidgets;

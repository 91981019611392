import React from 'react';
import CommonPopup from '../../../../common/CommonPopup';
import InputBox from '../../../../common/InputBox';

interface Props {
  modalInfo: {
    show: boolean;
    uuid: string;
    storeName: string;
  };
  setModalInfo: any;
  onClose: () => void;
  handleDataSourceConnect: any;
}

const DataSourceInfoPopup = (props: Props) => {
  const { modalInfo, onClose, setModalInfo, handleDataSourceConnect } = props;

  return (
    <CommonPopup onClose={onClose}>
      <div className="flex flex-col gap-3 p-2 pb-0">
        <div className="flex flex-col gap-1.5">
          <label
            htmlFor={'StoreName'}
            className="block font-normal text-[#F3F3E0] text-[26px] leading-[29.64px]"
          >
            Store Name
          </label>
          <InputBox
            name="StoreName"
            type="text"
            value={modalInfo.storeName}
            placeholder="example.shopify.com"
            handleChange={(e) => setModalInfo({ ...modalInfo, storeName: e.target.value })}
          />
        </div>
        <div className="flex justify-end">
          <button
            className="bg-[#006BEA] text-white font-medium text-[22px] px-4 py-2 rounded"
            onClick={handleDataSourceConnect}
          >
            Submit
          </button>
        </div>
      </div>
    </CommonPopup>
  );
};

export default DataSourceInfoPopup;

import React from 'react';
import { Route } from 'react-router-dom';

import DashboardLayout from '../components/dashboard/DashboardLayout';
import Dashboard from '../components/dashboard/Dashboard';
import Complaints from '../components/complaints/Complaints';
import Vendors from '../components/vendors/Vendors';
import CreateOrUpdateVendor from '../components/vendors/CreateOrUpdateVendor';
import Notification from '../notifications/Notification';
import ProtectedRoute from './AuthenticatedRoutes';
import CreateComplaint from '../components/complaints/CreateComplaint';
import Chatpage from '../components/chat/Chatpage';

const sidebarMenuNavigation = [
  {
    path: '/dashboard',
    element: (
      <DashboardLayout>
        <Dashboard />
      </DashboardLayout>
    ),
    key: 'dashboard'
  },
  {
    path: '/complaints',
    element: (
      <DashboardLayout>
        <Complaints />
      </DashboardLayout>
    ),
    key: 'complaints'
  },
  {
    path: '/vendors',
    element: (
      <DashboardLayout>
        <Vendors />
      </DashboardLayout>
    ),
    key: 'vendors'
  },
  {
    path: '/vendors/add-vendor',
    element: (
      <DashboardLayout>
        <CreateOrUpdateVendor />
      </DashboardLayout>
    ),
    key: 'add-vendor'
  },
  {
    path: '/vendors/update-vendor/:id',
    element: (
      <DashboardLayout>
        <CreateOrUpdateVendor />
      </DashboardLayout>
    ),
    key: 'update-vendor'
  },
  {
    path: '/notifications',
    element: (
      <DashboardLayout>
        <Notification />
      </DashboardLayout>
    ),
    key: 'notifications'
  },
  {
    path: 'complaints/add-complaint',
    element: (
      <DashboardLayout>
        <CreateComplaint />
      </DashboardLayout>
    ),
    key: 'add-complaint'
  },
  {
    path: '/chat',
    element: (
      <DashboardLayout>
        <Chatpage />
      </DashboardLayout>
    ),
    key: 'chat'
  }
];

export default [
  <Route element={<ProtectedRoute />} key="protected-route">
    {sidebarMenuNavigation.map((route) => (
      <Route key={route.key} path={route.path} element={route.element} />
    ))}
  </Route>
];

import { API_URL } from './Environment';

export interface IClientRegister {
  email: string;
  password: string;
}

export interface IClientLogin {
  email: string;
  password: string;
}

export interface IForgotPassword {
  email: string;
}

export interface IResetPassword {
  password: string;
}
async function loginUser(userdetails: IClientLogin) {
  const response = await fetch(API_URL + 'sessions/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userdetails)
  });
  return response;
}
async function logoutUser(uuid: string) {
  const response = await fetch(API_URL + 'sessions/logout', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ uuid })
  });
  return response;
}
async function signUpUser(userdetails: IClientRegister) {
  const response = await fetch(API_URL + 'sessions/register', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userdetails)
  });
  return response;
}

async function forgotPassword(userdetails: IForgotPassword) {
  const response = await fetch(API_URL + 'sessions/forgot-password', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userdetails)
  });
  return response;
}

async function resetPassword(token: string, userdetails: IResetPassword) {
  const response = await fetch(`${API_URL}sessions/reset-password/${token}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userdetails)
  });
  return response;
}
const verifyUser = async (userUuid: string, otp: string) => {
  try {
    const response = await fetch(`${API_URL}/sessions/${userUuid}/verify`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ otp })
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error verifying user:', error);
    throw new Error('An error occurred during email verification. Please try again.');
  }
};

export { loginUser, logoutUser, signUpUser, forgotPassword, resetPassword, verifyUser };

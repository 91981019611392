import React, { useEffect, useRef, useState } from 'react';
import { IoMdSend } from 'react-icons/io';
import { AiOutlinePaperClip } from 'react-icons/ai';
import chatbot from '../../../src/assets/chatbot.png';

const ChatArea = ({ selectedUser, updateUserMessages }: any) => {
  const [inputValue, setInputValue] = useState('');

  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [selectedUser.messages]);

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const newMessage = { type: 'user', text: inputValue, time: currentTime };
    updateUserMessages(selectedUser.id, newMessage);
    setInputValue('');
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') handleSendMessage();
  };

  return (
    <div className="xl:h-[calc(100vh-264px)] flex flex-col gap-3 bg-[#343434] p-2 xl:p-2 rounded-lg  max-w-[639px] sm:max-w-[767px] md:max-w-[800px] lg:max-w-[800px] mx-auto">
      <div className="flex items-center gap-3 p-2  ">
        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-500 text-white font-bold">
          {selectedUser.name[0]}
        </div>
        <span className="text-white font-medium">#{selectedUser.id}</span>
      </div>
      <div className="border-b-2 border-[#69696980]"></div>
      <div ref={messagesContainerRef} className="flex-grow overflow-y-auto flex flex-col gap-4 p-3">
        {selectedUser?.messages?.map((message: any, index: number) => (
          <div
            key={index}
            className={`flex ${message.type === 'bot' ? 'items-start gap-3' : 'justify-end'}`}
          >
            {message.type === 'bot' && (
              <div className="w-[35px] lg:w-12 lg:h-10 flex items-center justify-center rounded-full ">
                <span className="text-white text-sm md:text-lg">
                  <img src={chatbot} alt="chatbot" />
                </span>
              </div>
            )}
            <div
              className={`${
                message.type === 'bot'
                  ? 'bg-gray-100 text-gray-800 lg:max-w-[60%] max-w-[55%]'
                  : 'bg-blue-500 text-white lg:max-w-[40%] max-w-[45%]'
              } p-3 rounded-lg shadow-md break-words whitespace-normal`}
            >
              <p className="w-full break-all text-xs md:text-lg">{message.text}</p>
              <span className="text-[#343434] text-xs block text-right mt-2">{message.time}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center gap-2 bg-[#B3B3B3] p-2 rounded-full">
        <input
          type="text"
          placeholder="Message Support Bot"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyPress}
          className="flex-grow p-1 lg:p-3 rounded-full bg-[#B3B3B3] text-black placeholder-[#6E6E6E] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button className=" p-1 lg:p-3 rounded-full bg-gray-700 text-white hover:bg-gray-600">
          <AiOutlinePaperClip size={20} />
        </button>
        <button
          className="p-1 lg:p-3 rounded-full bg-blue-500 text-white hover:bg-blue-600"
          onClick={handleSendMessage}
        >
          <IoMdSend size={20} />
        </button>
      </div>
    </div>
  );
};

export default ChatArea;

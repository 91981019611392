import { API_URL } from './Environment';

interface ComplaintDataResponse {
  payload: {
    totalComplaints: {
      totalComplaintCount: number;
      percentage: number | null;
    };
    resolved: {
      complaintsResolved: number;
      percentage: number | null;
    };
    pending: {
      complaintsPending: number;
      percentage: number | null;
    };
    operators: number;
    complaints: Record<string, number>;
    vendor: {
      vendorName: string;
      complaints: number;
      solved: number;
      pending: number;
      rating: number;
    }[];
    complaintRaising: {
      raisedComplaints: {
        title: string;
        description: string;
      }[];
      usersWithUnassignedComplaints: {
        fullName: string;
        complaint: any[];
      }[];
    };
  };
  message: string;
}

async function fetchDashboardInfo(companyUuid: string, type: string, from: string, to: string) {
  const response = await fetch(
    `${API_URL}dashboard?companyUuid=${companyUuid}&type=${type}&from=${from}&to=${to}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );

  const complaintData: ComplaintDataResponse = await response.json();
  return complaintData;
}

export { fetchDashboardInfo };

import { LuClock } from 'react-icons/lu';
import React from 'react';

interface TicketInfo {
  uuid: string;
  ticketId: string;
  title: string;
  description: string;
  product: string;
  userAssigned: string;
  userUuid: string;
  vendor: string;
  vendorUuid: string;
  status: string;
  vendorStatus?: string; 
  raisedOn: string;
}

interface IProps {
  info: TicketInfo;
  selectedComplaintsInfo: any;
  setSelectedComplaintsInfo: any;
  complaintInfo: any;
  setComplaintInfo: any;
  isVendor?: boolean;
}

const TicketCard = (props: IProps) => {
  const {
    info,
    selectedComplaintsInfo,
    setSelectedComplaintsInfo,
    complaintInfo,
    isVendor,
    setComplaintInfo
  } = props;

  function getRelativeTime(dateString: string): string {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const timeUnits: { unit: Intl.RelativeTimeFormatUnit; seconds: number }[] = [
      { unit: 'year', seconds: 60 * 60 * 24 * 365 },
      { unit: 'month', seconds: 60 * 60 * 24 * 30 },
      { unit: 'day', seconds: 60 * 60 * 24 },
      { unit: 'hour', seconds: 60 * 60 },
      { unit: 'minute', seconds: 60 },
      { unit: 'second', seconds: 1 }
    ];

    for (const { unit, seconds } of timeUnits) {
      const interval = Math.floor(diffInSeconds / seconds);
      if (interval >= 1) {
        return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
      }
    }

    return 'just now';
  }

  const displayedStatus = isVendor ? info.vendorStatus || 'N/A' : info.status;

  return (
    <div
      key={info.uuid}
      className={`bg-[#343434] rounded-lg pt-3.5 pl-3.5 pr-2 pb-1.5 cursor-pointer hover:shadow-[0_0_10px_0px_#006BEA] transition-shadow duration-300 ${
        (isVendor ? complaintInfo.uuid : selectedComplaintsInfo.uuid) === info.uuid &&
        'border border-[#006BEA]'
      }`}
      onClick={() => (isVendor ? setComplaintInfo(info) : setSelectedComplaintsInfo(info))}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-x-5 items-center">
          <label className="text-xl text-white font-bold cursor-pointer">Ticket ID</label>
          <label className="text-lg text-white font-regular cursor-pointer">{info.ticketId}</label>
        </div>
        {displayedStatus === 'Assigned' ? (
          <LuClock color={'#006BEA'} size={24} />
        ) : (
          <label
            className={`text-lg font-bold cursor-pointer 
           ${
             displayedStatus === 'Resolved'
               ? 'text-[#28A745]'
               : displayedStatus === 'Reopened'
                 ? 'text-[#FA812F]'
                 : displayedStatus === 'Pending'
                   ? 'text-[#FF4C4C]'
                   : ''
           }`}
          >
            {displayedStatus}
          </label>
        )}
      </div>
      <div className="flex gap-x-6">
        <label className="text-xl text-white font-bold cursor-pointer ">Message</label>
        <label className="text-lg text-white font-regular cursor-pointer max-w-40 md:max-w-60 lg:max-w-72 overflow-hidden text-ellipsis whitespace-nowrap">
          {info.title}
        </label>
      </div>

      <div className="flex justify-end mt-1">
        <label className="text-xs font-bold text-[#D7D7D7] text-right cursor-pointer">
          {getRelativeTime(info.raisedOn)}
        </label>
      </div>
    </div>
  );
};

export default TicketCard;

import TicketCard from './TicketCard';
import React from 'react';

interface Ticket {
  uuid: string;
  ticketId: string;
  title: string;
  description: string;
  product: string;
  userAssigned: string;
  userUuid: string;
  vendor: string;
  vendorUuid: string;
  status: string;
  raisedOn: string;
}
interface IProps {
  tickets: Ticket[];
  selectedComplaintsInfo: any;
  setSelectedComplaintsInfo: any;
  complaintInfo: any;
  setComplaintInfo: any;
  isVendor?: boolean;
}

const ListOfTickets = (props: IProps) => {
  const {
    tickets,
    selectedComplaintsInfo,
    setSelectedComplaintsInfo,
    complaintInfo,
    isVendor,
    setComplaintInfo
  } = props;
  return (
    <div className="flex flex-col gap-2.5 xl:h-[calc(100vh-224px)] xl:overflow-y-auto p-2 md:pr-2.5">
      {tickets.map((info: Ticket, index) => (
        <TicketCard
          info={info}
          selectedComplaintsInfo={selectedComplaintsInfo}
          setSelectedComplaintsInfo={setSelectedComplaintsInfo}
          complaintInfo={complaintInfo}
          setComplaintInfo={setComplaintInfo}
          key={index}
          isVendor={isVendor}
        />
      ))}
    </div>
  );
};

export default ListOfTickets;

import React, { useEffect, useMemo, useState } from 'react';
import { BackButton2 } from '../../../../../common/BackButton2';
import RichTextBox from './RichTextBox';
import { useNavigate } from 'react-router-dom';
import faqAddORUpdate from './FaqAddOrUpdate';
import { FaPlus } from 'react-icons/fa';
import AccountSetupButton from '../../../../../common/AccountSetupButton';

interface IProps {
  setShowFaqManual: any;
  questionsList: { question: string; answer: string; uuid?: string | null }[];
  faqList: { uuid: string | null; faq: { question: string; answer: string }[] }[];
  setQuestionsList: any;
  disable: any;
  setDisable: any;
  fetchAllFaq: any;
  setFaqList: any;
  isLoading: boolean;
  handleDeleteFaq: (index: number, faqUUID: string) => void;
}
const FaqManual = (props: IProps) => {
  const {
    questionsList,
    faqList,
    setQuestionsList,
    setShowFaqManual,
    disable,
    setDisable,
    fetchAllFaq,
    setFaqList,
    isLoading,
    handleDeleteFaq
  } = props;

  const navigate = useNavigate();
  const optimizedQuestionsList = useMemo(() => questionsList, [questionsList]);
  const [isAddOrEditPopUpOpen, setIsAddOrEditPopUpOpen] = useState(false);

  const faqPost = () => {
    faqAddORUpdate({ questionsList, setQuestionsList, faqList, navigate, setDisable });
  };

  useEffect(() => {
    if (faqList.length === 0) {
      fetchAllFaq();
    }
  }, []);

  return (
    <div className="w-full">
      <div className="">
        <p className="h-[31px] lg:ml-9 ml-[4px] md:ml-2 mb-[5px] lg:text-[22px] text-[14px] sm:text-[18px] sm:leading-[26.86px]  md:leading-[30.86px] font-normal">
          STEP 2
        </p>
      </div>
      <div className="flex justify-between md:mb-10 mb-4">
        <div className="flex md:items-center items-start ml-[4px] md:ml-1">
          <div className="lg:mt-1">
            <BackButton2
              onClick={() => {
                setShowFaqManual(false);
                setFaqList([]);
              }}
            />
          </div>
          <h3 className="h-[39px] md:mt-0  lg:mt-2 sm:text-[18px] sm:leading-[29.18px] md:text-[28px] md:leading-[39.28px] font-medium">
            Frequently Asked Questions
          </h3>
        </div>
        <div
          className="md:h-10 md:w-10 h-8 w-8 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
          onClick={() => setIsAddOrEditPopUpOpen(true)}
        >
          <FaPlus className="md:h-5 md:w-5 h-3 w-3" />
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-[70%] w-full text-white text-lg font-bold">
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
            </div>
          </>
        </div>
      ) : (
        <RichTextBox
          questionsList={optimizedQuestionsList}
          setQuestionsList={setQuestionsList}
          handleDeleteFaq={handleDeleteFaq}
          setIsAddOrEditPopUpOpen={setIsAddOrEditPopUpOpen}
          isAddOrEditPopUpOpen={isAddOrEditPopUpOpen}
        />
      )}

      <div className="fixed bottom-2 right-6 gap-2 md:gap-4">
        <div className="flex gap-7">
          <AccountSetupButton
            isFormValid={true}
            btnText="Skip"
            textColor="#FFFFFF"
            backGroundColor="#1E1E1E"
            borderColor="#006BEA"
            onClick={() => navigate('/account-setup/widgets')}
          />
          <AccountSetupButton
            isFormValid={true}
            btnText="Next"
            textColor="#FFFFFF"
            backGroundColor="#006BEA"
            borderColor="#006BEA"
            onClick={() => faqPost()}
            disable={disable}
          />
        </div>
      </div>
      {disable && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
    </div>
  );
};
export default FaqManual;

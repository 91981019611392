import React, { memo } from 'react';
import SettingList from './SettingList';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
interface SettingUserProps {
  searchQuery?: string;
}
const SettingUsers = (props: SettingUserProps) => {
  const { searchQuery } = props;
  const navigate = useNavigate();
  return (
    <>
      <div className="">
        <div className="flex justify-between mb-1 ps-4 pe-6">
          <h1 className="md:text-3xl lg:text-3xl font-medium text-white sm:text-[20px]">Users</h1>
          <div
            className="md:h-10 md:w-10 h-8 w-8 bg-[#006BEA] rounded-lg flex justify-center items-center cursor-pointer"
            onClick={() => navigate('create-user')}
          >
            <FaPlus className="md:h-5 md:w-5 h-3 w-3" />
          </div>
        </div>
        <SettingList searchQuery={searchQuery} />
      </div>
    </>
  );
};

export default memo(SettingUsers);

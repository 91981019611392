import './App.css';
import Navigation from './navigation/Navigation';
import { useSocket } from './Contexts/SocketContext';
import React, { useEffect } from 'react';
import ToastNotification from './common/ToastNotification';

const MainComponent = () => {
  const { socketInstance } = useSocket();

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on('updateNotifications', (notification: any) => {
        if (notification.message) {
          ToastNotification({
            message: notification.message,
            type: 'info'
          });
        }
      });
      return () => {
        socketInstance.off('notifications');
        socketInstance.off('getNotifications');
        socketInstance.off('searchNotifications');
        socketInstance.off('updateNotifications');
      };
    }
  }, [socketInstance]);
  return (
    <>
      <Navigation />
    </>
  );
};

export default MainComponent;

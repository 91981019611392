import { useEffect } from 'react';
import Notification from '../../../notifications/Notification';
import Complaints from '../../complaints/Complaints';
import { getVendorLogin } from '../../../api/VendorLogin';
import { useParams } from 'react-router-dom';

const VendorComplaints = () => {
  const { vendorid } = useParams();

  useEffect(() => {
    const fetchFullName = async () => {
      try {
        const vendorData = await getVendorLogin(vendorid ? vendorid : '');
        const vendorDetail = vendorData?.payload;
        if (vendorDetail) {
          sessionStorage.setItem('fullName', vendorDetail?.fullname || 'Guest');
        }
      } catch (error) {
        console.error('Error fetching full name:', error);
      }
    };

    fetchFullName();
  }, []);

  return (
    <div className="w-full md:pl-14">
      <Complaints isVendor={true} />
    </div>
  );
};

export default VendorComplaints;

import React, { memo } from 'react';
import { AiOutlineQuestionCircle, AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

const QuestionItem = (props: any) => {
  const { item, index, editingIndex, handleEditQuestion, handleDeleteQuestion, questionsList } =
    props;
  const updateAnswerWithImageSize = (answer: string) => {
    const imgRegex = /<img[^>]+>/g;
    const iframeRegex = /<iframe[^>]+>/g;

    let updatedAnswer = answer?.replace(imgRegex, (imgTag) => {
      if (imgTag.includes('<img')) {
        return imgTag.replace(
          '<img',
          '<img style="height: 70px; width: 70px; margin: 10px; margin-right: 20px; display: inline-block;"'
        );
      }
      return imgTag;
    });

    updatedAnswer = updatedAnswer?.replace(iframeRegex, (iframeTag) => {
      if (iframeTag.includes('<iframe')) {
        return iframeTag.replace(
          '<iframe',
          '<iframe style="width: 100%; height: 315px; border-radius: 8px; margin-top: 10px;"'
        );
      }
      return iframeTag;
    });

    return updatedAnswer;
  };

  return (
    <div key={index}>
      <div className="flex items-start justify-between h-auto flex-wrap mt-3">
        <span className="mr-3 mt-2  md:mt-0">
          <AiOutlineQuestionCircle className="lg:h-[28px] lg:w-[28px] md:h-[25px] md:w-[25px] h-[18px] w-[18px] text-[#006BEA]" />
        </span>

        <p className="md:font-bold font-normal text-base md:text-[24px] text-[#ffffff] md:leading-[27.36px] flex-1">
          {item.question}
        </p>

        <span className="flex xe md:mt-0 mt-2">
          {!editingIndex && (
            <span className="md:ml-3 cursor-pointer" onClick={() => handleEditQuestion(index)}>
              <FiEdit className="md:h-6 md:w-6  h-4 w-4 text-[#006BEA]" />
            </span>
          )}
          <span
            className="ml-3 cursor-pointer"
            onClick={() => handleDeleteQuestion(index, item.uuid)}
          >
            <AiOutlineDelete className="md:h-7 md:w-7  h-5 w-5 text-[#FA0000]" />
          </span>
        </span>
      </div>

      <div
        className={`md:ml-12 ml-8 mb-4 mt-1 ${questionsList.length === index + 1 ? 'mb-24' : null}`}
      >
        <p
          className="md:font-normal font-light text-sm md:text-[22px]  leading-[25.08px] text-[#ffffff] "
          dangerouslySetInnerHTML={{ __html: updateAnswerWithImageSize(item.answer) }}
        ></p>
      </div>
      <br />
    </div>
  );
};

export default memo(QuestionItem);

import AnalysisChart from './AnalysisChart';

const EngagementAnalysis = ({ engagement, allUser }: any) => {
  return (
    <div className="flex flex-col gap-y-4 bg-[#343434] rounded-lg pt-2.5 p-2  md:p-4">
      <label className="text-lg font-normal text-white">Engagement Analysis</label>
      <div className="flex justify-center items-center w-full h-[353px] text-center">
        <AnalysisChart engagement={engagement} allUser={allUser} />
      </div>
    </div>
  );
};

export default EngagementAnalysis;

import React from 'react';
const ComplaintsCardSkeleton = () => {
  const Cardskeleton = () => {
    return (
      <div className="bg-[#343434] rounded-lg pt-3.5 pl-1.5 pr-2 pb-1.5 animate-pulse">
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-x-2 md:gap-x-5 items-center">
            <div className="w-16 h-4 bg-gray-500 rounded sm:w-20 md:w-24"></div>
            <div className="w-12 h-3 bg-gray-500 rounded sm:w-16 md:w-20"></div>
          </div>
          <div className="w-5 h-5 bg-gray-500 rounded-full sm:w-6 sm:h-6"></div>
        </div>

        <div className="flex flex-col sm:flex-row sm:gap-x-4 md:gap-x-6 items-start sm:items-center mb-2">
          <div className="w-16 h-4 bg-gray-500 rounded sm:w-20"></div>
          <div className="w-full h-3 bg-gray-500 rounded sm:w-64 md:w-72 mt-2 sm:mt-0"></div>
        </div>

        <div className="flex justify-end">
          <div className="w-12 h-2 bg-gray-500 rounded sm:w-14 md:w-16"></div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex w-[114%] flex-col xl:flex-row">
      <div className="bg-[#343434] rounded-lg p-4 whitespace-pre-wrap m-3 animate-pulse w-[85%]  md:w-full lg:w-full xl:w-[40%]">
        {new Array(6).fill(1).map((_, idx) => (
          <Cardskeleton key={idx} />
        ))}
      </div>

      <div className="w-[85%]  md:w-full xl:w-[58.5%] bg-[#343434] rounded-lg p-2 mt-2 m-3  md:ml-3">
        <div className="flex justify-between items-center mb-6">
          <div className="w-full flex gap-4">
            <div className="w-1/4 h-6 bg-gray-500 rounded"></div>
            <div className="w-[20%] h-6 bg-gray-500 rounded"></div>
          </div>
          <div className="w-1/12 h-6 bg-gray-500 rounded"></div>
        </div>

        <div className="mb-6 flex gap-4">
          <div className="w-1/4 h-6 bg-gray-500 rounded mb-2"></div>
          <div className="w-1/3 h-6 bg-gray-500 rounded"></div>
        </div>

        <div className="mb-6 flex gap-4">
          <div className="w-1/4 h-6 bg-gray-500 rounded mb-2"></div>
          <div className="w-2/3 h-12 bg-gray-500 rounded"></div>
        </div>

        <div className="mb-6 flex gap-4">
          <div className="w-1/4 h-6 bg-gray-500 rounded mb-2"></div>
          <div className="w-1/3 h-6 bg-gray-500 rounded"></div>
        </div>

        <div className="mb-6 flex gap-4">
          <div className="w-1/4 h-6 bg-gray-500 rounded mb-2"></div>
          <div className="w-16 h-8 bg-gray-500 rounded"></div>
        </div>

        <div className="mb-6 flex gap-4">
          <div className="w-1/4 h-6 bg-gray-500 rounded mb-2"></div>
          <div className="w-16 h-8 bg-gray-500 rounded"></div>
        </div>

        <div className="mb-6 flex gap-4">
          <div className="w-1/4 h-6 bg-gray-500 rounded mb-2"></div>
          <div className="w-1/2 h-10 bg-gray-500 rounded"></div>
        </div>
      </div>
    </div>
  );
};

export default ComplaintsCardSkeleton;

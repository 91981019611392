import { useNavigate } from 'react-router-dom';
import Lopa from '../../assets/whitelogo.png';
import { BackButton } from '../../common/BackButton';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="h-full">
      <img src={Lopa} className="lg:w-44 lg:h-20 w-28 h-16 mr-2 ml-8 mt-8 mb-8" alt="Matrix AI" />
      <div className="flex gap-2 items-center px-8">
        <BackButton onClick={() => navigate(-1)} />
        <label className="text-3xl text-white font-bold pt-2.5">Privacy Policy</label>
      </div>
      <div className="flex flex-col gap-5 p-8 text-lg text-white font-normal">
        <label>
          This legal document is an electronic record in terms of Information Technology Act, 2000,
          and rules there under as applicable and the amended provisions pertaining to electronic
          records in various statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not require any physical or
          digital signatures. This legal document is published in accordance with the provisions of
          Rule 4 of the Information Technology (Reasonable security practices and procedures and
          sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000
          amended through Information Technology Amendment Act, 2008 that require publishing the
          privacy policy for access or usage of Lopaa AI service and its integrated systems.
        </label>
        <label>
          This privacy policy applies to all users of Lopaa AI service and its integrated systems.
        </label>
        <label>
          This privacy policy applies to all users of Lopaa AI service and its integrated systems.
        </label>
        <label>
          By using this service, clicking the “I accept” button or completing the registration
          process, you agree and acknowledge that you have reviewed this privacy policy, given
          consent and accepted this agreement.
        </label>
        <label>
          References in these Terms to “we” or “us” or “app” or “firm” are references to Lopaa AI
          service and “you” as subscriber/customer of Lopaa AI service and its integrated systems.
        </label>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

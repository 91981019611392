import React, { useEffect, useState } from 'react';
import VendorNavbar from '../../common/layout/VendorNavbar';
import { Link } from 'react-router-dom';
import { notifyDashboard } from '../../api/Notification';
import { IoSettingsOutline } from 'react-icons/io5';
import BellIcon from '../../assets/Bell.png';
import { useSocket } from '../../Contexts/SocketContext';

const VendorLoginLayout = ({ children }: any) => {
  const [hasNotifications, setHasNotifications] = useState([]);
  const { socketInstance } = useSocket();

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     try {
  //       const response = await notifyDashboard();
  //       const data = await response.json();
  //       // console.log('<----- data ---->', data);
  //       if (Array.isArray(data?.payload?.notification) && data.payload.notification.length > 0) {
  //         setHasNotifications(true);
  //       } else {
  //         setHasNotifications(false);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching notifications:', error);
  //       setHasNotifications(false);
  //     }
  //   };

  //   fetchNotifications();
  // }, []);
  useEffect(() => {
    if (socketInstance) {
      setTimeout(() => {
        socketInstance.emit('getNotifications');
      }, 300);
      socketInstance.on('notifications', (notifications: any) => {
        const mappedunreadNotifications = notifications.filter(
          (notification: any) => !notification.read
        );
        setHasNotifications(mappedunreadNotifications);
      });

      socketInstance.on('updateNotifications', (notification: any) => {
        socketInstance.emit('getNotifications');
      });

      return () => {
        // socketInstance.off('notifications');
        // socketInstance.off('updateNotifications');
        // socketInstance.off('getNotifications');
      };
    }
    // }
  }, [socketInstance]);

  const bellAndSettingsIcon = (
    <div className="flex space-x-4 text-white">
      <div className="relative mx-2 lg:mx-0">
        <Link to="/vendor-login/vendor-notification">
          <img src={BellIcon} className="md:w-[23px] md:h-[25px] w-[15px] h-[18px]" alt="bell" />
        </Link>

        {hasNotifications.length > 0 && (
          <span
            className={`absolute top-0 ${hasNotifications.length < 99 ? 'right-0 left-2 bottom-5' : 'right-0'} inline-flex items-center justify-center
    ${hasNotifications.length < 99 ? 'w-4 h-4 p-[2px]' : 'w-[18px] h-[18px] px-[2px]'} 
    ${hasNotifications.length < 99 ? 'text-[9px]' : 'text-[8px]'} 
    bg-red-500 text-white font-semibold rounded-full`}
          >
            {hasNotifications.length > 99 ? '99+' : hasNotifications.length}
          </span>
        )}
      </div>
    </div>
  );
  return (
    <>
      <VendorNavbar bellAndSettingsIcon={bellAndSettingsIcon} children={children} />
    </>
  );
};

export default VendorLoginLayout;

import React from 'react';
import { Link } from 'react-router-dom';
interface IProps {
  linkText: string;
  link: string;
}
const Links = (props: IProps) => {
  const { linkText, link } = props;
  return (
    <div className="flex flex-row justify-center gap-[26px] underline underline-offset-4 text-[10px] sm:text-[15px] md:text-[16px] md:font-normal leading-[18.81px]">
      {/* <div>
        <Link to={link}>{linkText}</Link>
      </div> */}
      <div>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      {/* <div>
        <Link to="#">Terms of Service</Link>
      </div> */}
    </div>
  );
};

export default Links;

import NotificationCard from './NotificationCard';
import React from 'react';
interface IProps {
  tickets: any;
  selectedTicketIdx: any;
  setSelectedTicketIdx: any;
  markAsRead: any;
  isRead: any;
}

const NotificationList = (props: IProps) => {
  const { tickets, selectedTicketIdx, setSelectedTicketIdx, markAsRead, isRead } = props;

  return (
    <>
      <div className="flex flex-col gap-2 xl:h-[calc(100vh-234px)] pt-3 pl-3">
        <div className="flex justify-between items-center">
          <div className="flex ">
            <label className="text-[22px] text-white pl-[10px] font-bold">Notifications</label>
          </div>
        </div>
        <hr className="border-[#696969] border-1 ml-1 mr-4" />

        <div className="overflow-y-auto pr-2 pt-1">
          {tickets.length > 0 ? (
            tickets.map((info: any, index: number) => (
              <NotificationCard
                info={info}
                index={index}
                selectedTicketIdx={selectedTicketIdx}
                setSelectedTicketIdx={setSelectedTicketIdx}
                markAsRead={markAsRead}
                key={index}
                isRead={isRead}
                tickets={tickets}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-full">
              <p className="text-white text-lg">No Notifications</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default NotificationList;

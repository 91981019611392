import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Profile from '../components/vendor-login/profile/Profile';
import VendorComplaints from '../components/vendor-login/complaints/VendorComplaints';
import NotificationVendor from '../components/vendor-login/notification/Notification';

const VendorNavigation = () => {
  const VendorNavigation = [
    {
      path: '/vendor-profile/:vendorid',
      element: <Profile />,
      key: 'profile'
    },
    {
      path: '/vendor-complaints/:vendorid',
      element: <VendorComplaints />,
      key: 'vendor-complaints'
    },
    {
      path: '/vendor-notification',
      element: <NotificationVendor />,
      key: 'vendor-notifications'
    }
  ];

  return (
    <Routes>
      {VendorNavigation.map((route) => (
        <Route key={route.key} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};
export default VendorNavigation;

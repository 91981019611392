import React, { useState, useRef, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import rgbHex from 'rgb-hex';
interface colorPickerProps {
  handleState: (field: string, value: string) => void;
  selectedTheme: any;
  listOfColor: string[];
  isSubmit: boolean;
  isSettings: boolean | undefined;
  storedTheme: string;
}

const CustomColorPicker = (props: colorPickerProps) => {
  const { handleState, selectedTheme, listOfColor, isSettings, isSubmit, storedTheme } = props;
  const [color, setColor] = useState<string>(
    !listOfColor.includes(selectedTheme) ? selectedTheme : '#FF4C4C'
  );
  const [inputColor, setInputColor] = useState<string>(
    !listOfColor.includes(selectedTheme) ? selectedTheme : '#FF4C4C'
  );
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const pickerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    setInputColor(!listOfColor.includes(selectedTheme) ? selectedTheme : storedTheme);
    setColor(!listOfColor.includes(selectedTheme) ? selectedTheme : storedTheme);
  }, [selectedTheme, storedTheme]);

  const handleChangeComplete = (color: any) => {
    setColor(color);
    setInputColor(color);
    handleState('selectedTheme', color);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^#[0-9A-Fa-f]{0,6}$/.test(value)) {
      setInputColor(value.toUpperCase());
      setColor(value.toUpperCase());
      handleState('selectedTheme', value.toUpperCase());
    }
  };
  const handleColorOnClick = () => {
    handleState('selectedTheme', color.toUpperCase());
  };
  const togglePicker = () => setIsPickerOpen((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setIsPickerOpen(false);
      }
    };

    if (isPickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPickerOpen]);

  return (
    <div className="relative flex flex-col items-center ">
      <div
        ref={buttonRef}
        onClick={togglePicker}
        style={{ pointerEvents: !isSettings && isSubmit ? 'none' : undefined }}
        className={`flex items-center bg-[#343434] text-white rounded-md shadow-md ${!listOfColor.includes(selectedTheme) ? 'border-2 border-white' : ''}`}
      >
        <span
          className="md:w-7 md:h-7 w-6 h-6 rounded-l-md mr-2"
          style={{ backgroundColor: color }}
        ></span>
        <input
          type="text"
          value={inputColor}
          onChange={handleInputChange}
          className="bg-transparent text-white border-none focus:outline-none md:w-24 w-20 pe-2"
          maxLength={7}
          onClick={handleColorOnClick}
          disabled={!isSettings && isSubmit}
        />
      </div>

      {isPickerOpen && (
        <div
          ref={pickerRef}
          className="absolute mt-2 z-50 left-[40%] md:left-[50%] md:transform md:-translate-x-1/2  transform -translate-x-1/2"
          style={{
            top: '100%'
          }}
        >
          <div
            className="bg-[#343434] rounded-lg shadow-lg p-4 "
            style={{
              minWidth: '250px',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.25)'
            }}
          >
            <ChromePicker
              color={color}
              onChange={(c) => {
                const { r, g, b, a } = c.rgb;
                const hexColor = `#${rgbHex(r, g, b, a)}`;
                setColor(hexColor.toUpperCase());
                handleChangeComplete(hexColor.toUpperCase());
              }}
              styles={{
                default: {
                  picker: {
                    boxShadow: 'none',
                    background: '#343434',
                    borderRadius: '8px'
                  },
                  saturation: {
                    borderRadius: '4px'
                  },
                  swatch: {
                    boxShadow: 'none'
                  }
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomColorPicker;

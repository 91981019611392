import React from 'react'
const ViewDemo = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <video width="1000" height="1000" controls autoPlay={true} muted loop>
        <source
          src="https://api-support-system.sulopa.com/api/v1/common/gif/lopaa-ai-video.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default ViewDemo;

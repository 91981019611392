import { API_URL } from './Environment';

interface IUpdateVendorInfo {
  fullName: string;
  email: string;
  phone: string;
  address: string;
  companyName: string;
  code: string | null;
  country: string | null;
}

interface UpdateStatusInfo {
  complaintUuid: string;
  status: string;
  companyUuid: string;
}

async function getVendorLogin(vendorUuid: string) {
  const response = await fetch(API_URL + `vendor/${vendorUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  const data = await response.json();
  return data;
}

async function updateVendor(vendorLoginDetail: IUpdateVendorInfo, vendorUuid: string) {
  const response = await fetch(API_URL + `vendor/update-profile`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },

    body: JSON.stringify(vendorLoginDetail)
  });
  return response;
}

async function getVendorComplaintList(
  vendorUuid: string,
  page: number,
  limit: number,
  type: string,
  search: string,
  from: string,
  to: string
) {
  const response = await fetch(
    `${API_URL}vendor/complaint-list?vendorUuid=${vendorUuid}&type=${type}&search=${search}&from=${from}&to=${to}&page=${page}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
  );
  const data = await response.json();
  return data;
}

async function updateComplaint(complaintDetails: UpdateStatusInfo) {
  const response = await fetch(API_URL + `vendor/update-status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(complaintDetails)
  });
  console.log('<--- response --->', response);
  return response;
}

export { getVendorLogin, updateVendor, getVendorComplaintList, updateComplaint };

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { phoneNumberRangeRegex } from '../../../../function/Regex';
import { validateForm } from '../../../../function/FormValidation';
import { getCompanyDetails, updateCompany } from '../../../../api/AccountSetup';
import ToastNotification from '../../../../common/ToastNotification';
import { countrycodes } from '../../../../country-code/CountryCodes';
import CountryCode from '../../../../common/CountryCode2';
import AccountSetupInputBox from '../../../../common/AccountSetupInputBox';
import AccountSetupButton from '../../../../common/AccountSetupButton';

interface CompanyDetails {
  companyName: string;
  industry: string;
  address: string;
  phone: string;
  code?: string;
  country?: string;
  supportEmail?: string;
  whatsAppNumber?: string;
  email?: string;
}
interface Country {
  code: string;
  flag: string;
  name: string;
  teleCode: string;
}
const CreateCompanyProfile = () => {
  const navigate = useNavigate();
  const uuid = sessionStorage.getItem('companyUuid');

  const [formData, setFormData] = useState({
    companyName: '',
    industry: '',
    contactNumber: '',
    Countrycode: '',
    Countryname: '',
    supportEmail: '',
    address: '',
    whatsAppNumber: '',
    email: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disable, setDisable] = useState(false);
  const handlePhoneChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      Countrycode: value
    }));
  };
  useEffect(() => {
    handlePhoneChange(formData.Countrycode);
  }, [formData.contactNumber]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setFormData({
        ...formData,
        Countryname: selectedCountry.name,
        Countrycode: selectedCountry.teleCode
      });
    }
  }, [selectedCountry]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (country: Country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredCountries = countrycodes?.filter(
    (country: Country) =>
      country.teleCode.includes(searchQuery) ||
      country.code.toLowerCase().includes(searchQuery) ||
      country.name.toLowerCase().includes(searchQuery)
  );

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companyData = await getCompanyDetails(uuid ? uuid : '');
        const companyDetails: CompanyDetails = companyData?.payload?.companyDetail;
        if (companyDetails) {
          setFormData({
            companyName: companyDetails?.companyName || '',
            industry: companyDetails?.industry || '',
            contactNumber: companyDetails?.phone || '',
            supportEmail: companyDetails?.supportEmail || '',
            address: companyDetails?.address || '',
            whatsAppNumber: companyDetails?.whatsAppNumber || '',
            email: companyDetails?.email || '',
            Countrycode: companyDetails?.code || '',
            Countryname: companyDetails?.country || ''
          });
          const selectedCountry = countrycodes.find(
            (country) => country.teleCode === companyDetails.code
          );
          if (selectedCountry) {
            setSelectedCountry(selectedCountry);
          }
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
        setErrors((prev) => ({ ...prev, fetch: 'Failed to fetch company details.' }));
      }
    };

    fetchCompanyDetails();
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleCreateCompanyProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(formData, [
      'companyName',
      'contactNumber',
      'address',
      'supportEmail',
      'industry',
      'whatsAppNumber',
      'email'
    ]);
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (isValid) {
      try {
        const response = await updateCompany(
          {
            companyName: formData.companyName,
            contactNumber: formData.contactNumber,
            supportEmail: formData.supportEmail || null,
            address: formData.address,
            industry: formData.industry,
            whatsAppNumber: formData.whatsAppNumber,
            email: formData.email,
            code: formData.Countrycode || '+91',
            country: formData.Countryname || 'India'
          },
          uuid ? uuid : ''
        );
        if (response.ok) {
          const data = await response.json();
          ToastNotification({
            message: data.message
          });
          setTimeout(() => {
            navigate('/account-setup/faq');
            sessionStorage.setItem('companyName', formData.companyName);
            setDisable(false);
          }, 1500);
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
          setDisable(false);
        }
      } catch (error) {
        setDisable(false);
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 bg-[#1E1E1E] rounded-lg  lg:px-8 md:pt-3 lg:pt-6 p-2 overflow-y-auto">
      <h2 className="lg:text-[22px] md:text-[17px]  text-[14px] font-normal md:mb-[5px] mb-1  ms-1  ">
        STEP 1
      </h2>
      <h3 className="lg:text-[28px] md:text-[21px] mt-1 md:mt-0 text-[16px] md:mb-[20px] mb-4 font-medium ms-1">
        Create Profile
      </h3>
      <form
        className="flex flex-col justify-between  flex-1 ms-1"
        onSubmit={handleCreateCompanyProfile}
        method="Post"
      >
        <div className="grid grid-cols-1  gap-x-[60px] ">
          <label className="text-[#FFFFFF] block text-[13px]  sm:leading-[26.86px] lg:text-[20px] md:text-[16px] md:leading-[28.05px] font-normal  mb-[8px] ">
            Company Name
          </label>
          <div>
            <AccountSetupInputBox
              name="companyName"
              type="text"
              value={formData.companyName}
              placeholder="e.g Company Name"
              handleChange={handleChange}
              error={errors.companyName}
            />
          </div>
          <label className="text-[#FFFFFF] block text-[13px] sm:leading-[26.86px] lg:text-[20px] md:text-[16px] md:leading-[28.05px] font-normal  mb-[8px]">
            Industry
          </label>
          <div>
            <AccountSetupInputBox
              name="industry"
              type="text"
              value={formData.industry}
              placeholder="Industry"
              handleChange={handleChange}
              error={errors.industry}
            />
          </div>
          <label className="text-[#FFFFFF] block text-[13px] sm:leading-[26.86px] lg:text-[20px] md:text-[16px] md:leading-[28.05px] font-normal  mb-[8px]">
            Contact number
          </label>
          <div className="flex gap-2">
            <CountryCode
              formData={formData}
              isOpen={isOpen}
              searchQuery={searchQuery}
              selectedCountry={selectedCountry}
              dropdownRef={dropdownRef}
              filteredCountries={filteredCountries}
              toggleDropdown={toggleDropdown}
              handleSearch={handleSearch}
              handleSelect={handleSelect}
              countrycodes={countrycodes}
            />
            <div className="w-full">
              <AccountSetupInputBox
                name="contactNumber"
                type="text"
                value={formData.contactNumber}
                placeholder="Contact Number"
                error={errors.contactNumber}
                handleChange={(e) => {
                  const value = e.target.value;
                  if (phoneNumberRangeRegex.test(value)) {
                    handleChange(e);
                  }
                }}
              />
            </div>
          </div>
          <label className="text-[#FFFFFF] block text-[13px] sm:leading-[26.86px] lg:text-[20px] md:text-[16px] md:leading-[28.05px] font-normal  mb-[8px]">
            Email
          </label>
          <div>
            <AccountSetupInputBox
              name="email"
              type="text"
              value={formData.email}
              placeholder="Email"
              handleChange={handleChange}
              error={errors.email}
            />
          </div>
          <label className="text-[#FFFFFF] block text-[13px] sm:leading-[26.86px] lg:text-[20px] md:text-[16px] md:leading-[28.05px] font-normal  mb-[8px]">
            Address
          </label>
          <div>
            <AccountSetupInputBox
              name="address"
              type="text"
              value={formData.address}
              placeholder="Address"
              handleChange={handleChange}
              error={errors.address}
            />
          </div>
        </div>

        <div className="my-4 mt-1">
          <label className="lg:text-[22px] md:text-[18px] text-[14px]  font-medium">
            To get connect with customers add your WhatsApp number and Email address
          </label>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-3 md:mt-3 mt-1">
          <div>
            <label className="text-[#FFFFFF] block text-[13px] sm:leading-[26.86px] lg:text-[20px] md:text-[16px] md:leading-[28.05px] font-normal  mb-[8px]">
              WhatsApp Number
            </label>
            <div className="mt-1">
              <AccountSetupInputBox
                name="whatsAppNumber"
                type="text"
                value={formData.whatsAppNumber}
                placeholder="WhatsApp Number"
                error={errors.whatsAppNumber}
                handleChange={(e) => {
                  const value = e.target.value;
                  if (phoneNumberRangeRegex.test(value)) {
                    handleChange(e);
                  }
                }}
              />
            </div>
          </div>

          <div>
            <label className="text-[#FFFFFF] block text-[13px] sm:leading-[26.86px] lg:text-[20px] md:text-[16px] md:leading-[28.05px] font-normal  mb-[8px]">
              Email Address
            </label>
            <div className="mt-1">
              <AccountSetupInputBox
                name="supportEmail"
                type="text"
                value={formData.supportEmail}
                placeholder="Email"
                handleChange={handleChange}
                error={errors.supportEmail}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-auto lg:mb-2">
          <AccountSetupButton
            isFormValid={true}
            disable={disable}
            btnText="Next"
            textColor="#FFFFFF"
            backGroundColor="#006BEA"
          />
        </div>
      </form>
      {disable && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateCompanyProfile;

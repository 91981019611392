import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Links from '../../common/Links';
import Button from '../../common/Button';
import InputBox from '../../common/InputBox';
import Lopa from '../../assets/whitelogo.png';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../api/Auth';
import ToastNotification from '../../common/ToastNotification';
import { validateForm } from '../../function/FormValidation';
import { useSocket } from '../../Contexts/SocketContext';
import EyeButton from '../../common/EyeButton';

const Login = () => {
  const navigate = useNavigate();
  const { connectSocket } = useSocket();
  const [disable, setDisable] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(formData, ['email', 'password']);
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (isValid) {
      try {
        const response = await loginUser({
          email: formData.email || '',
          password: formData.password || ''
        });
        const data = await response.json();
        if (!response.ok) {
          ToastNotification({
            message: data?.message,
            type: 'error'
          });
          setDisable(false);
        } else if (response.ok) {
          ToastNotification({
            message: data.message
          });
          const { token, userUUID, companyUuid, companyName, setting, key } = data.payload;
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('userUUID', userUUID);
          sessionStorage.setItem('companyUuid', companyUuid);
          sessionStorage.setItem('companyName', companyName);
          sessionStorage.setItem('key', key);
          connectSocket(userUUID);
          const id = userUUID;
          setTimeout(() => {
            if (key === 'Vendor') {
              navigate(`/vendor-login/vendor-complaints/${id}`);
              setDisable(false);
            } else if (key === 'User') {
              if (setting === 'finished') {
                navigate('/dashboard');
                setDisable(false);
              } else {
                navigate('/account-setup/create-profile');
                setDisable(false);
              }
            } else {
              console.error('key error came.');
            }
            setFormData({
              email: '',
              password: ''
            });
          }, 1500);
        }
      } catch (error: any) {
        setDisable(false);
      }
    }
  };

  return (
    <>
      <div className="flex justify-center pt-12 pb-6">
        <img src={Lopa} className="lg:w-44 lg:h-20 w-28 h-16" alt="Matrix AI" />
      </div>
      <div className="flex h-full flex-col justify-center px-2 py-3 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg rounded-lg p-2">
          {/* <h1 className="text-start text-[25px]   md:text-[46px] md:leading-[50.25px] font-bold tracking-tight">
            Hello &#58;&#41;
          </h1> */}
          <h3 className="font-normal sm:text-[20px] md:text-[20px] sm:leading-[31.28px] md:leading-[39.28px]">
            Login to your account
          </h3>
          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-lg">
            <form className="space-y-3" onSubmit={handleSubmit} method="Post">
              <div>
                <div>
                  <InputBox
                    name="email"
                    type="text"
                    value={formData.email}
                    handleChange={handleChange}
                    placeholder="Email"
                    error={errors.email}
                  />
                </div>
              </div>
              <div>
                <div className="relative">
                  <InputBox
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formData.password}
                    handleChange={handleChange}
                    placeholder="Password"
                    error={errors.password}
                  />
                  <EyeButton
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                  />
                </div>
                <div className="grid justify-items-end">
                  <div>
                    <Link
                      to="/forgot-password"
                      className=" font-normal underline  md:text-[20px] md:leading-[23.52px] sm:text-[18px] underline-offset-4 md:underline-offset-[6px] "
                    >
                      Forgot password
                    </Link>
                  </div>
                </div>
              </div>
              <Button
                isFormValid={true}
                disable={disable}
                btnText="Login"
                textColor="#FFFFFF"
                backGroundColor="#006BEA"
                borderColor=""
              />
            </form>
            {/* <div className="mt-[60px]">
              <Icon />
            </div> */}
            <div className="flex justify-center items-center gap-2 text-base">
              <label>Dont have an account ?</label>
              <Link to="/signup" className="underline text-[#006BEA] font-semibold">
                Sign Up
              </Link>
            </div>
            <div className="mt-[70px]">
              <Links linkText="Sign Up" link="/signup" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

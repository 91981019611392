import React, { useState } from 'react';
import InputBox from '../../common/InputBox';
import { validateForm } from '../../function/FormValidation';
import Button from '../../common/Button';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../common/BackButton';
import ToastNotification from '../../common/ToastNotification';
import { createComplaint } from '../../api/Complaints';

function CreateComplaint() {
  const navigate = useNavigate();
  const companyUuid = sessionStorage.getItem('companyUuid');

  const [formData, setFormData] = useState({
    orderUuid: '',
    productUuid: '',
    title: '',
    description: '',
    ticketId: '',
    customerUuid: '',
    location: '',
    departmentUuid: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disable, setDisable] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };
  const handleCreateComplaintProfile = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
      | undefined
  ) => {
    e?.preventDefault();
    const { isValid, errors } = validateForm(formData, [
      'orderUuid',
      'productUuid',
      'title',
      'customerUuid',
      'location'
    ]);

    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }

    if (isValid) {
      try {
        const response = await createComplaint({
          orderUuid: formData.orderUuid || '',
          companyUuid: companyUuid || '',
          productUuid: formData.productUuid || '',
          title: formData.title || '',
          description: formData.description || '',
          ticketId: formData.ticketId || '',
          customerUuid: formData.customerUuid || '',
          location: formData.location || '',
          departmentUuid: formData.departmentUuid || ''
        });
        if (response.ok) {
          const data = await response.json();
          ToastNotification({
            message: data.message
          });
          setTimeout(() => {
            navigate('/complaints');
            setDisable(false);
          }, 1500);
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
          setDisable(false);
        }
      } catch (error) {
        setDisable(true);
      }
    }
  };

  const handleGoBack = () => {
    navigate('/complaints');
  };

  return (
    <div className="flex flex-col flex-1 bg-[#1E1E1E] h-[calc(100vh-90px)] overflow-y-auto">
      <div className="flex pr-5">
        <div className="">
          <BackButton onClick={handleGoBack} />
        </div>
        <h1 className="h-[26px] px-2 lg:ml-0 mt-1 md:mt-3 mb-[27px] text-[24px] sm:leading-[29.18px] md:text-[24px] md:leading-[25.78px] font-medium">
          Complaint
        </h1>
      </div>
      <form
        className="flex flex-col justify-between "
        onSubmit={handleCreateComplaintProfile}
        method="Post"
      >
        <div className="space-y-0.5 pr-4 px-5">
          {' '}
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Order Id
            </label>
            <div className=" flex-grow ">
              <InputBox
                name="orderUuid"
                type="text"
                value={formData.orderUuid}
                placeholder="Enter Order Id"
                handleChange={handleChange}
                error={errors.orderUuid}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Product Id
            </label>
            <div className="flex-grow ">
              <InputBox
                name="productUuid"
                type="text"
                value={formData.productUuid}
                placeholder="Enter Product Id"
                handleChange={handleChange}
                error={errors.productUuid}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Customer Id
            </label>
            <div className="flex-grow ">
              <InputBox
                name="customerUuid"
                type="text"
                value={formData.customerUuid}
                placeholder="Enter Customer Id"
                handleChange={handleChange}
                error={errors.customerUuid}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Ticket Id
            </label>
            <div className="flex-grow">
              <InputBox
                name="ticketId"
                type="text"
                value={formData.ticketId}
                placeholder="Enter Ticket Id"
                handleChange={handleChange}
                error={errors.ticketId}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Department Id
            </label>
            <div className="flex-grow ">
              <InputBox
                name="departmentUuid"
                type="text"
                value={formData.departmentUuid}
                placeholder="Enter Department Id"
                handleChange={handleChange}
                error={errors.departmentUuid}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Title
            </label>
            <div className="flex-grow ">
              <InputBox
                name="title"
                type="text"
                value={formData.title}
                placeholder="Enter Title"
                error={errors.title}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Description
            </label>
            <div className="flex-grow ">
              <InputBox
                name="description"
                type="text"
                value={formData.description}
                placeholder="Enter Description"
                handleChange={handleChange}
                error={errors.description}
              />
            </div>
          </div>
          <div className="flex flex-wrap md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-16">
            <label className="text-[22px] font-medium text-light w-full md:w-[190px] md:ps-5">
              Location
            </label>
            <div className="flex-grow ">
              <InputBox
                name="location"
                type="text"
                value={formData.location}
                placeholder="Enter Location"
                handleChange={handleChange}
                error={errors.location}
              />
            </div>
          </div>
        </div>

      </form>
      <div className="flex  justify-end items-end mt-auto  px-5 lg:mb-10">
        <Button
          isFormValid={true}
          btnText={`Create`}
          disable={disable}
          textColor="#FFFFFF"
          backGroundColor="#006BEA"
          onClick={(event) => handleCreateComplaintProfile(event)}
        />
      </div>
      {disable && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      )}
    </div>
  );
}

export default CreateComplaint;

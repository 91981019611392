import React from 'react';
interface Notification {
  message: string;
  uuid: string;
  createdTime: string;
  title: string;
}

interface IProps {
  tickets: Notification[];
  selectedTicketIdx: number;
}

const SpecificNotificationDetails = ({ tickets, selectedTicketIdx }: IProps) => {
  const selectedTicket = tickets[selectedTicketIdx];

  if (!selectedTicket) {
    return (
      <div className="flex items-center justify-center h-full">
        <label className="text-lg text-white font-bold">
          Select a notification to view its details.
        </label>
      </div>
    );
  }

  return (
    <div className="relative h-[calc(100vh-234px)] flex flex-col gap-2.5 pr-2 py-4 pl-5">
      <div className="flex justify-between items-center mb-3">
        <div className="flex gap-5">
          <label className="text-[20px] text-white font-medium">{selectedTicket.message}</label>
        </div>
      </div>
      <div className="">
        <label className="text-[18px] text-white  font-normal">{selectedTicket.title}</label>
      </div>
      <div className="absolute bottom-1 right-0 pr-2 ">
        <label className="text-xs font-bold text-[#D7D7D7]">{selectedTicket.createdTime}</label>
      </div>
    </div>
  );
};

export default SpecificNotificationDetails;

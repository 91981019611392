// src/utils/errorMessages.ts
export const ErrorMessages = {
  businessName: {
    required: 'Business name is required',
    invalid: 'Enter valid Business name'
  },
  email: {
    required: 'Email is required',
    invalid: 'Valid email is required'
  },
  password: {
    required: 'Password is required',
    invalid: 'Password must have at least one uppercase letter, one number, one special character, and be at least 8 characters long'
  },
  confirmPassword: {
    required: 'Confirm password is required',
    mismatch: 'Passwords do not match'
  },
  targetWebsite: {
    required: 'Website link is required',
    invalid: 'Invalid website link (e.g., "www.xyz.com")'
  },
  companyName: {
    required: 'Company name is required'
  },
  contactNumber: {
    required: 'Contact number is required',
    invalid: 'Contact number must be exactly 10 digits'
  },
  whatsAppNumber: {
    required: 'WhatsApp number is required',
    invalid: 'WhatsApp number must be exactly 10 digits'
  },
  address: {
    required: 'Address is required'
  },
  industry: {
    required: 'Industry name is required.'
  },
  name: {
    required: 'Name is required'
  },
  employeeId: {
    required: 'Employee ID is required'
  },
  position: {
    required: 'Position is required'
  },
  question: {
    required: 'Question is required'
  },
  answer: {
    required: 'Answer is required'
  },
  supportEmail: {
    required: 'Email is required',
    invalid: 'Valid email is required'
  },
  orderUuid: { required: 'Order Id is required' },
  productUuid: { required: 'Product Id is required' },
  title: { required: 'Title is required' },
  customerUuid: { required: 'Customer Id is required' },
  location: { required: 'Location is required' }
};

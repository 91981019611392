import React, { Dispatch, SetStateAction, useEffect } from 'react';

interface IProps {
  password: any;
  setScore: Dispatch<SetStateAction<number>>;
  score: number;
}

const ProgressBarLogic = (props: IProps) => {
  const { password, setScore, score } = props;
  useEffect(() => {
    let lowerCase = /[a-z]/.test(password) && !/\s/.test(password);
    let upperCase = /[A-Z]/.test(password) && !/\s/.test(password);
    let hasDigit = /\d/.test(password);
    let hasSpecialChar = /[@$!%*?&]/.test(password);
    let isValidLength = password.length >= 8;
    let isValidLength1 = password.length >= 12;

    let strengthScore = 0;

    if (lowerCase) strengthScore += 20;
    if (upperCase) strengthScore += 20;
    if (hasDigit) strengthScore += 20;
    if (hasSpecialChar) strengthScore += 20;
    if (isValidLength) strengthScore += 10;
    if (isValidLength1) strengthScore += 10;

    setScore(strengthScore);
  }, [password, setScore]);

  let strengthValue = '';
  let colorValue = '';

  switch (true) {
    case score >= 100:
      strengthValue = 'Very Strong';
      colorValue = '#00b500';
      break;
    case score >= 90:
      strengthValue = 'Strong';
      colorValue = '#9bc158';
      break;
    case score >= 60:
      strengthValue = 'Good';
      colorValue = '#3CD65F';
      break;
    case score >= 40:
      strengthValue = 'Weak';
      colorValue = '#EA1111';
      break;
    case score >= 20:
      strengthValue = 'Very Weak';
      colorValue = '#828282';
      break;
    default:
      strengthValue = '';
      colorValue = '';
  }

  return (
    <div className="">
      <span id="ProgressLabel" className="sr-only"></span>
      <span className="flex items-center justify-start">
        <span
          role="progressbar"
          aria-labelledby="ProgressLabel"
          className="block rounded-full bg-gray-200 w-2/3 sm:w-3/4 md:w-4/5 mr-2"
        >
          <span
            className="block h-1 rounded-full"
            style={{ width: `${score}%`, backgroundColor: colorValue }}
          ></span>
        </span>
        <p className="text-left text-white md:text-[16px]">{strengthValue}</p>
      </span>
    </div>
  );
};

export default ProgressBarLogic;

import React from 'react';
import Notification from '../../../notifications/Notification';

const NotificationVendor = () => {
  return (
    <div className="w-full md:pl-14">
      <Notification />
    </div>
  );
};

export default NotificationVendor;

import { useEffect, useState } from 'react';
import ComplaintsAreaChart from './ComplaintsAreaChart';
import React from 'react';
interface ComplaintsGraphProps {
  complaintsInfo: Record<string, number>;
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  selectedButton: any;
  setSelectedButton: any;
}

const ComplaintsGraph = ({
  complaintsInfo,
  date,
  setDate,
  selectedButton,
  setSelectedButton
}: ComplaintsGraphProps) => {
  const years = [
    { label: '2024', value: 'yearly' },
    { label: '2023', value: 'yearly1' },
    { label: '2022', value: 'yearly2' }
  ];

  const [data, setData] = useState<{ name: string; count: number }[]>([]);
  const [totalComplaints, setTotalComplaints] = useState(0);
  useEffect(() => {
    const processComplaintsData = () => {
      try {
        const complaints = complaintsInfo;
        if (!complaints || Object.keys(complaints).length === 0) {
         
          
        }

        const formattedData = Object.keys(complaints).map((month) => ({
          name: month,
          count: complaints[month]
        }));
        setData(formattedData);

        const total = Object.values(complaints).reduce((sum, count) => sum + count, 0);
        setTotalComplaints(total);
      } catch (err: any) {}
    };

    processComplaintsData();
  }, [complaintsInfo]);
  const handleWeekClick = () => {
    if (date === 'yearly' || date === 'monthly' || date === 'weekly') {
      setDate('weekly');
    }
    setSelectedButton('Week');
  };
  const handleMonthClick = () => {
    if (date === 'yearly' || date === 'monthly' || date === 'weekly') {
      setDate('monthly');
    }
    setSelectedButton('Month');
  };
  return (
    <>
      <div className="flex flex-col bg-[#343434] rounded-lg p-2.5">
        <div className="flex flex-row justify-between items-center">
          <label className="text-xs xl:text-lg font-normal text-white">Complaints</label>
          <label className="text-xl xl:text-3xl font-bold text-white">
            {totalComplaints.toLocaleString()}
          </label>
          <div className="flex justify-between gap-[2px] xl:gap-[10px]">
            <button
              className={`border text-sm px-[5px] md:px-[7px] xl:px-[10px] py-1 font-normal border-gray-400 rounded-[20px] ${selectedButton === 'Week' ? 'bg-[#006BEA] text-white' : ''} ${date !== 'yearly' && date !== 'weekly' && date !== 'monthly' ? 'bg-gray-400 opacity-50 cursor-not-allowed' : ''}`}
              disabled={date !== 'yearly' && date !== 'weekly' && date !== 'monthly'}
              onClick={handleWeekClick}
            >
              Week
            </button>
            <button
              className={`border text-sm px-[5px] md:px-[7px] xl:px-[10px] py-1 font-normal border-gray-400 rounded-[20px] ${selectedButton === 'Month' ? 'bg-[#006BEA] text-white' : ''} ${date !== 'yearly' && date !== 'weekly' && date !== 'monthly' ? 'bg-gray-400 opacity-50 cursor-not-allowed' : ''}`}
              disabled={date !== 'yearly' && date !== 'weekly' && date !== 'monthly'}
              onClick={handleMonthClick}
            >
              Month
            </button>
            <select
              name="type"
              className="bg-transparent text-white focus:bg-[#343434] text-sm font-normal border px-[5px] md:px-[7px] xl:px-[10px] py-1 border-gray-400 rounded-[20px]"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
                setSelectedButton('');
              }}
            >
              {years.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ width: '100%', height: '300px' }}>
          <ComplaintsAreaChart data={data} />
        </div>
      </div>
    </>
  );
};

export default ComplaintsGraph;

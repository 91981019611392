import React, { useEffect, useState } from 'react';
import InputBox from '../../../common/InputBox';
import Button from '../../../common/Button';
import { getChatOptions, postChatOptions } from '../../../api/Configuration';
import ToastNotification from '../../../common/ToastNotification';

interface OptionState {
  name: string;
  isSelected: boolean;
  code: string;
  description: string;
}

const Configuration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const companyUuid = sessionStorage.getItem('companyUuid');
  const [optionStates, setOptionStates] = useState<OptionState[]>([]);
  const [claimWarrantyData, setClaimWarrantyData] = useState({
    time: '',
    slug: ''
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState({
    time: '',
    slug: ''
  });

  const fetchAllData = async () => {
    try {
      setIsLoading(true);
      const response = await getChatOptions(companyUuid ? companyUuid : '');
      const { payload } = response;
      const { allOptionsStatus, selectedOptions, metadata } = payload;
      setOptionStates(allOptionsStatus || []);
      setSelectedOptions(selectedOptions);
      if (selectedOptions.includes('ClaimWarranty')) {
        setClaimWarrantyData({ time: metadata?.time, slug: metadata?.slug });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data list:', error);
      setOptionStates([]);
    }
  };

  const handleCheckboxChange = (index: number) => {
    const updatedOptions = optionStates.map((val, idx) => {
      if (idx === index) {
        if (val.code === 'ClaimWarranty') {
          if (val.isSelected) {
            setClaimWarrantyData({ time: '', slug: '' });
          }
        }
        return { ...val, isSelected: !val.isSelected };
      }
      return { ...val };
    });
    setOptionStates(updatedOptions);
  };

  const validate = () => {
    if (claimWarrantyData.time.trim() === '' && claimWarrantyData.slug.trim() === '') {
      ToastNotification({
        message: 'At least one field is required',
        type: 'error'
      });
    }
  };

  const handleFieldChange = (field: 'time' | 'slug', value: string) => {
    setClaimWarrantyData((prevData) => ({
      ...prevData,
      [field]: value
    }));
    if (field === 'time' && value.trim() !== '') {
      setClaimWarrantyData((prevData) => ({
        ...prevData,
        slug: ''
      }));
    } else if (field === 'slug' && value.trim() !== '') {
      setClaimWarrantyData((prevData) => ({
        ...prevData,
        time: ''
      }));
    }
  };

  const handleSubmit = async () => {
    const anyOptionSelected = optionStates.some((opt) => opt.isSelected);
    const sOption = optionStates.filter((option) => option.isSelected).map((option) => option.code);
    if (optionStates.find((opt) => opt.code === 'ClaimWarranty' && opt.isSelected)) {
      if (
        (!claimWarrantyData.time || claimWarrantyData.time.trim() === '') &&
        (!claimWarrantyData.slug || claimWarrantyData.slug.trim() === '')
      ) {
        ToastNotification({
          message: 'At least one field is required',
          type: 'error'
        });
        return;
      }
    }

    if (!anyOptionSelected) {
      ToastNotification({
        message: 'No Option Selected',
        type: 'error'
      });
      return;
    }

    if (
      anyOptionSelected ||
      optionStates.find((opt) => opt.code === 'ClaimWarranty' && opt.isSelected)
    ) {
      const dataToPost = {
        options: sOption,
        metadata: claimWarrantyData
      };
      try {
        setIsLoading(true);
        const response = await postChatOptions(dataToPost, companyUuid ? companyUuid : '');
        const data = await response.json();
        await fetchAllData();
        setIsLoading(false);
        ToastNotification({
          message: data?.message,
          type: 'success'
        });
      } catch (error) {
        setIsLoading(false);
        console.error('Error posting data:', error);
      }
    } else {
      validate();
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <div className="lg:pl-6 pl-2 flex flex-col flex-1 h-full overflow-y-auto">
      <h1 className="lg:text-[22px] md:text-[17px] text-[14px] font-medium mt-2 sm:ml-[10px]">
        Choose an option
      </h1>
      {optionStates.map((item: any, index: number) => (
        <div key={index} className="flex flex-wrap flex-col gap-2 md:ml-[13px] m-5">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={item.isSelected}
              onChange={() => handleCheckboxChange(index)}
              className="w-6 h-6 border-4 border-gray-300 rounded-lg focus:ring-2 focus:ring-[#006bea] cursor-pointer"
            />
            {/* <div className="flex flex-col"> */}
            <label className="text-lg font-medium cursor-pointer">{item.name}</label>
            {/* </div> */}
          </div>
          <label className="text-lg cursor-pointer">{item.description}</label>

          {item.code === 'ClaimWarranty' && item.isSelected && (
            <div className="ml-10 flex flex-col gap-4 mt-4">
              <div>
                <InputBox
                  name="time"
                  type="text"
                  value={claimWarrantyData.time}
                  handleChange={(e) => handleFieldChange('time', e.target.value)}
                  placeholder="Enter time period"
                  error={errors.time}
                />
                <label>
                  Customers can quickly and conveniently check and confirm their warranty period
                  using chatbot assistance.
                </label>
              </div>

              <div>
                <InputBox
                  type="text"
                  name="slug"
                  value={claimWarrantyData.slug}
                  handleChange={(e) => handleFieldChange('slug', e.target.value)}
                  placeholder="Enter slug key"
                  error={errors.slug}
                />
              </div>
            </div>
          )}
        </div>
      ))}
      {!isLoading && optionStates.length === 0 && (
        <>
          <div className="flex justify-center items-center h-full text-xl font-semibold">
            <p>No option available</p>
          </div>
        </>
      )}

      <div className="flex justify-end  mt-auto  lg:mb-12">
        <Button
          onClick={handleSubmit}
          isFormValid={true}
          btnText={selectedOptions.some((val) => val) ? 'Update' : 'Submit'}
          textColor="#FFFFFF"
          backGroundColor="#006BEA"
        />
      </div>

      {isLoading && (
        <div className="flex items-center justify-center  w-full text-white text-lg font-bold">
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Configuration;

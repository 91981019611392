import React, { ReactNode } from 'react';
import Sidebar from '../../common/layout/Sidebar';
import Stepper from './Stepper';
import Navbar from '../../common/layout/Navbar';
interface AccountSetupWizardProps {
  children: ReactNode;
}
const AccountSetupWizard = (props: AccountSetupWizardProps) => {
  const { children } = props;
  const header = (
    <div className="w-full">
      <h1 className="lg:text-[28px] md:text-[23px] text-[12px] font-bold lg:ms-8 md:ms-2 ms-1 md:mt-[30px] md:mb-[0px] pb-4 md:ps-4 md:pb-0">
        Account Setup Wizard
      </h1>
    </div>
  );

  return (
    <div className="flex  h-screen bg-[1E1E1E] text-white">
      <Sidebar>
        <Stepper
        />
      </Sidebar>

      <Navbar header={header} isAccountSetup={true}>
        {children}
      </Navbar>
    </div>
  );
};

export default AccountSetupWizard;

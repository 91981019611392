import React from 'react';
import { FaRegFileAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import { RiLink } from 'react-icons/ri';

interface IProps {
  documentList: {
    uuid: string;
    type: string;
    name: string;
  }[];
  deleteDocuments: any;
  handleEditUrl: any;
  
}

const FaqFillScreen = (props: IProps) => {
  const { documentList, deleteDocuments, handleEditUrl } = props;

  return (
    <div className="lg:ml-9 ml-[4px] md:ml-2">
      <div>
        <div className="md:mt-10 mt-4">
          <p className="md:font-medium font-semibold text-xl md:text-[21px] text-[16px] leading-[30.86px]">
            Details
          </p>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6 gap-4 pb-4">
          {documentList.map((info: any) => (
            <div className="mt-5" key={info.uuid}>
              <div className="lg:w-40 lg:h-40 w-[110px] h-[110px] border-[3px] rounded-lg border-[#006BEA]">
                <div className="flex gap-4 justify-end mr-2 mt-2 left-1 cursor-pointer">
                  {info.type === 'Link' && (
                    <FiEdit
                      className="md:h-5 md:w-5 h-4 w-4 text-[#006BEA]"
                      onClick={() => handleEditUrl(info)}
                    />
                  )}
                  <AiOutlineDelete
                    className="md:h-5 md:w-5 h-4 w-4 text-[#FA0000]"
                    onClick={() => deleteDocuments(info.uuid)}
                  />
                </div>
                <div className="h-3/4 flex justify-center items-center text-[#006BEA]">
                  {info.type === 'Link' ? (
                    <RiLink className="lg:w-10 lg:h-10 w-7 h-7" />
                  ) : (
                    <FaRegFileAlt className="lg:w-10 lg:h-10 w-7 h-7" />
                  )}
                </div>
              </div>
              <div className="flex justify-start">
                <p className="lg:text-[24px] lg:w-40  w-[110px]  text-[13px] font-light leading-[28.13px] text-[#ffffff] truncate  text-start">
                  {info.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default FaqFillScreen;

import React, { useEffect, useState } from 'react';
import Button from '../../common/Button';
import InputBox from '../../common/InputBox';
import ToastNotification from '../../common/ToastNotification';
import Lopa from '../../assets/whitelogo.png';
import { forgotPassword } from '../../api/Auth';
import { validateForm } from '../../function/FormValidation';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: '' });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(formData, ['email']);
    setErrors(errors);
    if (isValid) {
      try {
        const response = await forgotPassword({
          email: formData.email || ''
        });
        if (response.ok) {
          const data = await response.json();
          ToastNotification({
            message: data.message
          });
          setTimeout(() => {
            setFormData({
              email: ''
            });
          }, 1500);
        } else {
          const errorData = await response.json();
          ToastNotification({
            message: errorData.message,
            type: 'warning'
          });
        }
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    const validateForm = () => {
      setIsFormValid(!!formData.email);
    };
    validateForm();
  }, [formData]);

  return (
    <>
      <img src={Lopa} className="lg:w-44 lg:h-20 w-28 h-16 mr-2 ml-8 mt-8" alt="Matrix AI" />
      <div className="">
        <div className="flex min-h-full flex-col justify-center px-2 py-3 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-lg md:mt-20">
            <h2 className="mt-10 text-start text-3xl md:text-[46px] md:leading-[50.33px] font-bold tracking-tight">
              Forget Password?
            </h2>

            <p className="mt-5 justify-center tracking-tight text-xl md:text-[24px] md:leading-[27.36px]">
              Enter your email address below, and we’ll send you a link to reset your password.
            </p>
          </div>

          <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-lg">
            <form onSubmit={handleSubmit} action="/reset-password" className="space-y-6">
              <div>
                <div className="mt-5">
                  <InputBox
                    name="email"
                    type="text"
                    value={formData.email}
                    placeholder="Enter your Email"
                    handleChange={handleChange}
                    error={errors.email}
                  />
                </div>
              </div>

              <div className="flow-root">
                <Button
                  isFormValid={isFormValid}
                  btnText="Send"
                  textColor="#FFFFFF"
                  backGroundColor="#006BEA"
                  borderColor=""
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React, { Fragment, memo, useEffect, useState } from 'react';
import NotificationList from './NotificationList';
import SpecificNotificationDetails from './SpecificNotificationDetails';
import { updateNotification } from '../api/Notification';
import { useSocket } from '../Contexts/SocketContext';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';

interface NotificationType {
  message: string;
  uuid: string;
  createdTime: string;
  title: string;
  read: boolean;
}
interface NotificationProps {
  searchQuery?: string;
}

const Notification: React.FC<NotificationProps> = (props) => {
  const { searchQuery } = props;
  const [showLoading, setShowLoading] = useState(true);
  const [isRead, setIsRead] = useState<string>('');
  const [allTickets, setAllTickets] = useState<NotificationType[]>([]);
  const [tickets, setTickets] = useState<NotificationType[]>([]);
  const [selectedTicketIdx, setSelectedTicketIdx] = useState(0);
  const { socketInstance } = useSocket();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 6;
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const location = useLocation();
  useEffect(() => {
    if (socketInstance) {
      if (searchQuery !== '') {
        socketInstance.emit('searchNotifications', searchQuery);
      } else {
        socketInstance.emit('getNotifications');
        console.log('Called');
      }
    }
  }, [socketInstance, searchQuery]);

  useEffect(() => {
    if (socketInstance) {
      setTimeout(() => {
        if (searchQuery === '' || location.pathname.includes('/vendor-login/vendor-notification')) {
          socketInstance.emit('getNotifications');
        }
      }, 300);
      const handleConnectionError = () => {
        setShowLoading(false); 
      };
      socketInstance.on('connect_error', handleConnectionError);

      socketInstance.on('searchResults', (notifications: any) => {

        const mappedNotifications: NotificationType[] = notifications.map((notification: any) => ({
          message: notification.message,
          uuid: notification.uuid,
          createdTime: calculateRelativeTime(notification.createdAt),
          title: notification.complaint.title,
          read: notification.read
        }));
        setCurrentPage(1);
        setAllTickets(mappedNotifications);
        setTotalItems(mappedNotifications.length);
        setShowLoading(false);
      });
      socketInstance.on('notifications', (notifications: any) => {
        const mappedNotifications: NotificationType[] = notifications.map((notification: any) => ({
          message: notification.message,
          uuid: notification.uuid,
          createdTime: calculateRelativeTime(notification.createdAt),
          title: notification.complaint.title,
          read: notification.read
        }));
        setAllTickets(mappedNotifications);
        setTotalItems(mappedNotifications.length);
        setShowLoading(false);
      });
      setShowLoading(true);

      socketInstance.on('updateNotifications', (notification: any) => {
        setShowLoading(true);
        socketInstance.emit('getNotifications');
      });
    }
  }, [socketInstance]);
  useEffect(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    setTickets(allTickets.slice(startIdx, endIdx));
  }, [currentPage, allTickets]);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const markAsRead = async (uuid: any) => {
    try {
      const response = await updateNotification(uuid);

      if (response.ok) {
        const data = await response.json();
        setIsRead(data.message);
        if (socketInstance) {
          socketInstance.emit('getNotifications');
        }
      }
    } catch (error: any) {}
  };

  const calculateRelativeTime = (createdTime: string): string => {
    try {
      const now = new Date();
      const createdDate = new Date(createdTime);

      if (isNaN(createdDate.getTime())) {
        throw new Error('Invalid date format');
      }

      const diffMs = now.getTime() - createdDate.getTime();

      if (diffMs <= 0) {
        return 'Just Now';
      }
      if (diffMs < 60000) {
        const diffSeconds = Math.floor(diffMs / 1000);

        if (diffSeconds === 0) {
          return 'Just Now';
        }

        return `${diffSeconds} sec${diffSeconds !== 1 ? 's' : ''} ago`;
      }

      const diffMinutes = Math.floor(diffMs / (1000 * 60));
      if (diffMinutes < 60) return `${diffMinutes} min${diffMinutes !== 1 ? 's' : ''} ago`;

      const diffHours = Math.floor(diffMinutes / 60);
      if (diffHours < 24) return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;

      const diffDays = Math.floor(diffHours / 24);
      return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
    } catch (error) {
      return '';
    }
  };

  return (
    <>
      {showLoading ? (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="loader border-t-4 border-[#006BEA] rounded-full w-12 h-12 animate-spin"></div>
          </div>
        </>
      ) : (
        <Fragment>
          {totalItems > 0 ? (
            <div className="bg-[#1E1E1E] mt-6 w-full lg:px-5 px-2 h-screen overflow-y-auto">
              <div className="flex flex-col xl:flex-row gap-2.5 pb-24 xl:pb-0">
                <div className="w-full xl:w-[50%] bg-[#343434] rounded-lg pr-2 pt-2 xl:h-[calc(100vh-200px)]">
                  <NotificationList
                    tickets={tickets || []}
                    selectedTicketIdx={selectedTicketIdx}
                    setSelectedTicketIdx={setSelectedTicketIdx}
                    markAsRead={markAsRead}
                    isRead={setIsRead}
                  />
                </div>
                <div className="w-full xl:w-[50%] bg-[#343434] rounded-lg p-2">
                  <SpecificNotificationDetails
                    tickets={tickets || []}
                    selectedTicketIdx={selectedTicketIdx}
                  />
                </div>
              </div>
              <div className="flex flex-col md:justify-center mt-4 mb-36 w-full">
                {totalItems > 1 && (
                  <ReactPaginate
                    previousLabel={
                      <div className="flex items-center justify-center w-8 h-8 border rounded border-white text-white">
                        &lt;
                      </div>
                    }
                    nextLabel={
                      <div className="flex items-center justify-center w-8 h-8 border rounded border-white text-white">
                        &gt;
                      </div>
                    }
                    pageCount={pageCount}
                    forcePage={currentPage - 1}
                    onPageChange={handlePageClick}
                    containerClassName="flex justify-center items-center gap-3"
                    pageLinkClassName="cursor-pointer"
                    activeLinkClassName="text-blue-500 font-bold"
                    disabledClassName="opacity-50 cursor-not-allowed"
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="flex content-center justify-center items-center h-5/6 overflow-y-hidden w-full text-gray-300 text-[16px] font-normal">
              No records found
            </div>
          )}
        </Fragment>
      )}
    </>
  );
};

export default memo(Notification);
